import React, { useContext, useEffect, useState } from 'react';
import { Divider, Flex, Icon, IconButton, Skeleton, Text, Tooltip, useToast } from '@chakra-ui/react';
import InsightPicto from '@/insight/components/insightPicto';
import { InsightContext } from '@/insight/lib/insightContext';
import { MdDevices, MdFace, MdOutlineArchive, MdOutlineRoute } from 'react-icons/md';
import { ExperienceContext } from '@/experiences/lib/experienceContext';
import PersonaProvider from '@/persona/lib/personaProvider';
import ProductProvider from '@/products/lib/productProvider';
import ProductLogo from '@/products/components/productLogo';
import PersonaThumbnail from '@/persona/components/personaThumbnail';
import useInsightApi from '../lib/useInsightApi';
import { Link, useNavigate } from 'react-router-dom';
import InsightAttributesTags from './insightAttributesTags';

export default function InsightCardListItem(props) {

  const navigate = useNavigate();

  const {
    insight,
    personaList,
    productList,
    rights
  } = useContext(InsightContext)

  const {
    experience,
    isFetchingExperience
  } = useContext(ExperienceContext)

  const { onSetAsOutdated } = props;
  const toast = useToast();
  const [inCard, setInCard] = useState(false)

  const insightApi = useInsightApi();

  const archiveInsight = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      await insightApi.archiveInsight(insight.id);
      toast({
        position: 'bottom-right',
        description: "Insight set as outdated",
        duration: 1000,
        isClosable: true,
      });
      if (onSetAsOutdated) {
        onSetAsOutdated();
      }
    } catch (e) {
      console.error(e)
    }
  };

  return (
    <>
      {isFetchingExperience && (
        <Flex >
          <Skeleton
            w={"350px"}
            minHeight={"75px"}
            height={"100%"}
            flexShrink={0}
            />
        </Flex>
      )}
      {(insight && experience) && (
        <Flex
          backgroundColor={"background.white"}
          border="1px solid"
          borderColor="divider.grey"
          borderRadius={"2px"}
          w={"350px"}
          onMouseEnter={() => {setInCard(true)}}
          onMouseLeave={() => {setInCard(false)}}
          flexDirection={"column"}
          flexShrink={"0"}
          cursor="pointer"
          onClick={() => {
            navigate('/experiences/' + experience.id + '#' + insight.id)
          }}
          py={3}
        >
          <Flex
            mb={2}
            px={3}
            gap={2}
          >
            <Flex
              alignItems={"center"}
              flex={1}
            >
              <Icon
                as={MdOutlineRoute}
                color={'text.medium.grey'}
                boxSize="15px"
                mr={2}
              />
              <Text
                color={"text.medium.grey"}
                noOfLines={2}
                fontSize={"12px"}
              >{experience.objective}</Text>
            </Flex>
            {(!insight.archived && rights.can_update) &&
              <Tooltip label='Set as Outdated'
                style={{ opacity: (inCard ? '1' : '0' )  }}
              >
                <IconButton
                  style={{ opacity: (inCard ? '1' : '0' )  }}
                  variant="orangeOutline"
                  icon={<Icon as={MdOutlineArchive} boxSize="16px" />}
                  onClick={archiveInsight}
                />
              </Tooltip>
            }
          </Flex>
          <Flex
            px={3}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={(personaList && personaList.length > 0 && productList && productList.length > 3) ? 2 : 3}
          >
            {(personaList && personaList.length > 0) && (
              <Flex
                alignItems={"center"}
              >
                <Icon
                  as={MdFace}
                  size="20"
                  color="text.medium.grey"
                />
                {personaList.slice(0, 3).map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Link to={`/persona/${p.id}`} onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <Flex
                        ml={2}
                      >
                        <PersonaProvider
                          personaId={p.id}
                          originalPersona={p}
                        >
                          <PersonaThumbnail
                            size={20}
                            hasAcronym={true}
                          />
                        </PersonaProvider>
                      </Flex>
                    </Link>
                  </Tooltip>
                ))}
                {personaList.length > 3 && (
                  <Text
                    fontSize={"10px"}
                    color="text.medium.grey"
                    ml={2}
                  >+{personaList.length - 3} more</Text>
                )}
              </Flex>
            )}
            {(productList && productList.length > 0) && (
              <Flex alignItems={"center"}>
                <Icon
                  as={MdDevices}
                  size="20"
                  color="text.medium.grey"
                />
                {productList.slice(0, 3).map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Link to={`/products/${p.id}`} onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <Flex
                        ml={2}
                      >
                        <ProductProvider
                          productId={p.id}
                          orignalProduct={p}
                        >
                          <ProductLogo
                            size={20}
                            hasAcronym={true}
                          />
                        </ProductProvider>
                      </Flex>
                    </Link>
                  </Tooltip>
                ))}
                {productList && productList.length > 3 && (
                  <Text
                    fontSize={"10px"}
                    color="text.medium.grey"
                    ml={2}
                  >+{personaList.length - 3} more</Text>
                )}
              </Flex>
            )}
          </Flex>
          <Divider my={2} />
          <Flex px={3} flexDirection={"column"} flex={1}>
            <Flex
              flexDirection={"row"}
              alignItems={"flex-start"}
              flex={1}
            >
              <InsightPicto size={24} type={insight.type} />
              <Text
                ml={2}
                style={{ whiteSpace: 'break-spaces' }}
                noOfLines={4}
              > {insight.name}</Text>
            </Flex>
            <InsightAttributesTags />
          </Flex>
        </Flex>
      )}
    </>
  );
}
