'use client'

import React, { useEffect, useState } from 'react';
import {Flex} from '@chakra-ui/layout';
import PersonaList from '../persona/components/personaList';
import ProductList from '../products/components/productList';
import InsightCardList from '../insight/components/insightCardList';
import useInsightApi from '../insight/lib/useInsightApi';
import { insightTypeDictionnary } from '@/insight/lib/insightTypeDictionnary';

export default function HomePage() {

  const [lists, setLists] = useState();
  const insightApi = useInsightApi();

  const fetchData = async () => {
    try {
     const result = await Promise.all([
        insightApi.fetchAllMyActiveInsightByType(insightTypeDictionnary.negative),
        insightApi.fetchAllMyActiveInsightByType(insightTypeDictionnary.improvement)
      ])
      setLists({
        [insightTypeDictionnary.negative]: result[0],
        [insightTypeDictionnary.improvement]: result[1]
      })
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!lists) {
      fetchData();
    }
  }, [])
    
  return (
    <>
      <Flex
          py={3}
          flexDirection={"column"}
          alignSelf={"stretch"}
          flex={1}
          overflowY={"auto"}
        >
        <Flex 
          px={3}
          width={"100%"}
         >
          <InsightCardList
            lists={lists}
            onSetAsOutdated={fetchData}
          />
        </Flex>
        <Flex
          w="100%"
          px={3}
          mb={3}
        >
          <Flex
            border="1px solid"
            borderColor={"divider.grey"}
            w="100%"
          >
            <PersonaList
              insidePaddings={false}
              emptyHeight={"300px"}
            />
          </Flex>
        </Flex>
        <Flex
          w="100%"
          px={3}
          >
          <Flex
            w="100%"
            border="1px solid"
            borderColor={"divider.grey"}
          >
            <ProductList
              insidePaddings={false}
              emptyHeight={"300px"}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
