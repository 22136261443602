'use client'

import React, { Children, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Divider, Flex } from '@chakra-ui/layout';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, FormControl, FormErrorMessage, FormLabel, Icon, Textarea, Text } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { Select, chakraComponents } from 'chakra-react-select';
import usePersonaApi from '../../persona/lib/usePersonaApi';
import { Switch } from '@chakra-ui/switch';
import useProductsApi from '@//products/lib/useProductsApi';
import { ExperienceContext } from '../../experiences/lib/experienceContext';
import { MdKeyboard, MdKeyboardArrowDown, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from 'react-icons/md';
import { RiEqualFill } from 'react-icons/ri';
import CustomSelect from '../../components/customSelect';

const InsightForm = forwardRef((props, ref) => {

  const {
    experienceId,
    type,
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
  } = props;

  const {
    experience,
    personaList,
    productList,
    isFetchingPersonaList,
    isFetchingProductList
  } = useContext(ExperienceContext)

  const {
    handleSubmit,
    errors,
    control,
    register,
    values,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm();

  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const insightApi = useInsightApi();

  const [personaOptionsList, setPersonaOptionsList] = useState(null);
  const [isPersonaChecked, setIsPersonaChecked] = useState(true);
  const [productOptionList, setProductOptionList] = useState(true);
  const [isProductsChecked, setIsProductsChecked] = useState(true);
  const [placeholder, setPlaceholder] = useState('');
  const assumption = watch('assumption');

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      let newInsight;
      if (data.name && data.type && experienceId && data.importance) {
        const _type = data.type;
        newInsight = await insightApi.insertInsight(
          experienceId,
          _type,
          data.name,
          data.applies_to_all_persona,
          data.applies_to_all_products,
          data.assumption,
          data.importance.value,
        );
        if (data.persona) {
          const personaIds = data.persona.map(p => p.value);
          await insightApi.linkManyPersonaToInsight(newInsight.id, personaIds)
        }
        if (data.products) {
          const productIds = data.products.map(p => p.value);
          await insightApi.linkManyProductsToInsight(newInsight.id, productIds)
        }
      }
      if (onSubmitted) {
        onSubmitted(newInsight);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const init = async () => {
    try {
      let newPersonaOptions = personaList.map((p) => {
        return { label: p.name, value: p.id }
      });
      setPersonaOptionsList(newPersonaOptions);
      const newProductOptions = productList.map((p) => {
        return { label: p.name, value: p.id }
      });
      setProductOptionList(newProductOptions);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (!isFetchingPersonaList, !isFetchingProductList) {
      init();
    }
  }, [isFetchingPersonaList, isFetchingProductList]);

  useEffect(() => {
    setValue('type', type)
  }, [type]);

  useEffect(() => {
    const values = getValues();
    if (values.type) {
      if (values.type === 'negative') {
        setPlaceholder('frustration, problem, dislike, bad emotion… ')
      } else if (values.type === 'positive') {
        setPlaceholder('Benefit, like, good emotion…')
      } else if (values.type === 'improvement') {
        setPlaceholder('Need, wish, idea…')
      } else if (values.type === 'behavior') {
        setPlaceholder('Observation, task, action…')
      }
    }
  }, [getValues()]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          {!type && (
            <Controller
              control={control}
              name="type"
              rules={{ required: 'Insight type is mandatory' }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, error }
              }) => (
                <FormControl isInvalid={errors && errors.type} mb={3}>
                  <FormLabel>Insight Type</FormLabel>
                  <Select
                    options={[{
                      label: 'Negative',
                      value: 'negative',
                    }, {
                      label: 'Positive',
                      value: 'positive',
                    }, {
                      label: 'Improvement',
                      value: 'improvement',
                    }]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  <FormErrorMessage>{errors && errors.type}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
          <FormControl isInvalid={errors && errors.name} mb={3}>
            <FormLabel>Insight</FormLabel>
            <Textarea
              placeholder={placeholder}
              {...register('name', {
                required: true,
                minLength: 3,
              })}
            />
            <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl
            display='flex'
            alignItems='center'
            mt={3}
            mb={3}>
            <FormLabel mr={2} mb="0" color={!assumption ? "text.medium.orange" : "inherit"}>
              Fact
            </FormLabel>
            <Switch
              {...register('assumption')}
              isChecked={assumption}
              onChange={(e) => setValue('assumption', e.target.checked)}
              variant="orangeDouble"
            />
            <FormLabel ml={2} mb="0" color={assumption ? "text.medium.orange" : "inherit"}>
              Assumption
            </FormLabel>
            <FormErrorMessage>{errors && errors.applies_to_all_persona}</FormErrorMessage>
          </FormControl>
          <Divider />
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton px={0} >
                <Box as='span' flex='1' textAlign='left'>
                  More Options
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                backgroundColor={"white"}
                p={3}
                borderRadius={"2px"}
                border={"1px solid"}
                borderColor={'divider.grey'}
                >
                <Controller
                  control={control}
                  name="importance"
                  rules={{}}
                  defaultValue={{
                    label: 'Medium',
                    value: 'medium',
                    icon: RiEqualFill,
                    color: 'text.medium.yellow'
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, error }
                  }) => (
                    <FormControl isInvalid={errors && errors.importance} mb={3} mt={2}>
                      <FormLabel>Importance</FormLabel>
                      <CustomSelect
                        options={[{
                          label: 'Highest',
                          value: 'highest',
                          icon: MdOutlineKeyboardDoubleArrowUp,
                          color: 'text.medium.red'
                        }, {
                          label: 'High',
                          value: 'high',
                          icon: MdOutlineKeyboardArrowUp,
                          color: 'text.medium.red'
                        }, {
                          label: 'Medium',
                          value: 'medium',
                          icon: RiEqualFill,
                          color: 'text.medium.yellow'
                        }, {
                          label: 'Low',
                          value: 'low',
                          icon: MdOutlineKeyboardArrowDown,
                          color: 'text.medium.blue'
                        }, {
                          label: 'Lowest',
                          value: 'lowest',
                          color: 'text.medium.blue',
                          icon: MdOutlineKeyboardDoubleArrowDown
                        }]}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                      <FormErrorMessage>{errors && errors.importance}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <FormControl
                  display='flex'
                  alignItems='center'
                  mt={3}
                  mb={3}>
                  <Switch
                    {...register('applies_to_all_persona', {
                      onChange: (e) => {
                        const value = getValues().applies_to_all_persona;
                        setIsPersonaChecked(value);
                      },
                    })}
                    defaultChecked
                  />
                  <FormLabel ml={2} mb="0">
                    Applies to all persona of this experience
                  </FormLabel>
                  <FormErrorMessage>{errors && errors.applies_to_all_persona}</FormErrorMessage>
                </FormControl>
                {(!isPersonaChecked) && (
                  <Controller
                    control={control}
                    name="persona"
                    rules={{ required: "At least one item must be selected" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, error }
                    }) => (
                      <FormControl isInvalid={errors && errors.persona} mb={3}>
                        <FormLabel>Involved Persona</FormLabel>
                        <Select
                          isMulti
                          options={personaOptionsList}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                        <FormErrorMessage>{errors && errors.persona}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                )}
                <FormControl
                  display='flex'
                  alignItems='center'
                  mb={3}
                >
                  <Switch
                    {...register('applies_to_all_products', {
                      onChange: (e) => {
                        const value = getValues().applies_to_all_products;
                        setIsProductsChecked(value);
                      },
                    })}
                    defaultChecked
                  />
                  <FormLabel ml={2} mb='0'>
                    Applies to all products of this experience
                  </FormLabel>
                  <FormErrorMessage>{errors && errors.applies_to_all_products}</FormErrorMessage>
                </FormControl>
                {(!isProductsChecked) && (
                  <Controller
                    control={control}
                    name="products"
                    rules={{ required: "At least one item must be selected" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, error }
                    }) => (
                      <FormControl isInvalid={errors && errors.products} mb={3}>
                        <FormLabel>Products</FormLabel>
                        <Select
                          isMulti
                          options={productOptionList}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                        <FormErrorMessage>{errors && errors.products}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default InsightForm;


