import React, {useContext, useEffect, useState} from 'react';
import {ProductPageContext } from './productPageContext.js';
import useExperienceApi from '../../../experiences/lib/useExperienceApi.js';
import { ProductContext } from '../../lib/productContext.js';
import usePersonaApi from '@/persona/lib/usePersonaApi.js';
import { AuthContext } from '@/auth/lib/authContext.js';

export default function ProductPageProvider({children}) {

  const {
    productId,
  } = useContext(ProductContext);

  const {profile} = useContext(AuthContext)
  const personaApi = usePersonaApi();
  const experienceApi = useExperienceApi();
  const [isFetchingPersonaList, setIsFetchPersonaList] = useState(false);
  const [isFetchingExperiences, setIsFetchingExperiences] = useState(false);
  const [personaList, setPersonaList] = useState([]);
  const [experienceList, setExperienceList] = useState(null);
  const [experienceGroupList, setExperienceGroupList] = useState(null);
  const [experiencesPersona, setExperiencesPersona] = useState(null);
  const [init, setInit] = useState(false);

  const refreshPersonaList = async () => {
    try {
      setIsFetchPersonaList(true);
      const newPersonaList = await personaApi.fetchManyPersonaByProductId(productId);
      setPersonaList(newPersonaList);
      setIsFetchPersonaList(false);
    } catch (error) {
      console.error(error)
    }
  }

  const refreshExperienceList = async () => {
    try {
      setIsFetchingExperiences(true);
      const result = await Promise.all([
        experienceApi.fetchExperiencesByProductId(productId),
        experienceApi.fetchExperienceGroupsByProductId(productId),
        personaApi.fetchPersonaListWithExperienceByProductId(productId)
      ]);
      setExperienceList(result[0]);
      setExperienceGroupList(result[1]);
      setExperiencesPersona(result[2]);
      setIsFetchingExperiences(false);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (productId && profile) {
      if(!init) {
        refreshPersonaList();
        refreshExperienceList();
        setInit(true);
      }
    }
  }, [productId,  profile]);

  return (
    <ProductPageContext.Provider value={{
      isFetchingPersonaList,
      isFetchingExperiences,
      personaList,
      experienceGroupList,
      refreshPersonaList: refreshPersonaList,
      refreshExperienceList: refreshExperienceList,
      experiencesPersona,
      experienceList
    }}>
      {children}
    </ProductPageContext.Provider>
  );
}