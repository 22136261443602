import React, {useContext, useEffect, useState} from 'react';
import {Flex, Box, Text} from '@chakra-ui/layout';
import {MdFace} from 'react-icons/md';
import {Icon} from '@chakra-ui/icon';
import usePersonaApi from '../lib/usePersonaApi';
import { PersonaContext } from '../lib/personaContext';
import {SkeletonCircle} from '@chakra-ui/react'

export default function PersonaThumbnail(props) {

  const {
    size,
    hasAcronym = false
  } = props;

  const {
    persona,
  } = useContext(PersonaContext);

  const [thumbnail, setThumbnail]  =  useState(null);
  const [acronym, setAcronym]  =  useState(null);
  const [downloading, setDownloading] = useState(false);
  const personaApi = usePersonaApi();

  const fetchThumbnail = async () => {
    let imageSize;
    if(size > 128) {
      imageSize = 256;
    } else if(size > 64 &&  size <= 128) {
      imageSize = 128;
    } else if(size <= 64 ) {
      imageSize = 64;
    }
    setDownloading(true)
    const newThumbnail = await personaApi.downloadThumbnail(
        persona.id,
        imageSize
      );
    setDownloading(false);
    setThumbnail(newThumbnail);
  };

  const updateAcronym = async () => {
    const nameArray = persona.name.split(' ');
    if(nameArray.length > 1) {
      setAcronym(nameArray[0][0] + (nameArray[1]?  nameArray[1][0] : ''));
    } else {
      setAcronym(nameArray[0][0])
    }
  };

  useEffect(() => {
    if(persona && persona.has_thumbnail) {
      fetchThumbnail()
    }
  }, [persona]);

  useEffect(() => {
    if(persona.name) {
      updateAcronym();
    }
  }, [persona]);

  return (
    <>
      <Flex
        border={persona.has_thumbnail ? "none" : "1px"}
        borderRadius="50%"
        backgroundColor="background.blue"
        borderColor="divider.blue"
        alignItems='center'
        justifyContent='center'
        overflow={'hidden'}
        w={size + 'px'}
        h={size + 'px'}
        flexShrink={0}
      >
        {((downloading && !thumbnail) && persona.has_thumbnail) && (
          <SkeletonCircle size={size}/>
        )}
        { (persona.has_thumbnail && thumbnail) && (
          <Box
            w={size + 'px'}
            h={size + 'px'}
            borderRadius="50%"
            style={{overflow: 'hidden'}}
          >
            <img src={thumbnail} alt="thumbnail" style={{width: '100%', height:'100%'}} />
          </Box>
        )}
        { !persona.has_thumbnail && (
          <>
            {!hasAcronym && (
              <Icon
                as={MdFace}
                color={'fill.blue'}
                boxSize={((size * 0.6) + 'px')}
              />
            )}
            {hasAcronym && (
              <Text
              textTransform={"uppercase"}
              fontWeight={"600"}
              fontSize={((size / 2.14) + 'px')}
              color={"text.medium.blue"}
              >{acronym}</Text>
            )}
          </>
        )}
      </Flex>
    </>
  );
}