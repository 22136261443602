'use client'

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/layout';
import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import useExperienceApi from '../../../experiences/lib/useExperienceApi';

const NewExperienceGroupInProductForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    productId
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if (name) {
        await experienceApi.insertExperienceGroupInProduct(name, productId);
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <FormControl isInvalid={errors && errors.name} mb={3}>
            <FormLabel>Group Name</FormLabel>
            <Input
              placeholder='Group name'
              {...register('name', {
                required: true,
                minLength: 3
              })}
            />
            <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
          </FormControl>
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default NewExperienceGroupInProductForm;


