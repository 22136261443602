import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Box, IconButton } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Flex, Text } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { MdClose, MdOutlineWarning } from 'react-icons/md';
import useExperienceApi from '../../lib/useExperienceApi';
import { Icon } from '@chakra-ui/icon';
import PersonaThumbnail from '../../../persona/components/personaThumbnail';
import { ExperienceContext } from '../../lib/experienceContext';
import { useNavigate } from 'react-router-dom';
import { PersonaContext } from '@/persona/lib/personaContext';

export default function PersonaInExperienceListItem(props) {

  const experienceContext = useContext(ExperienceContext);
  const personaContext = useContext(PersonaContext);

  const {
    onRemove,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [numberOfPersonaAndProduct, setNumberOfPersonaAndProduct] = useState();
  const modalDelete = useDisclosure();
  const experienceApi = useExperienceApi();
  const navigate = useNavigate();

  const onTryToDeleteExperiencePersona = async () => {
    try {
      const restultNumberOfPersonaAndProduct = await experienceApi.fetchNumberOfPersonaAndProduct(experienceContext.experienceId);
      setNumberOfPersonaAndProduct(restultNumberOfPersonaAndProduct);
      modalDelete.onOpen();
    } catch (e) {
      console.error(e);
    }
  };

  async function deleteExperiencesPersona() {
    try {
      setIsDeleting(true);
      await experienceApi.unlinkPersonaFromExperience(
        experienceContext.experienceId,
        personaContext.persona.id
      );
      setIsDeleting(false);
      modalDelete.onClose();
      if (onRemove) {
        onRemove();
      }
      if (numberOfPersonaAndProduct === 1) {
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {personaContext.persona && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="white"
          role="group"
          flex={1}
          mb={1}
          px={3}
          h={"34px"}
          _hover={{
            backgroundColor: 'background.grey',
            borderRadius: '2px'
          }}
        >
          <Flex alignItems={'center'}>
            <PersonaThumbnail size={24} />
            <Text ml={2} fontSize={'12px'}>{personaContext.persona.name}</Text>
          </Flex>
          <Box style={{ opacity: '0' }} _groupHover={{ opacity: '1 !important' }} >
            {(personaContext.rights.can_update && experienceContext.rights.can_update) && (
              <IconButton
                icon={<MdClose />}
                size={'md'}
                aria-label='Delete'
                variant='greyTransparent'
                isLoading={isDeleting}
                onClick={(e) => {
                  onTryToDeleteExperiencePersona();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            )}
          </Box>
          <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
            <ModalOverlay />
            <ModalContent >
              <ModalHeader>Remove persona {personaContext.persona.name}</ModalHeader>
              <ModalBody p={3} >
                <Flex
                  border="1px"
                  borderColor="divider.grey"
                  borderRadius="2px"
                  backgroundColor="white"
                  alignItems={'center'}
                  p={3}
                >
                  <Icon
                    as={MdOutlineWarning}
                    color={'text.medium.red'}
                    boxSize={'18px'}
                    mr={3}
                  />
                  <Text textStyle="body" color={'text.medium.red'} >Are you sure to remove the persona {personaContext.persona.name} from this experience ? </Text>
                </Flex>
                {numberOfPersonaAndProduct === 1 && (
                  <Flex
                    border="1px"
                    borderColor="divider.grey"
                    borderRadius="2px"
                    backgroundColor="white"
                    alignItems={'center'}
                    p={3}
                    mt={2}
                  >
                    <Text textStyle="body" color={'text.medium.red'} >
                      The experience will be deleted because it will be not link anymore to any product or persona.</Text>
                  </Flex>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant='redOutline'
                  onClick={modalDelete.onClose}
                >Close</Button>
                <Button
                  variant="redFill"
                  ml={2}
                  isLoading={isDeleting}
                  onClick={deleteExperiencesPersona}>Remove</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </>
  );
}
