import React, {useContext, useEffect, useState} from 'react';
import {Text, Flex, Box, Spacer} from '@chakra-ui/react';
import ProductLogo from './productLogo';
import ProductInsightTags from './productInsightTags';
import { ProductContext } from '../lib/productContext';
import { Link } from 'react-router-dom';

export default function ProductListItem() {

  const { product } = useContext(ProductContext);

  return (
    <Link to={`/products/${product.id}`} >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={3}
        backgroundColor="white"
        _hover={{
          background: "background.grey",
        }}
      >
        <Flex alignItems={'center'}>
          <ProductLogo
            size={40}
            productId={product.id}
            hasLogo={product.has_logo}
            productName={product.name}
          />
          <Box ml={2} >
            <Text fontSize={16} >{product.name}</Text>
          </Box>
        </Flex>
        <ProductInsightTags productId={product.id} />
      </Flex>
    </Link>
  );
}
