'use client'

import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import usePersonaApi from '../../lib/usePersonaApi';
import {forwardRef} from '@chakra-ui/system';
import { PersonaContext } from '../../lib/personaContext';

const PersonaNameForm = forwardRef((props, ref) => {

  const {
    persona,
    personaId,
    refresh,
  } = useContext(PersonaContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const personaApi = usePersonaApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if(name && personaId) {
        await personaApi.updateName(name, personaId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(persona.name) {
      setValue('name', persona.name);
    }
  }, [persona.name]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.name}>
              <Input
                placeholder='Name'
                {...register('name', {
                  required: true,
                  minLength: 3
                })}
              />
              <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                    <IconButton
                      variant="greyOutline"
                      icon={<MdClose />}
                      onClick={onCancel}
                    />
                    )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='blueOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default PersonaNameForm;


