'use client'

import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Box} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input, Button, Text, FormLabel} from '@chakra-ui/react';
import {forwardRef} from '@chakra-ui/system';
import { AuthContext } from '../lib/authContext';

const UpdatePasswordForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onError,
  } = props;

  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, isValid, errors }
  } = useForm({ mode: 'onBlur'});

  const authContext = useContext(AuthContext);
  const [globalError, setGlobalError] = useState(null)
  
  const onSubmit = async (data) => {
    try {
      setGlobalError(null)
      if(onSubmitting) {
        onSubmitting();
      }
      const password = data.password;
      if(password) {
        await authContext.updatePassword(password);
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      setGlobalError(e.message);
      if(onError) {
        onError();
      }
      console.error(e);
    }
  };

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <FormControl isInvalid={errors?.password} mb={2}>
              <FormLabel>New Password</FormLabel>
              <Input
                placeholder='Password'
                type="password"
                {...register('password', {
                  required: true,
                  minLength: {value: 6, message: 'Password should have at least 6 characters'}
                })}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.confirmPassword} mb={3}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                placeholder='Confirm Password'
                type="password"
                {...register('confirmPassword', {
                  required: true,
                  minLength: {value: 6, message: 'Password confirmation should have at least 6 characters'},
                  validate: (val) => {
                    if (watch('password') != val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
            </FormControl>
            {globalError && (
              <Box mb={3}>
                <Text color={'text.medium.red'}>{globalError}</Text>
              </Box>
            )}
            <Button
              style={{
                display: hideSave ? 'none' : null,
              }}
              ref={ref}
              ml={2}
              type={'submit'}
              aria-label='check'
              variant='greyFill'
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              Update
            </Button>
        </form>
      </Box>
    </>
  );
});

export default UpdatePasswordForm;


