'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/layout';
import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import useExperienceApi from '../../../experiences/lib/useExperienceApi';
import { Select } from 'chakra-react-select';
import usePersonaApi from '../../../persona/lib/usePersonaApi';
import useProductsApi from '../../lib/useProductsApi';
import { ProductPageContext } from '../lib/productPageContext';

const NewExperienceInProductForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    productId,
    groupId,
    objectiveDefault
  } = props;

  const {
    personaList
  } = useContext(ProductPageContext);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: "onChange"
  });

  const experienceApi = useExperienceApi();
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [personaOptionsList, setPersonaOptionsList] = useState([]);

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const objective = data.objective;
      let newExperience;
      if (objective) {
        newExperience = await experienceApi.insertExperience(objective);
        if(groupId) {
          await productApi.linkManyExperiencesToProduct(productId, [newExperience.id], groupId);
        } else {
          await productApi.linkManyExperiencesToProduct(productId, [newExperience.id]);
        }
        if (data.persona) {
          const personaIds = data.persona.map(p => p.value);
          await experienceApi.linkManyPersonaToExperience(newExperience.id, personaIds);
        }
      }
      if (onSubmitted) {
        onSubmitted(newExperience);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const fetchData = async () => {
    try {
      let productUsers = Array.from(personaList);
      productUsers = productUsers.map((u) => ({label: u.name, value: u.id}))
      const allUsers = await personaApi.fetchMyPersonaList();
      const otherUsers = [];
      allUsers.forEach((p) => {
        const alreadyExists = !(productUsers
          .every(productUser => productUser.value !== p.id)); 
        if (!alreadyExists) {
          otherUsers.push({ label: p.name, value: p.id })
        }
      });
      const groupedOptions = [{
        label: 'User of the Product',
        options: productUsers
      }, {
        label: (productUsers && productUsers.length > 0) ? 'Other Personas' : '',
        options: otherUsers
      }]
      setPersonaOptionsList(groupedOptions);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (productId) {
      fetchData();
    }
  }, [productId]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if(objectiveDefault) {
      setValue('objective', objectiveDefault, { shouldTouch: true, shouldValidate: true })
    }
  }, [objectiveDefault])

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <FormControl isInvalid={errors && errors.objective} mb={3}>
            <FormLabel>Objective</FormLabel>
            <Input
              placeholder='Action: Make… Find… Search…'
              {...register('objective', {
                required: true,
                minLength: 3
              })}
            />
            <FormErrorMessage>{errors && errors.objective}</FormErrorMessage>
          </FormControl>
          <Controller
            control={control}
            name="persona"
            rules={{}}
            render={({
              field: { onChange, onBlur, value, objective, ref },
              fieldState: { invalid, error }
            }) => (
              <FormControl isInvalid={errors && errors.persona}>
                <FormLabel>Involved Persona</FormLabel>
                <Select
                  isMulti
                  options={personaOptionsList}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
                <FormErrorMessage>{errors && errors.persona}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default NewExperienceInProductForm;


