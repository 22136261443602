import {supabase} from '../../supabase.client';
import memberStatusDictionnary from '../../lib/memberStatusDictionnary';
import roleDictionnary from '@/lib/roleDictionnary';

const usePersonaApi = () => {

  const searchManyPersona = async (searchTerm) => {
    try {
      const { data, error } = await supabase
      .from('persona')
      .select('*')
      .or(`name.ilike.%${searchTerm}%,bio.ilike.%${searchTerm}%,job.ilike.%${searchTerm}%,location.ilike.%${searchTerm}%,education.ilike.%${searchTerm}%,interests.ilike.%${searchTerm}%,hobbies.ilike.%${searchTerm}%`)
      .eq('engine_status', 'visible')
      if (error) {
        throw (error)
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyPersonaList = async (options) => {
    try {
      let { data, error} = await supabase.rpc('get_my_persona_list');
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPersona = async (id, options) => {
    try {
      let { data } = await supabase.from("persona")
        .select(`
          *,
          products ( id, name )
        `)
        .eq('id', id)
        .limit(1)
        .single();
      if(options && options.thumbnail === true) {
       data.thumbnail = await fetchPersonaThumbnail(data.id);
      }
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaByProductId = async (productId) => {
    try {
      try {
        const {data, error} = await supabase.rpc('get_persona_list_by_product_id', {
          arg_product_id : productId
        });
        if(data) {
          return data;
        } else {
          throw error;
        }
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaNotInProductForUser = async (productId, userId) => {
    try {
      const {data, error} = await supabase.rpc('get_persona_list_not_in_product_for_user', {
        arg_product_id : productId,
        arg_user_id : userId
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaByExperienceId = async (experienceId) => {
    try {
      const {data, error} = await supabase.rpc('get_persona_by_experience_id', {
        arg_experience_id: experienceId
      })
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaByProductExperienceGroupId = async (groupId) => {
    try {
      const {data, error} = await supabase.rpc('get_persona_list_by_product_experience_group', {
        arg_group_id: groupId,
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };


  const fetchManyPersonaByPersonaExperienceGroupId = async (groupId) => {
    try {
      const {data, error} = await supabase.rpc('get_persona_list_by_persona_experience_group', {
        arg_group_id: groupId,
      });
      if(data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };


  const fetchManyPersonaByInsightId = async (id) => {
    try {
      const result = await supabase.from('insight')
        .select(`
          persona (*)
        `)
        .eq('id', id)
        .single();
      return result.data.persona;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaNotInProductId = async (id) => {
    try {
      const {data}  = await supabase.from('products')
        .select(`
          *,
          persona(id)
        `)
        .eq('id', id)
        .single();
      const ids = data.persona.map((p) => {
        return p.id;
      });
      const result  = await supabase.from('persona')
        .select()
        .not('id', 'in',  JSON.stringify(ids).replace('[', '(').replace(']', ')') );
      return result.data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchManyPersonaNotInExperience = async (experienceId) => {
    try {
      try {
        let { error, data } = await supabase.rpc('get_persona_list_not_in_experience',
          { arg_experience_id: experienceId});
        if(error) {
          throw error
        } else {
          return data;
        }
      } catch (e) {
        console.error(e)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPersonaListWithExperienceByProductId = async (productId) => {
    try {
      let { data } = await supabase.rpc('get_persona_list_with_experiences_by_product_id',
        {arg_product_id: productId});
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const insertPersona = async (name) => {
    try {
      const {data, error} = await supabase
        .from('persona')
        .insert({name})
        .select()
        .single();
        if(data) {
          return data; 
        } else if (error) {
          throw error
        }
    } catch (e) {
      console.error(e);
    }
  };

  const linkManyProductToPersona = async (personaId, productIds) => {
    try {
      const productsPersona = productIds.map(p => ({
        persona_id: personaId,
        product_id: p
      }));
      const {error} = await supabase
      .from('products_persona')
      .insert(productsPersona).select();
      if(error) {
        throw error;
      }
    } catch (e) {
      console.error(e)
    }
  };

  const updateName = async (name, personaId) => {
    try {
     const result =  await supabase.from('persona')
        .update({name})
        .eq('id',personaId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateBio = async (bio, personaId) => {
    try {
      const result =  await supabase.from('persona')
        .update({bio})
        .eq('id',personaId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateAttributes = async (attributes, personaId) => {
    try {
      const result =  await supabase.from('persona')
        .update({
          age: attributes.age,
          job: attributes.job,
          education: attributes.education,
          family_status: attributes.family_status,
          location: attributes.location,
          interests: attributes.interests
        })
        .eq('id',personaId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateQuote = async (quote, personaId) => {
    try {
      const result =  await supabase.from('persona')
        .update({quote})
        .eq('id',personaId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const deletePersona = async (id) => {
    try {
      let result = await supabase.rpc('delete_persona',
      { arg_persona_id: id});
      if(result.error) {
        throw(result.error)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadThumbnail = async (files, personaId) => {
    try {
      await supabase.storage
      .from('persona')
      .upload(`/thumbnails/${personaId}x64.png`, files[64], {
        upsert: true
      });
      await supabase.storage
      .from('persona')
      .upload(`/thumbnails/${personaId}x128.png`, files[128], {
        upsert: true
      });
      await supabase.storage
      .from('persona')
      .upload(`/thumbnails/${personaId}x256.png`, files[256], {
        upsert: true
      });
      await supabase.from('persona')
      .update(
        {
          has_thumbnail: true,
          thumbnail_updated_at: new Date().toISOString()
        })
      .eq('id',personaId)
      .select();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteThumbnail = async (personaId) => {
    try {
      await supabase
        .storage
        .from('persona')
        .remove([
          `/thumbnails/${personaId}x64.png`,
          `/thumbnails/${personaId}x128.png`,
          `/thumbnails/${personaId}x256.png`
        ]);
        await supabase.from('persona')
        .update({has_thumbnail: false})
        .eq('id', personaId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const downloadThumbnail = async (personaId, size = 128) => {
    try {
      const resultUpdatedAt = await supabase.from('persona')
        .select()
        .eq('id', personaId)
        .select()
        .single();
      if(resultUpdatedAt.data) {
        const {data, error} = await supabase.storage
        .from('persona')
        .download(`/thumbnails/${personaId}x${size}.png?updated_at=${resultUpdatedAt.data.thumbnail_updated_at}`);
        if(data) {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(data);
        } else {
          throw error;
        }
      } else {
        throw resultUpdatedAt.error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPersonaThumbnail = async (personaId) => {
    try {
      const thumbnail = await downloadThumbnail(personaId);
      return thumbnail;
    } catch (e) {
      console.error(e);
    }
  };

  const linkManyExperiencesToPersona = async (personaId, experienceIds, groupId) => {
    try {
      let experiencePersona = experienceIds.map(experienceId => ({
        experience_id: experienceId,
        persona_id: personaId
      }));
      if(groupId) {
        experiencePersona = experiencePersona.map(ep => ({...ep, persona_experience_group_id: groupId}) )
      }
     const {data, error} = await supabase
        .from('experiences_persona')
        .insert(experiencePersona)
        .select();
      if(error) {
        throw (error)
      } else {
        return data;
      }
    } catch (e) {
      console.error(e)
    }
  };

  const unlinkProductFromPersona = async (productId, personaId) => {
    try {
      await supabase
        .from('products_persona')
        .delete()
        .eq('persona_id', personaId)
        .eq('product_id', productId);
    } catch (e) {
      console.error(e);
    }
  };

  const unlinkExperienceFromPersona = async (experienceId, personaId) => {
    try {
      await supabase
        .from('experiences_persona')
        .delete()
        .eq('experience_id', experienceId)
        .eq('persona_id', personaId);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMemberRole = async (personaId, profileId) => {
    try {
      let result = await supabase.from("persona_members")
      .select('role')
      .eq('persona_id', personaId)
      .eq('profile_id', profileId)
      .eq('status', memberStatusDictionnary.approved)
      .limit(1)
      .single();
      if(result.data && result.data.role) {
        return result.data.role;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserRights = async (personaId, profileId) => {
    try {
      const promiseArray = [
        supabase.rpc('can_user_update_persona', {
          arg_persona_id: personaId,
          arg_user_id: profileId
        }),
        supabase.rpc('can_user_select_persona', {
          arg_persona_id: personaId,
          arg_user_id: profileId
        }),
        supabase.rpc('can_user_delete_persona', {
          arg_persona_id: personaId,
          arg_user_id: profileId
        }),
      ]
      const result =  await Promise.all(promiseArray);
      return {
        can_update : result[0].data,
        can_select : result[1].data,
        can_delete : result[2].data,
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchMemberStatus = async (personaId, profileId) => {
    try {
      let result = await supabase.from("persona_members")
      .select('status')
      .eq('persona_id', personaId)
      .eq('profile_id', profileId)
      .limit(1)
      .single();
      if(result.data && result.data.status) {
        return result.data.status;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const revokeAccess = async (personaId, profileId) => {
    try {
      let {data, error} = await supabase.from("persona_members")
      .delete()
      .eq('persona_id', personaId)
      .eq('profile_id', profileId);
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const approveRequest = async (personaId, profileId) => {
    try {
      let {error } = await supabase.rpc('persona_approve_request', {
        arg_persona_id: personaId,
        arg_profile_id: profileId
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const declineRequest = async (personaId, profileId) => {
    try {
      let {error } = await supabase.rpc('persona_decline_request', {
        arg_persona_id: personaId,
        arg_profile_id: profileId
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }


  const updateRole = async (personaId, profileId, role) => {
    try {
      let {error } = await supabase.rpc('persona_update_role', {
        arg_persona_id: personaId,
        arg_profile_id: profileId,
        arg_role: role
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const addMembers = async (emails, personaId, role) => {
    try {
      const promises = emails.map((email) => {
        return new Promise(async (resolve, rejject) => {
          const result = await supabase.rpc('add_persona_member',
          { arg_email: email,
            arg_persona_id: personaId,
            arg_role: role
          });
          resolve(result);
        })
      })
      const result = await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  }

  const requestEdit = async (personaId, profileId) => {
    try {
      const {error} = await supabase.rpc('persona_request_edit', { 
        arg_persona_id : personaId,
        arg_profile_id : profileId,
      });
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const hasRequestedEdition = async (personaId, profileId) => {
    try {
      let {data, error} = await supabase.from("persona_members")
      .select()
      .eq('persona_id', personaId)
      .eq('profile_id', profileId)
      .eq('role', roleDictionnary.contributor)
      .eq('status', memberStatusDictionnary.requested)
      .limit(1)
      .maybeSingle();
      if(error) {
          throw error
      } else {
        return !!data
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    searchManyPersona,
    fetchMyPersonaList,
    fetchManyPersonaByProductId,
    fetchManyPersonaByProductExperienceGroupId,
    fetchManyPersonaByPersonaExperienceGroupId,
    insertPersona,
    fetchUserRights,
    fetchPersona,
    fetchPersonaListWithExperienceByProductId,
    updateName,
    updateBio,
    updateQuote,
    updateAttributes,
    deletePersona,
    uploadThumbnail,
    downloadThumbnail,
    deleteThumbnail,
    fetchManyPersonaNotInProductForUser,
    fetchManyPersonaByExperienceId,
    fetchManyPersonaNotInExperience,
    fetchManyPersonaByInsightId,
    linkManyProductToPersona,
    linkManyExperiencesToPersona,
    unlinkExperienceFromPersona,
    unlinkProductFromPersona,
    fetchMemberRole,
    fetchMemberStatus,
    revokeAccess,
    updateRole,
    addMembers,
    requestEdit,
    approveRequest,
    declineRequest,
    hasRequestedEdition
  }
};

export default usePersonaApi;
