'use client'

import React, {createContext, useState} from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './theme/chakra.theme';
import { MenuContext } from './menuContext';


export default function MenuProvider({children}) {

  const [visibility, setVisibility] = useState(true)

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  const close = () => {
    setVisibility(false);
  }

  const open = () => {
    setVisibility(true);
  }

  return (
    <MenuContext.Provider value={{
      visibility,
      toggleVisibility: toggleVisibility,
      close,
      open
    }}>
        {children}
    </MenuContext.Provider>
  );
}




