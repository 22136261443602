import React from 'react';
import { supabase } from '../../supabase.client';
import roleDictionnary from '../../lib/roleDictionnary';

const useProfileApi = () => {

  const profileSubscriber = (profileId, onReceive, channelId) => {
    return supabase
      .channel('profile-changes' + channelId)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'profiles',
        filter: 'id=eq.' + profileId,
      }, onReceive
      )
  }
  const fetchProfile = async (profileId, options) => {
    try {
      let { data } = await supabase.from('profiles')
        .select()
        .eq('id', profileId)
        .limit(1)
        .single();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProfileByUserEmail = async (email) => {
    try {
      let { data, error } = await supabase.rpc('get_profile_by_email',
        { arg_email: email });
      return data[0];
    } catch (e) {
      console.error(e)
    }
  };

  const fetchMembersProfilesByPersonaId = async (personaId, status = null ) => {
    try {
      let { data, error } = await supabase.rpc('get_members_profiles_by_persona_id', {
        arg_persona_id: personaId,
        arg_status: status,
      });
      if (error) {
        throw error;
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchMembersProfilesByProductId = async (productId, status = null ) => {
      try {
        let { data, error } = await supabase.rpc('get_members_profiles_by_product_id', {
          arg_product_id: productId,
          arg_status: status,
        });
        if (error) {
          throw error;
        } else if (data) {
          return data;
        }
      } catch (e) {
        console.error(e);
      }
  }

  const fetchEmailByProfileId = async (profileId) => {
    try {
      let { data, error } = await supabase.rpc('get_email_by_profile_id', {
        arg_profile_id: profileId
      });
      if (error) {
        throw error;
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fetchPersonaOwnerByPersonaId = async (personaId) => {
    try {
      const result = await supabase
        .from('persona_members')
        .select(`
          *,
          profiles!inner(*)
        `)
        .eq('persona_id', personaId)
        .eq('role', roleDictionnary.owner)
        .limit(1)
        .single();
      return result.data.profiles;
    } catch (e) {
      console.error(e);
    }
  }

  const fetchProductOwnerByProductId = async (productId) => {
    try {
      const result = await supabase
        .from('product_members')
        .select(`
          *,
          profiles!inner(*)
        `)
        .eq('product_id', productId)
        .eq('role', roleDictionnary.owner)
        .limit(1)
        .single();
      return result.data.profiles;
    } catch (e) {
      console.error(e);
    }
  }

  const fetchTemporaryUsersByPersonaId = async (personaId) => {
    try {
      const result = await supabase
        .from('persona')
        .select(`
          *,
          user_temp(*)
        `)
        .eq('id', personaId);
      return result.data[0].user_temp;
    } catch (e) {
      console.error(e);
    }
  }

  const fetchTemporaryUsersByProductId = async (productId) => {
    try {
      const result = await supabase
        .from('products')
        .select(`
          *,
          user_temp(*)
        `)
        .eq('id', productId);
      return result.data[0].user_temp;
    } catch (e) {
      console.error(e);
    }
  }

  const updatePseudo = async (pseudo, profileId) => {
    try {
      const result = await supabase.from('profiles')
        .update({ pseudo })
        .eq('id', profileId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateEmail = async (email) => {
    try {
      const emailRedirectTo = window.location.protocol
        + '://'
        + window.location.hostname
        + '/profile';
      const result = await supabase.auth.updateUser(
        {
          email,
          emailRedirectTo
        },
        { emailRedirectTo }
      );
      if (result.error) {
        throw result.error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updatePassword = async (password) => {
    try {
      const result = await supabase.auth.updateUser({ password })
      if (result.error) {
        throw result.error;
      }
    } catch (e) {
      throw e;
    }
  };

  const updateDescription = async (description, profileId) => {
    try {
      const result = await supabase.from('profiles')
        .update({ description })
        .eq('id', profileId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const uploadThumbnail = async (files, profileId) => {
    try {
      await supabase.storage
      .from('profiles')
      .upload(`/thumbnails/${profileId}x64.png`, files[64], {
        upsert: true
      });
      await supabase.storage
        .from('profiles')
        .upload(`/thumbnails/${profileId}x128.png`, files[128], {
          upsert: true
        });
      await supabase.storage
        .from('profiles')
        .upload(`/thumbnails/${profileId}x256.png`, files[256], {
          upsert: true
        });
      await supabase.from('profiles')
        .update({
          has_thumbnail: true,
          thumbnail_updated_at: new Date().toISOString()
        })
        .eq('id', profileId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteThumbnail = async (profileId) => {
    try {
      await supabase
        .storage
        .from('profiles')
        .remove([
          `/thumbnails/${profileId}x64.png`,
          `/thumbnails/${profileId}x128.png`,
          `/thumbnails/${profileId}x256.png`
      ]);

      await supabase.from('profiles')
        .update({ has_thumbnail: false })
        .eq('id', profileId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const downloadThumbnail = async (profileId, size = 128) => {
    try {
      const resultUpdatedAt = await supabase.from('profiles')
      .select()
      .eq('id', profileId)
      .select()
      .single();
    if(resultUpdatedAt.data) {
      const { data, error } = await supabase.storage
      .from('profiles')
      .download(`/thumbnails/${profileId}x${size}.png?updated_at=${resultUpdatedAt.data.thumbnail_updated_at}`);
      if(data) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(data);
      } else {
        throw error;
      }
    } else {
      throw resultUpdatedAt.error;
    }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProfileThumbnail = async (profileId) => {
    try {
      const thumbnail = await downloadThumbnail(profileId);
      return thumbnail;
    } catch (e) {
      console.error(e);
    }
  };

  const disbaleAccount = () => {

  };

  return {
    fetchProfile,
    fetchMembersProfilesByPersonaId,
    fetchTemporaryUsersByPersonaId,
    fetchProfileByUserEmail,
    fetchMembersProfilesByProductId,
    fetchProductOwnerByProductId,
    fetchTemporaryUsersByProductId,
    fetchProfileThumbnail,
    updatePseudo,
    updateDescription,
    uploadThumbnail,
    downloadThumbnail,
    deleteThumbnail,
    fetchPersonaOwnerByPersonaId,
    fetchProductOwnerByProductId,
    profileSubscriber,
    fetchEmailByProfileId,
    updatePassword,
    updateEmail
  }
};

export default useProfileApi;
