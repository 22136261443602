import React, {useEffect, useState} from 'react';
import {Box} from '@chakra-ui/layout';

export default function SizableImage({src, maxWidth = 100, maxHeight = 50}) {

  const [width, setWidth] = useState(null);
  const [parentWidth, setParentWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [parentHeight, setParentHeight] = useState(null);


  const onImageDisplayed = (e) => {
    if(maxWidth !== 'auto' || maxHeight !== 'auto' ) {
      if(e.target.naturalWidth > e.target.naturalHeight) {
        setParentWidth(maxWidth + 'px');
        setWidth('100%');
        setParentHeight('auto');
        setHeight('auto');
      } else if(e.target.naturalWidth < e.target.naturalHeight) {
        setParentWidth('auto');
        setWidth('auto');
        setParentHeight(maxHeight + 'px');
        setHeight('100%');
      } else if (e.target.naturalWidth === e.target.naturalHeight) {
        if(maxWidth !== 'auto') {
          maxHeight = maxWidth;
        } else if(maxHeight !== 'auto') {
          maxWidth = maxHeight;
        }
        setParentWidth(maxWidth + 'px');
        setWidth('auto');
        setParentHeight(maxHeight + 'px');
        setHeight('auto');
      }
    } else {
      setParentWidth('auto');
      setWidth('auto');
      setParentHeight('auto');
      setHeight('auto');
    }
  };


  return (
    <Box style={{width: parentWidth, height: parentHeight }}>
      { src && (
        <img
          onLoad={onImageDisplayed}
          src={src}
          style={{width: width, height: height}}
        />
      )}
    </Box>
  );
}
