'use client'

import React, {useEffect, useState} from 'react';
import {forwardRef} from '@chakra-ui/system';
import {Select} from 'chakra-react-select';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Skeleton,
} from '@chakra-ui/react';
import {Controller, useForm} from 'react-hook-form';
import usePersonaApi from '../lib/usePersonaApi';
import useExperienceApi from '../../experiences/lib/useExperienceApi';

const AddPersonaToExperienceForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onSubmitted,
    onSubmitting,
    experienceId,
    onCreateNew
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();
  const personaApi = usePersonaApi();
  const [personaOptionsList, setPersonaOptionsList] = useState(null);

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const personaId = data.persona.value;
      await experienceApi.linkManyPersonaToExperience(experienceId, [personaId]);
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      const newPersonaList = await personaApi.fetchManyPersonaNotInExperience(experienceId);
      const newPersonaOptionsList = newPersonaList.map((p) => {
        return {label: p.name, value: p.id}
      });
      if (onCreateNew) {
        newPersonaOptionsList.unshift({
          label: '+ New Persona',
          value: -1,
        });
      }
      setPersonaOptionsList(newPersonaOptionsList);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(experienceId) {
      fetchData();
    }
  }, [experienceId]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Controller
          control={control}
          name="persona"
          rules={{ required: "Please enter at least one persona" }}
          render={({
                     field: { onChange, onBlur, value, name, ref },
                     fieldState: { invalid, error }
                   }) => (
            <FormControl isInvalid={errors && errors.persona}>
              <FormLabel>Select a persona</FormLabel>
              {!personaOptionsList && (
                  <Skeleton w={"100%"} height={"30px"}/>
              )}
              {personaOptionsList && (
              <Select
                options={personaOptionsList}
                onChange={(e) => {
                  if(e.value === -1 && onCreateNew) {
                    onCreateNew();
                  } else {
                    onChange(e);
                  }
                }}
                onBlur={onBlur}
                value={value}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
              )}
              <FormErrorMessage>{errors && errors.persona}</FormErrorMessage>
            </FormControl>
          )}
        />
        <Button
          style={{
            display: hideSave ? 'none' : 'inherit',
          }}
          ref={ref}
          isLoading={isSubmitting}
          disabled={!isValid}
          type='submit'
          my={2}
        >Save</Button>
      </form>
    </>
  );
});

export default AddPersonaToExperienceForm;


