'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Skeleton } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';
import ImageInput from '../../../components/imageInput';
import useProductsApi from '../../lib/useProductsApi';
import { forwardRef } from '@chakra-ui/system';
import { Controller, useForm } from 'react-hook-form';
import { ProductContext } from '../../lib/productContext';

const ProductLogosForm = forwardRef((props, ref) => {

  const {product} = useContext(ProductContext);
  const [downloading, setDownloading] = useState(false);

  const {
    hideSave,
    onSubmitting,
    onSubmitted,
    onValidityChange,
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const productsApi = useProductsApi();

  const fetchImages = async () => {
    try {
      setDownloading(true);
      if(product.has_logo_with_text) {
        const newLogo = await productsApi.downloadLogoWithText(product.id);
        setValue('logoWithText',{originalImage: newLogo} )
      } else {
        setValue('logoWithText', null )
      }
      if(product.has_logo) {
        const newLogo = await productsApi.downloadLogo(product.id);
        setValue('logo', {originalImage: newLogo})
      } else {
        setValue('logo', null )
      }
      setDownloading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const logo = data.logo;
      const logoWithText = data.logoWithText;
      if (product.id) {
        if (logo) {
          await productsApi.uploadLogo({
            '64': logo[64],
            '128': logo[128],
            '256': logo[256]
          }, product.id)
        } else {
          await productsApi.deleteLogo(product.id);
        }
        if (logoWithText) {
          await productsApi.uploadLogoWithText({
            '64': logoWithText[64],
            '128': logoWithText[128],
            '256': logoWithText[256]
          }, product.id)
        } else {
          await productsApi.deleteLogoWithText(product.id);

        }
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if(product) {
      fetchImages();
    }
  }, [product]);

  return (
    <>
      <Box
        border="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        backgroundColor="white"
        p={3}
      >
        <form onSubmit={handleSubmit(onSubmit)} >
          <Controller
            control={control}
            name="logo"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error }
            }) => (
              <FormControl isInvalid={errors && errors.logo} mb={3}>
                <FormLabel>Upload a logo</FormLabel>
                {downloading && (<Skeleton height={"40px"} />)}
                {!downloading && (
                  <ImageInput
                  maxWidth={100}
                  value={value}
                  crop={{
                    aspect: 1,
                    zoom: 1,
                    x: 0,
                    y: 0
                  }}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  onBlur={onBlur}
                  outputWidths={[64, 128, 256]}
                />
                )}
                <FormErrorMessage>{errors && errors.logo}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="logoWithText"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error }
            }) => (
              <FormControl isInvalid={errors && errors.logoWithText}>
                <FormLabel>Upload a logo with text</FormLabel>
                {downloading && (<Skeleton height={"40px"} />)}
                {!downloading && (
                    <ImageInput
                      maxWidth={100}
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      onBlur={onBlur}
                      outputWidths={[128, 256]}
                  />
                )}
                <FormErrorMessage>{errors && errors.logoWithText}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={(!isValid || downloading)}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default ProductLogosForm;


