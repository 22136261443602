'use client'

import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import usePersonaApi from '../../lib/usePersonaApi';
import {forwardRef} from '@chakra-ui/system';
import {Textarea} from '@chakra-ui/textarea';
import { PersonaContext } from '../../lib/personaContext';

const PersonaQuoteForm = forwardRef((props, ref) => {

  const {
    persona,
    personaId,
    refresh,
  } = useContext(PersonaContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm();

  const personaApi = usePersonaApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const quote = data.quote;
      if(personaId) {
        await personaApi.updateQuote(quote, personaId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(persona.quote) {
      setValue('quote', persona.quote);
    }
  }, [persona]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'flex-start'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.quote}>
              <Textarea
                placeholder='Quote…'
                {...register('quote', {})}
              />
              <FormErrorMessage>{errors && errors.quote}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                    <IconButton
                      variant="greyOutline"
                      icon={<MdClose />}
                      onClick={onCancel}
                    />
                    )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='blueOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default PersonaQuoteForm;


