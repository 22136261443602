import React, { useContext, useEffect } from 'react';
import { Spacer } from '@chakra-ui/layout';
import { Button, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { MdDevices, MdFace, MdHome, MdOutlineArrowCircleLeft } from 'react-icons/md';
import { MenuContext } from '../lib/menuContext';
import { Link } from 'react-router-dom';

export default function SideMenu() {

  const menuContext = useContext(MenuContext)

  return (
    <>
      {menuContext.visibility && (
        <Flex
          w={200}
          flexShrink={0}
          borderRight="1px"
          borderColor="divider.grey"
          backgroundColor="white"
          flexDirection={'column'}
          py={3}
          px={3}
        >
          <Link to="/"   >
            <Button
              boxSizing='borderBox'
              display="flex"
              leftIcon={<MdHome size={'15px'} />}
              justifyContent={'start'}
              height="auto"
              w={'100%'}
              px={3}
              py={2}
              mb={2}
              variant={
                (window.location.href.includes('/home') ? 'orangeOutline' : 'greyTransparent')
              }
            >Home</Button>
          </Link>
          <Link to="/persona">
            <Button
              leftIcon={<MdFace size={'15px'} />}
              boxSizing='borderBox'
              display="flex"
              justifyContent={'start'}
              height="auto"
              w={'100%'}
              px={3}
              py={2}
              mb={2}
              variant={
                (window.location.href.includes('/persona') ? 'blueOutline' : 'greyTransparent')
              }
            >Persona</Button>
          </Link>
          <Link to="/products">
            <Button
              leftIcon={<MdDevices size={'15px'} />}
              boxSizing='borderBox'
              display="flex"
              justifyContent={'start'}
              height="auto"
              w={'100%'}
              px={3}
              py={2}
              mb={2}
              variant={
                (window.location.href.includes('/products') ? 'purpleOutline' : 'greyTransparent')
              }
            >Products</Button>
          </Link>
          <Spacer />
          <Tooltip label='Hide Menu' >
            <Flex>
              <IconButton
                variant="greyTransparent"
                fontSize='20px'
                color="text.medium.grey"
                icon={<MdOutlineArrowCircleLeft />}
                onClick={menuContext.close}
              />
            </Flex>
          </Tooltip>
        </Flex>
      )}
    </>
  );
}
