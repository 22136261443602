'use client'

import React from 'react';
import PersonaList from './components/personaList';
import { Flex } from '@chakra-ui/layout';

export default function Page() {

  return (
    <Flex
      w="100%"
      h="100%"
    >
      <PersonaList />
    </Flex>
  )
}
