import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag';
import { MdOutlineThumbUp, MdOutlineThumbDown, MdOutlineTouchApp } from 'react-icons/md';
import { PiHandsPrayingFill } from 'react-icons/pi';
import { Tooltip } from '@chakra-ui/react';
import CognitionIcon from '@/components/icons/cognitionIcon';

export default function InsightTags(props) {

  const {
    nbBenefits,
    nbFrustrations,
    nbNeeds,
    nbBehavior,
    nbMotivation,
  } = props;

  return (
    <Flex gap={1}>
        <Tooltip label='Positive Feedback'>
          <Tag
            variant="green"
            border="none"
            >
            <TagLeftIcon boxSize='12px' as={MdOutlineThumbUp} />
            <TagLabel>{nbBenefits}</TagLabel>
          </Tag>
        </Tooltip>
        <Tooltip label='Negative Feedback'>
          <Tag
            variant="red"
            border="none"
            >
            <TagLeftIcon boxSize='12px' as={MdOutlineThumbDown} />
            <TagLabel>{nbFrustrations}</TagLabel>
          </Tag>
        </Tooltip>
        <Tooltip label='Improvement Feedback'>
          <Tag
            variant="yellow"
            border="none"
            >
            <TagLeftIcon boxSize='12px' as={PiHandsPrayingFill} />
            <TagLabel>{nbNeeds}</TagLabel>
          </Tag>
        </Tooltip>
        <Tooltip label='Behavior'>
          <Tag
            variant="grey"
            border="none"
            >
            <TagLeftIcon boxSize='12px' as={MdOutlineTouchApp} />
            <TagLabel>{nbBehavior}</TagLabel>
          </Tag>
        </Tooltip>
        <Tooltip label='Motivation'>
          <Tag
            variant="purple"
            border="none"
            >
            <TagLeftIcon boxSize='12px' as={CognitionIcon} />
            <TagLabel>{nbMotivation}</TagLabel>
          </Tag>
        </Tooltip>
  </Flex>
  );
}
