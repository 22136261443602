import { Select, chakraComponents } from 'chakra-react-select';
import { useTheme } from '@emotion/react';
import { Flex, Icon, Text } from '@chakra-ui/react';

function CustomSelect(props) {

  const theme = useTheme();

  return (
    <Select
      {...props}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      components={{
        Option: (props) => {
          return (<chakraComponents.Option {...props}>
            {props.data.icon && <Icon as={props.data.icon} mr={2} color={(props.data.color ? props.data.color : 'inherit')} />} 
            <Text color={(props.data.color ? props.data.color : 'inherit')}>{props.children}</Text>
          </chakraComponents.Option>)
        }, SingleValue: ({ children, ...props }) => {
          return (
            <chakraComponents.SingleValue {...props} >
              <Flex alignItems={"center"}>
                {props.data.icon && <Icon as={props.data.icon} mr={2} color={(props.data.color ? props.data.color : 'inherit')} />} 
                <Text color={(props.data.color ? props.data.color : 'inherit')}>{props.data.label}</Text>
              </Flex>
            </chakraComponents.SingleValue>)
        }
      }}
      chakraStyles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? (theme.colors.text.disable.grey + ' !important') : provided.backgroundColor,
          color: (theme.colors.text.dark.grey + ' !important'),
        }),
      }}
    />
  );
}

export default CustomSelect;