'use client'
import React, {useContext} from 'react';
import { ProductContext } from '../../lib/productContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import { Flex, Tag, TagLabel, Text } from '@chakra-ui/react';
import ProductAsideTypeForm from './productAsideTypeForm';

export default function ProductAsideType() {

  const {
    product,
    rights,
    types
  } = useContext(ProductContext);

  return (
    <Flex
      px={3}
      py={2}
      >
      {product && (
        <InlineFormWrapper
          width={"100%"}
          canEdit={rights.can_update}
          value={
            <Flex
              gap={1}
              flexWrap={"wrap"}
              >
              {types && types.map((t,i) => (
                <Tag
                  variant="purple"
                  key={i}
                  >
                  <TagLabel>{t.label}</TagLabel>
                </Tag>
              ))}
            </Flex>
          }
        >
          {(close) => (
            <ProductAsideTypeForm
              onSubmitted={close}
              onCancel={close}
            />
          )}
        </InlineFormWrapper>
      )}
    </Flex>
  )
}




