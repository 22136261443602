import React, { useContext, useRef, useState } from 'react';
import useInsightApi from '../lib/useInsightApi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import InsightQuoteList from './insightQuoteList';
import InsightImageForm from './insightImageForm';
import { MdOutlineArrowBack } from 'react-icons/md';

export default function InsightImageModal(props) {

  const {
    insight,
    rights
  } = useContext(InsightContext)

  const {
    disclosure,
    onGoBack,
    onSaved
  } = props;

  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const ref1 = useRef();

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={"500px"} >
          <ModalHeader alignItems={"center"}>
            {onGoBack && <IconButton
              variant="greyTransparent"
              icon={<Icon boxSize={"24px"}
              as={MdOutlineArrowBack} />} 
              mr={3}
              onClick={() => {
                disclosure.onClose()
                if(onGoBack) {
                  onGoBack();
                }
              }}
              />
            }
            {(rights.can_update && insight.image_count === 0) ? 'Add Images' : ''}
            {(rights.can_update && insight.image_count !== 0) ? 'Edit Image' : ''}
            </ModalHeader>
          <ModalBody p={3}>
            <InsightImageForm
              hideSave={true}
              onSubmitting={() => {
                setIsAdding(true);
              }}
              onSubmitted={() => {
                setIsAdding(false);
                disclosure.onClose();
                if(onSaved) {
                  onSaved();
                }
              }}
              onValidityChange={(v) => {
                setIsValid(v);
              }}
              ref={ref1}
            />
          </ModalBody>
          <ModalFooter>
          <Button
              variant='orangeOutline'
              onClick={() => {
                disclosure.onClose()
                if(onGoBack) {
                  onGoBack();
                }
              }}
          >Cancel</Button>
          <Button
              variant="orangeFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
