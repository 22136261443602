import React, {useContext, useEffect, useState} from 'react';
import { ExperiencePageContext } from './experiencePageContext.js';
import useProductsApi from '@/products/lib/useProductsApi.js';
import { AuthContext } from '@/auth/lib/authContext.js';
import { ExperienceContext } from '../../lib/experienceContext.js';
import usePersonaApi from '@/persona/lib/usePersonaApi.js';

export default function ExperiencePageProvider({children}) {

  const {
    experienceId,
    isFetchingPersonaList,
    isFetchingProductList,
    personaList,
    productList,
    refreshPersonaList,
    refreshProductList,
  } = useContext(ExperienceContext);

  const {profile} = useContext(AuthContext)
  const [isFetchingInsightList, setIsFetchingInsightList] = useState(false);
  const [insightList, setInsightList] = useState(null);
  const [init, setInit] = useState(false);

  const refresh = async () => {
    try {
      await Promise.all([
        refreshPersonaList(),
        refreshProductList(),
      ])
     ;
      ;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ExperiencePageContext.Provider value={{
      isFetchingPersonaList,
      isFetchingProductList,
      isFetchingInsightList,
      productList,
      personaList,
      insightList,
      refresh: refresh,
      refreshProductList: refreshProductList,
      refreshPersonaList: refreshPersonaList
    }}>
      {children}
    </ExperiencePageContext.Provider>
  );
}