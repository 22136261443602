'use client'
import React, { useRef, useState, useContext } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz } from 'react-icons/md';
import roleDictionnary from '@/lib/roleDictionnary';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import ProductNameForm from '../../components/productNameForm';
import { ProductContext } from '../../lib/productContext';
import DeleteProductModal from '../../components/deleteProductModal';
import ProductValuePropositionForm from './productValuePropositionForm';
import ProductAsideTypeForm from './productAsideTypeForm';
import ProductAsideMainFeaturesForm from './productAsideMainFeaturesForm';

export default function ProductAsideEditMenu(props) {

  const {
    product,
    role
  } = useContext(ProductContext);

  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalEditName = useDisclosure();
  const modalEditValueProposition = useDisclosure();
  const modalDelete = useDisclosure();
  const modalEditTypes = useDisclosure();
  const modalEditMainFeatures = useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  return (
    <>
      {(product && (role && role !== roleDictionnary.viewer)) && (
        <>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={IconButton}
                  icon={<MdMoreHoriz />}
                  variant='purpleOutline'
                />
                <MenuList>
                  <MenuItem onClick={() => {
                    modalEditName.onOpen();
                  }}>Edit Name</MenuItem>
                  <MenuItem onClick={() => {
                    modalEditValueProposition.onOpen();
                  }}>
                  {product.value_proposition ? 'Edit Value Proposition' : 'Add Value Proposition'}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    modalEditTypes.onOpen();
                  }}>Edit Types</MenuItem>
                  <MenuItem onClick={() => {
                    modalEditMainFeatures.onOpen();
                  }}>Edit Main Features</MenuItem>
                  {(role === roleDictionnary.owner) && (
                    <MenuItem onClick={() => {
                      modalDelete.onOpen();
                    }}>
                      <Text color='text.medium.red' >Delete</Text>
                    </MenuItem>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        </>
      )}
      <Modal isOpen={modalEditName.isOpen} onClose={modalEditName.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Name</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {product && (
                <ProductNameForm
                  hideSave={true}
                  productId={product.id}
                  productName={product.name}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditName.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditName.onClose}
            >Close</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalEditValueProposition.isOpen}
        onClose={modalEditValueProposition.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Value Proposition</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {product && (
                <ProductValuePropositionForm
                  hideSave={true}
                  productId={product.id}
                  productDescription={product.description}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditValueProposition.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref2}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditValueProposition.onClose}
            >Close</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalEditMainFeatures.isOpen}
        onClose={modalEditMainFeatures.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Main Features</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {product && (
                <ProductAsideMainFeaturesForm
                  hideSave={true}
                  productId={product.id}
                  productDescription={product.description}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditMainFeatures.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref4}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditMainFeatures.onClose}
            >Close</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref4.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditTypes.isOpen} onClose={modalEditTypes.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Types</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {product && (
                <ProductAsideTypeForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditTypes.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref3}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditTypes.onClose}
            >Close</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref3.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteProductModal disclosure={modalDelete} />
    </>
  )
}




