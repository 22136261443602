import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { IconButton, MenuGroup, Skeleton, SkeletonCircle, Spinner, Tag, Tooltip } from '@chakra-ui/react';
import { MdArrowOutward, MdArrowRight, MdImage, MdMoreHoriz, MdOutlineAddAPhoto, MdOutlineAddPhotoAlternate, MdOutlineArchive, MdOutlineDelete, MdOutlineImage, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp, MdOutlineThumbDown, MdOutlineThumbUp, MdOutlineToggleOn, MdOutlineTouchApp, MdOutlineUnarchive, MdToggleOn, MdUnarchive, MdVerticalAlignBottom, MdVerticalAlignCenter, MdVerticalAlignTop } from 'react-icons/md';
import { useToast } from '@chakra-ui/react'
import useInsightApi from '../lib/useInsightApi';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react'
import InsightPicto from './insightPicto';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import InsightNameForm from './insightNameForm';
import { InsightContext } from '../lib/insightContext';
import InsightListItemPersonaProducts from './insightListItemPersonaProducts';
import AddQuoteIcon from '@/components/icons/addQuoteIcon';
import InsightDeleteModal from './insightDeleteModal';
import InsightEditQuoteModal from './insightEditQuoteModal';
import EditQuoteIcon from '@/components/icons/editQuoteIcon';
import InsightImageModal from './insightImageModal';
import InsightMoveToExperienceModal from './insightMoveToExperienceModal';

export default function InsightListItemMenu(props) {

  const {
    insight,
    rights,
    refresh
  } = useContext(InsightContext)

  const {
    onArchived,
    onUnarchived,
    onMovedToStart,
    onMovedToEnd,
    orderMax,
    onDelete
  } = props;

  const insightApi = useInsightApi();
  const [migrating, setMigrating] = useState(false);
  const modalDelete = useDisclosure();
  const modalQuote = useDisclosure();
  const modalImages = useDisclosure();
  const modalMoveToExperience = useDisclosure();
  const toast = useToast();

  const migrate = async () => {
    try {
      setMigrating(true);
      await insightApi.migrateThumbnailToImages(insight.id);
      refresh();
      setMigrating(false);
    } catch (e) {
      console.error(e)
    }
  };

  const moveToStart = async () => {
    try {
      await insightApi.updateOrder(1, insight.id)
      if (onMovedToStart) {
        onMovedToStart()
      }
    } catch (error) {
      console.error(error)
    }
  };

  const moveToEnd = async () => {
    try {
      await insightApi.updateOrder(orderMax, insight.id)
      if (onMovedToEnd) {
        onMovedToEnd()
      }
    } catch (error) {
      console.error(error)
    }
  };

  const archiveInsight = async () => {
    try {
      await insightApi.archiveInsight(insight.id);
      toast({
        position: 'bottom-right',
        description: "insight set as Outdated",
        duration: 1000,
        isClosable: true,
      });
      if (onArchived) {
        onArchived()
      }
    } catch (e) {
      console.error(e)
    }
  };

  const unarchiveInsight = async () => {
    try {
      await insightApi.unarchiveInsight(insight.id);
      toast({
        position: 'bottom-right',
        description: "Insight Unarchived",
        duration: 1000,
        isClosable: true,
      });
      if (onUnarchived) {
        onUnarchived()
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if (insight && insight.has_thumbnail) {
      migrate()
    }
  }, [insight]);

  return (
    <>
      {(insight && rights.can_update && !migrating) && (
        <Flex gap={2} >
          {!insight.archived &&
            <Tooltip label='Set as Outdated'>
              <IconButton
                variant="orangeOutline"
                icon={<Icon as={MdOutlineArchive} boxSize="16px" />}
                onClick={archiveInsight}
              />
            </Tooltip>
          }
          {insight.archived &&
            <Tooltip label='Set as Current'>
              <IconButton
                variant="orangeOutline"
                icon={<Icon as={MdOutlineUnarchive} boxSize="16px" />}
                onClick={unarchiveInsight}
              />
            </Tooltip>
          }
          <Menu >
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={IconButton}
                  variant="orangeOutline"
                  icon={<MdMoreHoriz />}
                />
                <MenuList>
                  <MenuItem onClick={moveToStart} >
                    <Icon
                      as={MdVerticalAlignTop}
                      boxSize={'14px'}
                      mr={2}
                    />
                    <Text>Move to start of the list</Text>
                  </MenuItem>
                  <MenuItem onClick={moveToEnd} >
                    <Icon
                      as={MdVerticalAlignBottom}
                      boxSize={'14px'}
                      mr={2}
                    />
                    <Text>Move to end of the list</Text>
                  </MenuItem>
                  <MenuItem onClick={modalImages.onOpen} >
                    {insight.image_count === 0 && (
                      <>
                        <Icon
                          as={MdOutlineAddPhotoAlternate}
                          boxSize={'14px'}
                          mr={2}
                        />
                        <Text>Add Image</Text>
                      </>
                    )}
                    {insight.image_count > 0 && (
                      <>
                        <Icon
                          as={MdOutlineImage}
                          boxSize={'14px'}
                          mr={2}
                        />
                        <Text>Edit Images</Text>
                      </>
                    )}
                  </MenuItem>
                  < MenuItem
                    onClick={modalQuote.onOpen}
                  >
                    {insight.quotes.length === 0 && (
                      <>
                        <AddQuoteIcon
                          boxSize={'14px'}
                          mr={2}
                        />
                        <Text>Add Quote</Text>
                      </>
                    )}
                    {insight.quotes.length > 0 && (
                      <>
                        <EditQuoteIcon
                          boxSize={'14px'}
                          mr={2}
                        />
                        <Text>Edit Quotes</Text>
                      </>
                    )}
                  </MenuItem>
                  {!insight.archived &&
                    < MenuItem onClick={archiveInsight} >
                      <Icon
                        as={MdOutlineArchive}
                        boxSize={'14px'}
                        mr={2}
                      />
                      <Text>Set as Outdated</Text>
                    </MenuItem>
                  }
                  {insight.archived &&
                    < MenuItem onClick={unarchiveInsight} >
                      <Icon
                        as={MdOutlineUnarchive}
                        boxSize={'14px'}
                        mr={2}
                      />
                      <Text>Set as Current</Text>
                    </MenuItem>
                  }
                  < MenuItem onClick={modalMoveToExperience.onOpen} >
                    <Icon
                      as={MdArrowOutward}
                      boxSize={'14px'}
                      mr={2}
                    />
                    <Text>Move to Experience</Text>
                  </MenuItem>
                  <MenuItem onClick={modalDelete.onOpen} >
                    <Icon
                      as={MdOutlineDelete}
                      color={'text.medium.red'}
                      boxSize={'14px'}
                      mr={2}
                    />
                    <Text color='fill.red' >Delete</Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
          <InsightEditQuoteModal
            disclosure={modalQuote}
          />
          <InsightDeleteModal
            disclosure={modalDelete}
            onDelete={onDelete}
          />
          <InsightImageModal
            disclosure={modalImages}
          />
          <InsightMoveToExperienceModal disclosure={modalMoveToExperience} />
        </Flex>
      )}
    </>
  );
}
