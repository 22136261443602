import React, { useContext, useEffect, useRef, useState } from 'react';
import useInsightApi from '../lib/useInsightApi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Button,
  Icon,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import { ExperienceContext } from '../../experiences/lib/experienceContext';
import InsightMoveToExperienceForm from './insightMoveToExperienceForm';

export default function InsightMoveToExperienceModal(props) {

  const {
    insight,
  } = useContext(InsightContext)

  const {
    experience,
    personaList,
    productList
  } = useContext(ExperienceContext)

  const {
    disclosure
  } = props;

  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] =  useState(false);;
  const ref =  useRef(false);


  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            Move to Experience
            </ModalHeader>
          <ModalBody p={3}>
            <InsightMoveToExperienceForm
               hideSave={true}
               onSubmitting={() => {
                 setIsAdding(true);
               }}
               onSubmitted={() => {
                 setIsAdding(false);
                 disclosure.onClose();
               }}
               onValidityChange={setIsValid}
               ref={ref}
             />
          </ModalBody>
          <ModalFooter>
          <Button
              variant='orangeOutline'
              onClick={() => {
                disclosure.onClose();
              }}
            >Cancel</Button>
            <Button
              variant="orangeFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
                disclosure.onClose();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
