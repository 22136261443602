'use client'
import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../lib/productContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import { Button, Flex, Text } from '@chakra-ui/react';
import ProductAsideMainFeaturesForm from './productAsideMainFeaturesForm';

export default function ProductAsideMainFeatures() {

  const {
    product,
    rights,
  } = useContext(ProductContext);

  const [seeMore, setSeeMore] = useState(false);
  const [displaySeeMore, setDisplaySeeMore] = useState(true);

  return (
    <> {product && (
      <Flex px={3} py={2} flexDirection={"column"} alignItems={"flex-start"}>
        <InlineFormWrapper
          width={"100%"}
          canEdit={rights.can_update}
          onToggle={(isOpen) => {
            setDisplaySeeMore(!isOpen);
          }}
          value={
            <Flex flexDirection={"column"}>
              <Text
                color="text.medium.grey"
              >Main Features</Text>
              <Flex flexDirection={"column"}>
                {(product.features && product.features.length > 0) && product.features.map((f, i) => (
                  <Flex key={i}>
                    {((i < 3) || (i >= 3 && seeMore)) &&
                      <Flex mt={1}>
                        <Text>{f}</Text>
                      </Flex>
                    }
                  </Flex>
                ))}
              </Flex>
            </Flex>
          }
        >
          {(close) => (
            <ProductAsideMainFeaturesForm
              onSubmitted={close}
              onCancel={close}
            />
          )}
        </InlineFormWrapper>
        {displaySeeMore &&
          <>
            {(product.features && product.features.length > 3 && !seeMore) &&
              <Button variant="purpleTransparent" px={0} onClick={() => { setSeeMore(true) }}>See more</Button>
            }
            {(product.features && product.features.length > 3 && seeMore) &&
              <Button variant="purpleTransparent" px={0} onClick={() => { setSeeMore(false) }}>See less</Button>
            }
          </>
        }

      </Flex>
    )}
    </>
  )
}




