import React, {useContext, useEffect, useState} from 'react';
import { PersonaContext } from '../../lib/personaContext.js';
import { PersonaPageContext } from './personaPageContext.js';
import useProductsApi from '@/products/lib/useProductsApi.js';
import useExperienceApi from '../../../experiences/lib/useExperienceApi';
import { AuthContext } from '@/auth/lib/authContext.js';

export default function PersonaPageProvider({children}) {

  const {
    personaId
  } = useContext(PersonaContext);

  const {profile} = useContext(AuthContext)
  const productApi = useProductsApi();
  const experienceApi = useExperienceApi();
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [isFetchingExperiences, setIsFetchingExperiences] = useState(false);
  const [productList, setProductList] = useState([]);
  const [experienceList, setExperienceList] = useState(null);
  const [experienceGroupList, setExperienceGroupList] = useState(null);
  const [experiencesProducts, setExperiencesProducts] = useState(null);
  const [init, setInit] = useState(false);

  const refreshProductList = async () => {
    try {
      setIsFetchingProducts(true);
      const newProductList = await productApi.fetchProductsByPersonaId(personaId);
      setProductList(newProductList);
      setIsFetchingProducts(false);
    } catch (error) {
      console.error(error)
    }
  }

  const refreshExperienceList = async () => {
    try {
      setIsFetchingExperiences(true);
      const result = await Promise.all([
        experienceApi.fetchExperiencesByPersonaId(personaId),
        experienceApi.fetchExperienceGroupsByPersonaId(personaId),
        productApi.fetchProductsWithExperienceByPersonaId(personaId)
      ]);
      setExperienceList(result[0]);
      setExperienceGroupList(result[1]);
      setExperiencesProducts(result[2]);
      setIsFetchingExperiences(false);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (personaId && profile) {
      if(!init) {
        refreshProductList();
        refreshExperienceList();
        setInit(true);
      }
    }
  }, [personaId,  profile]);

  return (
    <PersonaPageContext.Provider value={{
      isFetchingProducts,
      isFetchingExperiences,
      productList,
      experienceGroupList,
      refreshProductList: refreshProductList,
      refreshExperienceList: refreshExperienceList,
      experiencesProducts,
      experienceList
    }}>
      {children}
    </PersonaPageContext.Provider>
  );
}