import React, { useContext, useState } from 'react';
import { Text, Flex, IconButton, Box, useDisclosure, Icon, Button } from '@chakra-ui/react';
import ProductLogo from '../../../products/components/productLogo';
import { PersonaContext } from '../../lib/personaContext';
import { ProductContext } from '@/products/lib/productContext';
import { MdClose, MdOutlineWarning } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import useProductsApi from '@/products/lib/useProductsApi';
import { PersonaPageContext } from '../lib/personaPageContext';

export default function ProductInPersonaListItem() {

  const {
    refreshProductList,
    refreshExperienceList
  } = useContext(PersonaPageContext);

  const personaContext = useContext(PersonaContext);
  const {
    product,
  } = useContext(ProductContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const modalDelete = useDisclosure();
  const productApi = useProductsApi();

  async function removeProductFromPersona() {
    try {
      setIsDeleting(true);
      await productApi.unlinkPersonaFromProduct(
        personaContext.persona.id,
        product.id
      )
      await Promise.all([
        refreshProductList(),
        refreshExperienceList()
      ])
      setIsDeleting(false);
      modalDelete.onClose();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {product && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="white"
          role={'group'}
          flex={1}
          mb={1}
          px={3}
          h={"34px"}
          _hover={{
            backgroundColor: 'background.grey',
            borderRadius: '2px'
          }}
        >
          <Flex alignItems="center">
            <ProductLogo
              size={24}
              productId={product.id}
            />
            <Text ml={2}>{product.name}</Text>
          </Flex>
          {(personaContext.rights.can_update) && (
            <Box style={{ opacity: '0' }} _groupHover={{ opacity: '1 !important' }} >
              <IconButton
                icon={<MdClose />}
                size={'md'}
                variant='greyTransparent'
                onClick={(e) => {
                  modalDelete.onOpen();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </Box>
          )}
        </Flex>
      )}
      <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Remove Product {product.name}</ModalHeader>
          <ModalBody p={3} >
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
            >
              <Icon
                as={MdOutlineWarning}
                color={'text.medium.red'}
                boxSize={'18px'}
                mr={3}
              />
              <Text textStyle="body" color={'text.medium.red'} >Are you sure to remove the product {product.name} from persona {personaContext.persona.name}?</Text>
            </Flex>
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
              mt={2}
            >
              <Text textStyle="body" color={'text.medium.red'} >
                All experiences not related to other products of this persona will be removed.</Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={modalDelete.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isDeleting}
              onClick={removeProductFromPersona}>Remove</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
