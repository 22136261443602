'use client'

import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, FormLabel, Input, Textarea, Text} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useExperienceApi from '../../lib/useExperienceApi';
import { ExperienceContext } from '../../lib/experienceContext';

const PrototypeForm = forwardRef((props, ref) => {

  const {
    experience,
    experienceId,
    refresh,
  } = useContext(ExperienceContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const prototypeLink = data.prototypeLink;
      if(experienceId) {
        await experienceApi.updatePrototypeLink(prototypeLink, experienceId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(experience.prototype_link) {
      setValue('prototypeLink', experience.prototype_link);
    }
  }, [experience.prototype_link]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        w={"100%"}
        >
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.prototypeLink}>
            <FormLabel mb={0}>Prototype Link</FormLabel>
            <Text color="text.medium.grey" mb={1} >Bibliona supports figma prototypes only.</Text>
              <Textarea
                h={"150px"}
                placeholder='https://'
                {...register('prototypeLink', {
                  validate: value => value.includes('figma.com/proto') || value == '',
                })}
              />
              <FormErrorMessage>{errors && errors.prototypeLink}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
                )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='orangeOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default PrototypeForm;


