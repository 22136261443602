'use client'

import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { InsightContext } from '../lib/insightContext';
import InsightQuoteForm from './insightQuoteForm';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import { MdAdd, MdOutlineFormatQuote } from 'react-icons/md';


export default function InsightQuoteList(props) {

  const {
    insight,
    role,
    rights,
  } = useContext(InsightContext);

  const { onFormDisplayChange, onChange } = props;
  const [displayForm, setDisplayForm] = useState(false);

  useEffect(() => {
    if (onFormDisplayChange) {
      onFormDisplayChange(displayForm);
    }
  }, [displayForm])

  return (
    <>
      {insight && insight.quotes && insight.quotes.map((q, i) => (
        <Flex
          w="100%"
          key={i}
          alignItems={"center"}
          mb={2}
        >
          <Flex
            pl={2}
            w="100%"
            position={"relative"}
            _before={{
              content: '""', 
              position: 'absolute',
              width: '1px',
              height: "calc(100% - 20px)",
              top: '20px',
              left: "-3px",
              bg: 'text.light.grey'
            }}
          >
            <Flex
              position={"absolute"}
              color={"text.light.grey"}
              top="0"
              left="-10px"
            >
              <Icon as={MdOutlineFormatQuote} boxSize={"15px"} />
            </Flex>
            <InlineFormWrapper
              canEdit={rights.can_update}
              value={
                <Text
                  width={"100%"}
                  style={{ whiteSpace: 'break-spaces' }}
                  fontSize={"16px"}
                >
                  {q}
                </Text>
              }
              width={"100%"}
            >
              {(close) => (
                <Flex w={"100%"} pb={2}>
                  <InsightQuoteForm
                    onSubmitted={() => {
                      close();
                      if(onChange) {
                        onChange();
                      }
                    }}
                    onCancel={close}
                    insightIndex={JSON.stringify(i)}
                  />
                </Flex>
              )}
            </InlineFormWrapper>
          </Flex>
        </Flex>
      ))}
      {displayForm &&
        <InsightQuoteForm
          onSubmitted={() => {
            setDisplayForm(false);
            if(onChange) {
              onChange();
            }
          }}
          onCancel={() => {
            setDisplayForm(false);
          }}
        />
      }
      {!displayForm && rights.can_update && (
        <Button
          mt={2}
          px={0}
          leftIcon={<Icon as={MdAdd} />}
          variant="orangeTransparent"
          onClick={() => {
            setDisplayForm(true);
          }}
        >
          Add Quote
        </Button>
      )}
    </>
  )
}




