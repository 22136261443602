'use client'

import React, {useContext, useRef, useState} from 'react';
import {Box, Flex} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/react'
import {MdEdit} from 'react-icons/md';
import {Icon} from '@chakra-ui/icon';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import PersonaThumbnailForm from './personaThumbnailForm';
import PersonaThumbnail from '../../components/personaThumbnail';
import { PersonaContext } from '../../lib/personaContext';

export default function PersonaAsideThumbnail() {

  const {
    persona,
    rights,
    refresh
  } = useContext(PersonaContext);

  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalEditLogo = useDisclosure();
  const ref = useRef(null);

  return (
    <>
    <Flex>
        {persona && (
        <Box
        position={'relative'}
        w={ (persona.has_logo && persona.has_logo_with_text) ? '180px' : 'auto'}
        h={100}
        flexShrink={0}
        cursor={rights.can_update ? 'pointer' : 'default'}
        onClick={() => {
          if(rights.can_update) {
            modalEditLogo.onOpen();
          }
        }}
        >
       <PersonaThumbnail
          size={100}
        />
        { (rights.can_update) && (
        <Flex
            border="1px"
            borderColor="divider.grey"
            borderRadius="50%"
            backgroundColor="white"
            alignItems="center"
            justifyContent="center"
            w={30}
            h={30}
            style={{'cursor' : 'pointer'}}
            position={'absolute'}
            bottom={'0'}
            right={'0'}
            onClick={modalEditLogo.onOpen}
        >
            <Icon
            as={MdEdit}
            color={'text.secondaryGrey'}
            boxSize={'15px'}
            />
        </Flex>
        )}
        </Box>
        )}
    </Flex>
      <Modal isOpen={modalEditLogo.isOpen} onClose={modalEditLogo.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Edit Thumbnail</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {persona && (
                <PersonaThumbnailForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditLogo.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalEditLogo.onClose}
            >Close</Button>
            <Button
              variant='blueFill'
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}




