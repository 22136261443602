import React, { useContext, useEffect, useState } from 'react';
import useProductsApi from '../lib/useProductsApi';
import { ProductContext } from './productContext.js';
import { AuthContext } from '@/auth/lib/authContext';

export default function ProductProvider(props) {

  let {
    productId,
    originalProduct,
    fetchTypes = false,
    children,
    onFetchingData,
    onDataFetched,
    onInit,
  } = props;
  const { profile, hasProfile } = useContext(AuthContext);
  const [role, setRole] = useState();
  const [product, setProduct] = useState();
  const [types, setTypes] = useState();
  const [hasRequestedEdition, setHasRequestedEdition] = useState(false);
  const [rights, setRights] = useState({
    can_update: false,
    can_insert: false,
    can_delete: false
  });
  const productApi = useProductsApi();
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const refresh = async () => {
    try {
      if(onFetchingData) {
        onFetchingData()
      }
      const promises = [
        productApi.fetchProduct(productId),
        productApi.fetchMemberRole(productId, profile.id),
        productApi.hasRequestedEdition(productId, profile.id),
        productApi.fetchUserRights(productId, profile.id)
      ];
      const result = await Promise.all(promises);
      setProduct(result[0]);
      setRole(result[1]);
      setHasRequestedEdition(result[2]);
      setRights(result[3]);
      if(fetchTypes) {
        const types = await productApi.fetchProductTypeByProductId(productId);
        setTypes(types);
      }
      if(!isInit && onInit) {
        onInit(true);
        setIsInit(true);
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      refresh()
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

useEffect(() => {
    if(productId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
      setIsInit(false);
    }
  }, [productId, hasProfile]);
  
  useEffect(() => {
    if (originalProduct && hasProfile) {
      setProduct(originalProduct);
    }
  }, [originalProduct, hasProfile]);

  
  return (
    <ProductContext.Provider value={{
      product,
      productId,
      role,
      rights,
      refresh,
      hasRequestedEdition,
      types
    }} >
      {product && <>{children}</>}
    </ProductContext.Provider>
  );
}