'use client'

import React, {useContext, useEffect, useState} from 'react';
import {forwardRef} from '@chakra-ui/system';
import {Select, chakraComponents} from 'chakra-react-select';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Text,
  Skeleton
} from '@chakra-ui/react';
import {Controller, useForm} from 'react-hook-form';
import useExperienceApi from '../../../experiences/lib/useExperienceApi';
import { AuthContext } from '@/auth/lib/authContext';

const AddExperienceToPersonaForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onSubmitted,
    onSubmitting,
    personaId,
    onCreateNew,
    groupId
  } = props;

  const {profile} = useContext(AuthContext);

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();
  const [experienceOptionsList, setExperienceOptionsList] = useState(null);
  const [inputValue, setInputValue] = useState('');
  
  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const experienceId = data.experience.value;
      if(groupId) {
        await experienceApi.linkManyPersonaToExperience(experienceId, [personaId], groupId);
      } else {
        await experienceApi.linkManyPersonaToExperience(experienceId, [personaId]);
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      const newExperiences = await experienceApi.fetchExperiencesInPersonaAndInProductPersonaForUser(
        personaId,
        profile.id
      );
      const newExperienceList = newExperiences.map((p) => {
        return {label: p.objective, value: p.id}
      });
      if (onCreateNew) {
        newExperienceList.unshift({
          label: '+ Create New Experience',
          value: -1,
        });
      }
      setExperienceOptionsList(newExperienceList);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(personaId) {
      fetchData();
    }
  }, [personaId]);

  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Controller
            control={control}
            name="experience"
            rules={{ required: "Please enter at least one experience" }}
            render={({
                       field: { onChange, onBlur, value, name, ref },
                       fieldState: { invalid, error }
                     }) => (
              <FormControl isInvalid={errors && errors.experience} mb={3} >
                <FormLabel>Select an experience</FormLabel>
                {!experienceOptionsList && (
                  <Skeleton w={"100%"} height={"30px"}/>
                )}
                {experienceOptionsList && (
                    <Select
                    options={experienceOptionsList}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onInputChange={(e) => {
                      setInputValue(e);
                      if(experienceOptionsList) {
                        const newExperienceList =  Array.from(experienceOptionsList);
                        newExperienceList[0].label =  e ? `+ Create New Experience "${e}"` : '+ Create New Experience';
                        setExperienceOptionsList(newExperienceList);
                      }
                    }}
                    onChange={(e) => {
                      if(e.value === -1 && onCreateNew) {
                        onCreateNew(inputValue);
                      } else {
                        onChange(e);
                      }
                    }}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                <FormErrorMessage>{errors && errors.experience}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid}
            type='submit'
            my={2}
          >Save</Button>
        </form>
    </>
  );
});

export default AddExperienceToPersonaForm;


