'use client'

import { Flex, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ProductContext } from '../../lib/productContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import ProductValuePropositionForm from './productValuePropositionForm';

export default function ProductValueProposition(props) {

  const {
    product,
    rights
  } = useContext(ProductContext);

  return (
    <>
      {product && product.value_proposition && (
        <Flex
          bg="background.white"
          border="1px solid"
          borderColor="divider.grey"
          mb={3}
          p={3}
          flexDirection={"column"}
        >
          <Text
            color="text.dark.grey"
            fontSize={"18px"}
            fontWeight={"500"}
            mb={1}
          >
            Value Proposition
          </Text>
          <InlineFormWrapper
            canEdit={rights.can_update}
            value={
              <Text>
                {product.value_proposition}
              </Text>
            }
          >
            {(close) => (
              <ProductValuePropositionForm
                onSubmitted={close}
                onCancel={close}
              />
            )}
          </InlineFormWrapper>
        </Flex>
      )}
    </>
  )
}




