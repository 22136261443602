import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Icon, IconButton, Skeleton} from '@chakra-ui/react';
import {Text, Box, Flex, Spacer, Stack} from '@chakra-ui/layout';
import {MdAdd, MdFace} from 'react-icons/md';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import PersonaForm from '@/persona/components/personaForm';
import {MdArrowBack} from 'react-icons/md';
import AddPersonaToExperienceForm from '@/persona/components/addPersonaToExperienceForm';
import PersonaInExperienceListItem from './personaInExperienceListItem';
import { ExperienceContext } from '../../lib/experienceContext';
import PersonaProvider from '@/persona/lib/personaProvider';
import useExperienceApi from '../../lib/useExperienceApi';
import { useNavigate } from 'react-router-dom';

export default function PersonaInExperienceList({experienceId}) {

  const {
    rights,
  } = useContext(ExperienceContext);

  const {
    personaList,
    isFetchingPersonaList,
    refreshPersonaList
  } = useContext(ExperienceContext);

  const experiencesApi = useExperienceApi();
  const modalAddPersona = useDisclosure();
  const modalCreatePersona = useDisclosure();
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const linkNewPersonaToExperience = async (newPersona) => {
    try {
      await experiencesApi.linkManyPersonaToExperience(experienceId, [newPersona.id] );
      await refreshPersonaList();
      setIsAdding(false);
      modalCreatePersona.onClose();
      modalAddPersona.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Box>
        <Flex
          alignItems='center'
          mb={1}
          px={3}
        >
          <Icon
            as={MdFace}
            color={'text.primary'}
            boxSize={'14px'}
            mr={2}
            />
          <Text fontSize={14} > Involved Persona</Text>
          <Spacer />
          {rights.can_update && (
            <IconButton
              ml={2}
              variant="orangeOutline"
              icon={<MdAdd />}
              onClick={modalAddPersona.onOpen}
              size="sm"
            />
          )}
        </Flex>
        <Box maxH={300} overflowY={'scroll'}>
          { isFetchingPersonaList && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
              <Skeleton height='50px' />
              <Skeleton height='50px' />
              <Skeleton height='50px' />
            </Stack>
          )}
          { (!isFetchingPersonaList && personaList && personaList.length === 0) && (
            <Box px={3}>
              <Text color="text.light.grey" fontSize="12px">No persona so far.</Text>
            </Box>
          )}
          { (!isFetchingPersonaList && personaList) && personaList.map((p, i) => {
            return (
              <Box
                key={i}
                style={{'cursor' : 'pointer'}}
                onClick={() => {
                  navigate( `/persona/${p.id}`)
                }}
              >
                <PersonaProvider
                  originalPersona={p}
                  personaId={p.id}
                  >
                  <PersonaInExperienceListItem
                    onRemove={refreshPersonaList}
                  />
                </PersonaProvider>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Modal isOpen={modalAddPersona.isOpen} onClose={modalAddPersona.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Add Persona</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <AddPersonaToExperienceForm
                experienceId={experienceId}
                hideSave={true}
                onCreateNew={() => {
                  modalAddPersona.onClose();
                  modalCreatePersona.onOpen();
                }}
                onSubmitted={refreshPersonaList}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='orangeOutline'
              onClick={modalAddPersona.onClose}
            >Close</Button>
            <Button
              variant="orangeFill"
              ml={2}
              isLoading={isFetchingPersonaList}
              onClick={() => {
                ref1.current.click();
                modalAddPersona.onClose();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalCreatePersona.isOpen} onClose={modalCreatePersona.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>
            <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack/>}
              aria-label='New'
              mr={2}
              onClick={() => {
                modalCreatePersona.onClose();
                modalAddPersona.onOpen();
              }}
            />
            New Persona</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <PersonaForm
                hideSave={true}
                onSubmitted={(newPersona) => {
                  linkNewPersonaToExperience(newPersona)
                }}
                experienceId={experienceId}
                ref={ref2}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='orangeOutline'
              onClick={() => {
                modalCreatePersona.onClose();
                modalAddPersona.onOpen();
              }}
            >Close</Button>
            <Button
              variant="orangeFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                setIsAdding(true)
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
