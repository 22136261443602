import React, {useContext, useRef, useState} from 'react';
import {Button, Icon, IconButton, Skeleton, useDisclosure} from '@chakra-ui/react';
import {Box, Flex, Stack, Text} from '@chakra-ui/layout';
import {MdAdd, MdArrowBack, MdFace} from 'react-icons/md';
import PersonaInProductListItem from './personaInProductListItem';
import { ProductPageContext } from '../lib/productPageContext';
import { ProductContext } from '../../lib/productContext';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import AddPersonaToProductForm from '@/products/[id]/components/addPersonaToProductForm';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import PersonaForm from '@/persona/components/personaForm';
import PersonaProvider from '@/persona/lib/personaProvider';
import { useNavigate } from 'react-router-dom';

export default function PersonaInProductList({productId}) {

  const {
    rights,
  } = useContext(ProductContext);

  const {
    isFetchingPersonaList,
    personaList,
    refreshPersonaList,
  } = useContext(ProductPageContext);

  const [isAdding, setIsAdding] = useState(false);
  const personaApi = usePersonaApi()
  const navigate = useNavigate();
  const modalAddPersona = useDisclosure();
  const modalCreatePersona =  useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const linkNewPersonaToProduct = async (newPersona) => {
    try {
      await personaApi.linkManyProductToPersona(newPersona.id, [productId])
      refreshPersonaList();
      setIsAdding(false);
      modalCreatePersona.onClose();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Flex 
        w="100%"
        flexDirection={"column"}
        py={3}
      >
        <Flex 
          alignItems='center'
          justifyContent={"space-between"}
          px={3}
          mb={2}
        >
          <Flex 
            alignItems={"center"}
          >
            <Icon
              as={MdFace}
              boxSize={'14px'}
              mr={2}
            />
            <Text fontSize={14} >Users</Text>
          </Flex>
          {rights.can_update && (
            <IconButton
              ml={2}
              variant="purpleOutline"
              icon={<MdAdd />}
              size="sm"
              onClick={modalAddPersona.onOpen}
            />
          )}
        </Flex>
        <Box maxH={300} overflowY={'scroll'}>
          { isFetchingPersonaList && (
            <Stack p={3} spacing={2} backgroundColor={'white'}>
               <Skeleton height='30px' />
               <Skeleton height='30px' />
               <Skeleton height='30px' />
             </Stack>
          )}
          { (!isFetchingPersonaList && personaList && personaList.length === 0) && (
              <Box px={3}>
                <Text color="text.light.grey">No user so far.</Text>
              </Box>
          )}
           { (!isFetchingPersonaList && personaList) && personaList.map((p, i) => {
            return (
              <Box
                key={i}
                style={{'cursor' : 'pointer'}}
                onClick={() => {
                  navigate( `/persona/${p.id}`)
                }}
              >
                <PersonaProvider
                  originalPersona={p}
                  personaId={p.id}
                >
                  <PersonaInProductListItem
                    productId={productId}
                  />
                </PersonaProvider>
              </Box>
            );
          })}
        </Box>
      </Flex>
      <Modal isOpen={modalAddPersona.isOpen} onClose={modalAddPersona.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Add Persona to Product</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <AddPersonaToProductForm
                productId={productId}
                hideSave={true}
                onCreateNew={() => {
                  modalAddPersona.onClose();
                  modalCreatePersona.onOpen();
                }}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={() => {
                  setIsAdding(false);
                  modalAddPersona.onClose();
                  refreshPersonaList();
                }}
                ref={ref1}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalAddPersona.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalCreatePersona.isOpen} onClose={modalCreatePersona.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>
            <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack/>}
              aria-label='New'
              mr={2}
              onClick={() => {
                modalCreatePersona.onClose();
                modalAddPersona.onOpen();
              }}
            />
            New Persona</ModalHeader>
          <ModalBody >
            <Box p={3}>
              <PersonaForm
                hideSave={true}
                onSubmitting={() => {
                  setIsAdding(true);
                }}
                onSubmitted={(newPersona) => {
                  linkNewPersonaToProduct(newPersona)
                }}
                ref={ref2}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={() => {
                modalCreatePersona.onClose();
                modalAddPersona.onOpen();
              }}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
