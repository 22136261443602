'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text, Divider } from '@chakra-ui/layout';
import ProductAsideLogo from './productAsideLogo';
import PersonaInProductList from '@/products/[id]/components/personaInProductList';
import ProductAsideEditMenu from './productAsideEditMenu';
import { ProductContext } from '../../lib/productContext';
import { MdArrowBack } from 'react-icons/md';
import { IconButton } from '@chakra-ui/react';
import ProductNameForm from '../../components/productNameForm';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import { useTheme } from '@emotion/react';
import ProductAsideType from './productAsideType';
import ProductAsideMainFeatures from './productAsideMainFeatures';
import { useNavigate } from 'react-router-dom';

export default function ProductAside() {

  const theme = useTheme();

  const {
    product,
    productId,
    rights,
    types
  } = useContext(ProductContext);
  const navigate = useNavigate();
  const [space, setSpace] = useState();

  useEffect(() => {
    if (theme) {
      const spaceCurrent = parseInt(theme.space[3].replace('px', ''));
      setSpace((spaceCurrent * 2))
    }
  }, [theme])

  return (
    <>
      {product && (
        <Flex
          py={3}
          flexShrink={"0"}
          w="275px"
          borderRight="1px"
          borderColor="divider.grey"
          borderRadius="2px"
          backgroundColor="white"
          alignItems={'stretch'}
          flexDirection={'column'}
          gap={3}
          position="relative"
          overflow={"auto"}
        >
          <IconButton
            left={1}
            top={1}
            position="absolute"
            variant="greyTransparent"
            icon={<MdArrowBack />}
            size="lg"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Flex
            flexDirection={"column"}
            alignItems={'center'}
            gap={2}
            mb={1}
          >
            <ProductAsideLogo
              productId={productId}
            />
            <InlineFormWrapper
              canEdit={rights.can_update}
              value={
                <Text
                  px={3}
                  fontSize={20}
                  color="fill.purple"
                  textAlign={"center"}
                >
                  {product.name}
                </Text>
              }
            >
              {(close) => (
                <ProductNameForm
                  onSubmitted={close}
                  onCancel={close}
                />
              )}
            </InlineFormWrapper>
            <ProductAsideEditMenu />
          </Flex>
          <Flex flexDirection={"column"}>
            {(types && types.length > 0) && (
              <Flex flexDirection={"column"}>
                <Divider variant="purple" ml={3} width={"calc(100% - " + space + "px)"} />
                <ProductAsideType />
              </Flex>
            )}
            {(product.features && product.features.length > 0) && (
              <Flex flexDirection={"column"}>
               <Divider variant="purple" ml={3} width={"calc(100% - " + space + "px)"} />
               <ProductAsideMainFeatures />
             </Flex>
            )}
            <Flex flexDirection={"column"}>
              <Divider variant="purple" ml={3} width={"calc(100% - " + space + "px)"} />
              <PersonaInProductList productId={product.id} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}




