'use client'

import React, {useEffect, useContext} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useProductsApi from '../lib/useProductsApi';
import { ProductContext } from '../lib/productContext';

const ProductNameForm = forwardRef((props, ref) => {

  const {
    product,
    productId,
    refresh,
  } = useContext(ProductContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const productApi = useProductsApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if(name && productId) {
        await productApi.updateName(name, productId);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(product.name) {
      setValue('name', product.name);
    }
  }, [product.name]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.name}>
              <Input
                placeholder='Name'
                {...register('name', {
                  required: true,
                })}
              />
              <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                  <IconButton
                    variant="greyOutline"
                    icon={<MdClose />}
                    onClick={onCancel}
                  />
                  )
                }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='purpleOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default ProductNameForm;


