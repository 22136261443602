'use client'

import { Flex, Text } from '@chakra-ui/react';
import React, {useContext} from 'react';
import { PersonaContext } from '../../lib/personaContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import PersonaAttributesForm from './personaAttributesForm';


export default function PersonaAttributes(props) {

  const {
    persona,
    rights
  } = useContext(PersonaContext);

  return (
    <>
    {persona &&(
          <InlineFormWrapper
          width={"100%"}
          canEdit={rights.can_update}
          value={
            <Flex
              alignSelf={"stretch"}
              flexDirection={"column"}
              gap={1}
              w={"100%"}
            >
            {persona.age && (
              <Flex>
                <Text
                  w="100px"
                  flexShrink="0"
                  color="text.medium.grey"
                  >Age</Text>
                <Text>{persona.age}</Text>
              </Flex>
            )}
            {persona.job && (
              <Flex>
                <Text
                  w="100px"
                  flexShrink="0"
                  color="text.medium.grey"
                  >Job</Text>
                <Text>{persona.job}</Text>
              </Flex>
            )}
            {persona.education && (
              <Flex>
                <Text
                  w="100px"
                  flexShrink="0"
                  color="text.medium.grey"
                  >Education</Text>
                <Text>{persona.education}</Text>
              </Flex>
            )}
            {persona.location && (
              <Flex>
                <Text
                  w="100px"
                  color="text.medium.grey"
                  flexShrink="0"
                  >Location</Text>
                <Text>{persona.location}</Text>
              </Flex>
            )}
            {persona.family_status && (
              <Flex>
                <Text
                  w="100px"
                  color="text.medium.grey"
                  >Family Status</Text>
                <Text>{persona.family_status}</Text>
              </Flex>
            )}
            {persona.interests && (
              <Flex>
                <Text
                  w="100px"
                  flexShrink={"0"}
                  color="text.medium.grey"
                  >Interests</Text>
                <Text>{persona.interests}</Text>
              </Flex>
            )}
          </Flex>
          }
        >
          {(close) => (
            <PersonaAttributesForm
              onSubmitted={close}
              onCancel={close}
            />
          )}
        </InlineFormWrapper>
     )
    }
    </>
  )
}




