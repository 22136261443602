import { Icon } from "@chakra-ui/react";
/*
* Instruction for adding an icon :
* 1. Clean your svg to make it very light
* 2. Remove all the svg Tag and insert the children inside the Icon tag
* 3. swith all the hypen with a capital ex: fill-rule -> fillRule
* 4. Change fill colors by "currentColor" ex: fill="#000" -> fill="CurrentColor" (only if you don't need a custom color)
*/
const EditQuoteIcon = (props) => (
  <Icon viewBox="0 0 25 25" {...props} fill="currentColor" >
   <path fillRule="evenodd" clipRule="evenodd" d="m8.056 13.308 1.428-2.436a2.415 2.415 0 0 1-1.754-.716A2.323 2.323 0 0 1 7 8.436c0-.67.243-1.243.73-1.72A2.415 2.415 0 0 1 9.484 6c.683 0 1.267.239 1.754.716.486.477.73 1.05.73 1.72 0 .233-.03.449-.086.647a2.252 2.252 0 0 1-.256.57l-2.142 3.655H8.056Zm5.588 0 1.428-2.436a2.415 2.415 0 0 1-1.754-.716 2.323 2.323 0 0 1-.73-1.72c0-.67.243-1.243.73-1.72A2.415 2.415 0 0 1 15.072 6c.683 0 1.268.239 1.754.716s.73 1.05.73 1.72c0 .233-.029.449-.086.647a2.255 2.255 0 0 1-.256.57l-2.142 3.655h-1.428Z" fill="currentColor"/><path fillRule="evenodd" clipRule="evenodd" d="M2 4v18l4-4h8.027a4.537 4.537 0 0 1 .23-2H5.15L4 17.125V4h16v9.256c.792.28 1.482.775 2 1.415V4c0-.55-.196-1.02-.587-1.413A1.926 1.926 0 0 0 20 2H4c-.55 0-1.02.196-1.413.587A1.926 1.926 0 0 0 2 4Z" fill="currentColor"/><path d="M14.421 21.565h.632l3.728-3.734-.607-.62-3.753 3.758v.596ZM13 23v-2.64l6.024-6.044a.924.924 0 0 1 .35-.243 1.12 1.12 0 0 1 1.169.231l1.141 1.107a1.121 1.121 0 0 1 .237 1.199 1.028 1.028 0 0 1-.237.358L15.66 23H13Zm5.478-5.486-.304-.303.607.62-.303-.317Z" fill="currentColor"/>
  </Icon>
)
export default EditQuoteIcon;
