import React, { useContext, useEffect, useState } from 'react';
import usePersonaApi from './usePersonaApi';
import { PersonaContext } from './personaContext.js';
import { AuthContext } from '@/auth/lib/authContext';

export default function PersonaProvider(props) {

  let { personaId, originalPersona, children, onFetchingData, onDataFetched, onInit } = props;
  const { profile, hasProfile} = useContext(AuthContext);
  const [persona, setPersona] = useState();
  const [role, setRole] = useState();
  const [hasRequestedEdition, setHasRequestedEdition] = useState(false);
  const [rights, setRights] = useState({
    can_update: false,
    can_insert: false,
    can_delete: false
  });
  const personaApi = usePersonaApi();
  const [isInit, setIsInit] = useState(false);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [previouslyReadyToFetch, setPreviouslyReadyToFetch] = useState(false);

  const refresh = async () => {
    try {
      if(onFetchingData) {
        onFetchingData()
      }
      const promises = [
        personaApi.fetchPersona(personaId),
        personaApi.fetchMemberRole(personaId, profile.id),
        personaApi.hasRequestedEdition(personaId, profile.id),
        personaApi.fetchUserRights(personaId, profile.id)
      ];
      const promisesResult = await Promise.all(promises);
      setPersona(promisesResult[0]);
      setRole(promisesResult[1]);
      setHasRequestedEdition(promisesResult[2]);
      setRights(promisesResult[3]);
      if(onDataFetched) {
        onDataFetched()
      }
      if(!isInit && onInit) {
        onInit(true);
        setIsInit(true);
      }
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(isReadyToFetch && !previouslyReadyToFetch ) {
      setPreviouslyReadyToFetch(true)
      refresh()
      setIsReadyToFetch(false)
    }
  }, [isReadyToFetch]);

useEffect(() => {
    if(personaId && hasProfile) {
      setPreviouslyReadyToFetch(false)
      setIsReadyToFetch(true);
      setIsInit(false);
    }
  }, [personaId, hasProfile]);
  
  useEffect(() => {
    if (originalPersona && hasProfile) {
      setPersona(originalPersona);
    }
  }, [originalPersona, hasProfile]);

  return (
    <PersonaContext.Provider value={{
      role,
      persona,
      personaId,
      rights,
      refresh,
      hasRequestedEdition
    }} >
      {persona && (
        <>
          {children}
        </>
      )}
    </PersonaContext.Provider>
  );
}