import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Flex, Icon, IconButton, Skeleton, Text, useDisclosure, keyframes } from '@chakra-ui/react';
import { InsightContext } from '../lib/insightContext';
import useInsightApi from '../lib/useInsightApi';
import { MdAdd, MdClose, MdOutlineEdit, MdOutlineFullscreen, MdOutlineFullscreenExit } from 'react-icons/md';
import InsightQuoteList from './insightQuoteList';
import InsightImageModal from './insightImageModal';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import InsightAttributesTags from './insightAttributesTags';
import InsightType from './insightType';
import { ExperienceContext } from '../../experiences/lib/experienceContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import InsightNameForm from './insightNameForm';
import { SlideshowContext } from '../lib/slideshowContext';

export default function Slide(props) {

  const fullScreenHandle = useFullScreenHandle();
  const { disclosure } = props;

  const slideshowContext = useContext(SlideshowContext)

  const {
    insight,
    rights
  } = useContext(InsightContext);

  const {
    refreshInsights,
  } = useContext(ExperienceContext);

  const insightApi = useInsightApi();

  const [insightId, setInsightId] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [imageCount, setImageCount] = useState();
  const [images512, setImages512] = useState();
  const [images1024, setImages1024] = useState();
  const [imagesOriginal, setImagesOriginal] = useState();
  const [slideNumber, setSlideNumber] = useState(0);
  const [fetchingImages, setFetchingImages] = useState(false);
  const imagesModal = useDisclosure();
  const thumbnailsRef = useRef([]);
  const thumbnailsSlideRef = useRef();

  const fetchImages512 = async () => {
    return new Promise(async (resolve) => {
      try {
        const newimages512 = await insightApi.downloadImages(insight.id, 512);
        setImages512(newimages512);
        resolve(newimages512);
      } catch (error) {
        console.error(error)
      }
    })
  };

  const fetchImages1024 = async () => {
    return new Promise(async (resolve) => {
      const newimages1024 = await insightApi.downloadImages(insight.id, 1024);
      setImages1024(newimages1024);
      resolve(newimages1024);
      setFetchingImages(false)
    })
  };

  const fetchImagesOriginal = async () => {
    return new Promise(async (resolve) => {
      const newimagesOriginal = await insightApi.downloadImages(insight.id, "original");
      setImagesOriginal(newimagesOriginal);
      resolve(newimagesOriginal);
    })
  };

  const fetchimages = async () => {
    try {
      setFetchingImages(true);
      await Promise.all([
        fetchImages512(),
        fetchImages1024(),
        fetchImagesOriginal(),
      ])
    } catch (e) {
      console.error(e)
    }
  };


  const handleKey = useCallback(
    (e) => {
      if (images512) {
        if (e.key === 'ArrowRight') {
          if (slideNumber < images512.length - 1) {
            slideshowContext.setSkipKey(true);
            setSlideNumber(slideNumber + 1);
          }
        } else if (e.key === 'ArrowLeft') {
          if (slideNumber > 0) {
            slideshowContext.setSkipKey(true);
            setSlideNumber(slideNumber - 1);
          }
        }
      }
    },
    [images512, slideNumber]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);
  

  useEffect(() => {
    if (thumbnailsSlideRef.current && thumbnailsRef.current.length > 0) {
      const slideParentElement = thumbnailsSlideRef.current;
      const parentBox = slideParentElement.getBoundingClientRect()
      const slideElement = thumbnailsRef.current[slideNumber];
      const box = slideElement.getBoundingClientRect()
      const parentOverflow = parentBox.width + parentBox.left;
      const childOverflow = box.width + box.left;
      if (childOverflow > parentOverflow) {
        slideParentElement.scrollTo({
          top: 0,
          left: slideParentElement.scrollLeft + parentBox.width,
          behavior: "smooth",
        })
      } else if (childOverflow < parentBox.left) {
        slideParentElement.scrollTo({
          top: 0,
          left: slideParentElement.scrollLeft - parentBox.width,
          behavior: "smooth",
        })
      }
    }
  }, [slideNumber]);

  useEffect(() => {
    if (insight) {
      if(insight.id !== insightId || insight.image_count !== imageCount || insight.images_updated_at !== updatedAt) {
        setImages512(null);
        setImages1024(null);
        setImagesOriginal(null);
        fetchimages()
        setSlideNumber(0);
        setInsightId(insight.id);
        setImageCount(insight.image_count)
        setUpdatedAt(insight.images_updated_at)
      }
    }
  }, [insight]);

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

  return (
    <>
      {disclosure && (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
          <ModalOverlay />
          {insight && (
            <FullScreen handle={fullScreenHandle}>
              <ModalContent maxW={"calc(100% - 20px)"} maxH={"calc(100vh - 20px)"} h={"100%"} py={0} >
                <ModalHeader fontSize={"14px"} >
                  <Flex alignItems={"center"} gap={3}>
                    <Flex
                      flex="1"
                      alignItems={"center"}
                      gap={2}>
                      <InsightType onChange={() => {
                        refreshInsights();
                      }} />
                      <InlineFormWrapper
                        width="100%"
                        canEdit={rights.can_update}
                        value={
                          <Text
                            fontWeight={"400"}
                            fontSize={"16px"}
                          >{insight.name}</Text>
                        }
                      >
                        {(close) => (
                          <InsightNameForm
                            onSubmitted={() => {
                              close();
                              refreshInsights();
                            }}
                            onCancel={close}
                          />
                        )}
                      </InlineFormWrapper>
                      <InsightAttributesTags onChange={() => {
                        refreshInsights();
                      }} />
                    </Flex>
                    <Flex gap={2}>
                      {fullScreenHandle && !fullScreenHandle.active && <IconButton variant={"greyTransparent"} icon={<Icon as={MdOutlineFullscreen} boxSize={"24px"} />} onClick={fullScreenHandle.enter} />}
                      {fullScreenHandle && fullScreenHandle.active && <IconButton variant={"greyTransparent"} icon={<Icon as={MdOutlineFullscreenExit} boxSize={"24px"} />} onClick={fullScreenHandle.exit} />}
                      <IconButton variant={"greyTransparent"} icon={<Icon as={MdClose} boxSize={"22px"} />} onClick={() => {
                        fullScreenHandle.exit();
                        disclosure.onClose();
                      }} />
                    </Flex>
                  </Flex>
                </ModalHeader>
                <ModalBody >
                  <Flex w={"100%"} height={"100%"} >
                    {(!insight.image_count) && (
                      <Flex
                        flex="1"
                        alignItems={"center"}
                        justifyContent={"center"}
                        alignSelf={"stretch"}
                        flexDirection={"column"}
                        gap={3}
                        width={"300px"} >
                        <Text color="text.medium.grey">No Image so far</Text>
                        {rights.can_update && (
                          <Button
                            leftIcon={<Icon as={MdAdd} />}
                            variant="orangeOutline" onClick={() => {
                              disclosure.onClose();
                              imagesModal.onOpen();
                            }}>Add Image</Button>
                        )}
                      </Flex>
                    )}
                    {(insight.image_count !== 0 && fetchingImages) && (
                      <Skeleton w={"100%"} h="100%" />
                    )}
                    {(insight.image_count !== 0 && !fetchingImages) &&
                      <Flex
                        w={"400px"}
                        flex="1"
                        height={"100%"}
                        flexDirection={"column"}>
                        <Flex flex={1} height={"100%"} position={"relative"}
                        >
                          {(insight.image_count !== 0 && images1024) && images1024.map((img, i) =>
                            <Flex key={i}>
                              {(i === slideNumber) && (
                                <>
                                {(images1024 && images1024[i]) ? (
                                  <Flex
                                  w="100%"
                                  height={"100%"}
                                  bg="#dadada"
                                  position={"absolute"}
                                  animation={`${fadeIn} 0.3s ease-in-out`} 
                                >
                                  <img
                                    src={images1024[i]}
                                    alt="thumbnail"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit:
                                        'contain'
                                    }}
                                  />
                                </Flex>) : (<></>)}
                                {(imagesOriginal && imagesOriginal[i]) ? (
                                  <Flex
                                  w="100%"
                                  height={"100%"}
                                  bg="#dadada"
                                  position={"absolute"}
                                  animation={`${fadeIn} 0.3s ease-in-out`} 
                                >
                                  <img
                                    src={imagesOriginal[i]}
                                    alt="thumbnail"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit:
                                        'contain'
                                    }}
                                  />
                                </Flex>) : (<></>)}
                                </>
                              )}
                            </Flex>
                          )}
                        </Flex>
                        {(images512 && (rights.can_update && insight.image_count !== 0) || (!rights.can_update && insight.image_count > 1)) &&
                          <Flex
                            width={"100%"}
                            h="100px"
                            p={2}
                            overflow={"auto"}
                            ref={thumbnailsSlideRef}
                            background={"white"}
                            borderTop={"1px solid"}
                            borderColor={"divider.grey"}
                          >
                            {images512 && images512.map((image, i) =>
                              <Flex
                                w="150px"
                                height={"100%"}
                                bg="#dadada"
                                key={i}
                                borderRadius={"2px"}
                                mr={2}
                                cursor={"pointer"}
                                flexShrink={0}
                                ref={(el) => (thumbnailsRef.current[i] = el)}
                                onClick={() => {
                                  setSlideNumber(i);
                                }}
                                outline={i === slideNumber ? "3px solid" : '1px solid'}
                                outlineColor={i === slideNumber ? "divider.orange" : 'divider.grey'}
                              >
                                <Flex
                                  w="100%"
                                  height={"100%"}
                                  bg="#dadada"
                                >
                                  <img
                                    src={image}
                                    alt="thumbnail"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit:
                                        'contain'
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            )}
                            {rights.can_update &&
                              <IconButton
                                variant="orangeOutline"
                                icon={<Icon as={MdOutlineEdit} />}
                                onClick={() => {
                                  disclosure.onClose();
                                  imagesModal.onOpen();
                                }}
                              />
                            }
                          </Flex>
                        }
                      </Flex>
                    }
                    <Flex
                      width={"325px"}
                      height={"100%"}
                      overflow={"auto"}
                      flexDirection={"column"}
                      bg={"white"}
                      p={3}
                      borderLeft={"1px solid"}
                      borderColor={"divider.grey"}
                    >
                      <InsightQuoteList onChange={() => {
                        refreshInsights();
                      }} />
                    </Flex>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </FullScreen>
          )}
        </Modal>
      )}
      <InsightImageModal
        disclosure={imagesModal}
        onSaved={() => {
          slideshowContext.playFromInsight(insight.id);
          refreshInsights();
        }}
        onGoBack={() => {
          slideshowContext.playFromInsight(insight.id);
        }}
      />
    </>
  )
}

