'use client'

import React from 'react';
import { Text, Flex, UnorderedList, ListItem } from '@chakra-ui/layout';

export default function PrivacyPolicyPage() {
  return (
    <Flex
      p={3}
      overflow={"auto"}
    >
      <Flex
        flexDirection="column"
        p={3}
        backgroundColor={"background.white"}
        border="1px solid"
        borderColor="divider.grey"
        borderRadius={"2px"}
        h="fit-content"
      >
        <Text fontSize="22px">Bibliona Privacy Policy</Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>Effective Date: 01 January 2024</Text>
       
        <Text fontSize="18px" mt={3}>1. Introduction</Text>
        <Text>
          Bibliona ("we," "us," or "our") is committed to protecting the privacy of our website visitors ("you" or "your"). This Privacy Policy explains how we collect, use, and disclose information about you when you visit our website and how we handle your personal information.
        </Text>
        <Text fontSize="18px" mt={3} >2. Information We Collect</Text>
        <Text>
          We may collect the following types of information about you:
        </Text>
        <UnorderedList>
          <ListItem>Personal Information: This is information that can be used to identify you as an individual, such as your email address, username, and profile picture. We collect this information when you create an account or provide it to us through other means, such as a contact form.</ListItem>
          <ListItem>Non-Personal Information: This is information that does not identify you as an individual, such as your IP address, browser type, and device type. We may collect this information automatically when you visit our website.</ListItem>
        </UnorderedList>
        <Text fontSize="18px" mt={3}>3. How We Use Your Information</Text>
        <Text>
          We may use your information for the following purposes:
        </Text>
        <UnorderedList>
          <ListItem>To provide and improve our website and services.</ListItem>
          <ListItem>To communicate with you, including to respond to your inquiries and send you updates about our website and services.</ListItem>
          <ListItem>To personalize your experience on our website.</ListItem>
          <ListItem>To comply with legal and regulatory requirements.</ListItem>
        </UnorderedList>
        <Text fontSize="18px" mt={3}>4. How We Share Your Information</Text>
        <Text>
        We do not currently share your personal information with any third parties, except as required by law or to protect our rights and interests. However, we may use third-party service providers to perform services on our behalf, such as hosting, data analysis, and marketing. These service providers may have access to your personal information as necessary to perform their services for us, but they are not permitted to use it for their own purposes.
        </Text>
        <Text fontSize="18px" mt={3}>5. Your Choicess</Text>
        <Text>
          You have the following choices regarding your personal information:
        </Text>
        <UnorderedList>
          <ListItem>You may choose not to provide us with your personal information, but this may limit your ability to use certain features of our website.</ListItem>
          <ListItem>You may request access to, correction of, or deletion of your personal information by contacting us at support@bibliona.com.</ListItem>
        </UnorderedList>
        <Text fontSize="18px" mt={3}>6. Security</Text>
        <Text>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use Google SSO and password authentication to protect your account information. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee the absolute security of your personal information.
        </Text>
        <Text fontSize="18px" mt={3}>7. Cookies and Other Tracking Technologies</Text>
        <Text>
          We may use third-party libraries that store cookies on your device when you visit our website. These cookies may be used to collect information about your use of our website and to personalize your experience. You may be able to control or disable cookies through your browser settings, but this may limit your ability to use certain features of our website.
        </Text>
        <Text fontSize="18px" mt={3}>8. Changes to This Privacy Policy</Text>
        <Text>
          We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on our website prior to the effective date of the changes. We encourage you to periodically review this Privacy Policy for the latest information on our privacy practices.
        </Text>
        <Text fontSize="18px" mt={3}>9. Contact Us</Text>
        <Text>
          If you have any questions or concerns about this Privacy Policy, please contact us at support@bibliona.com.
        </Text>
      </Flex>
    </Flex>
  )
}
