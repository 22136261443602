'use client'

import React, { useContext, useState } from 'react';
import { Button, FormControl, FormErrorMessage, Input, Text } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/layout';
import { useForm } from 'react-hook-form';
import GoogleIcon from './googleIcon';
import { AuthContext } from '../lib/authContext';
import { Link } from 'react-router-dom';

export default function SignUpForm(props) {

  const { onSubmitted } = props;
  const authContext = useContext(AuthContext);
  const [globalError, setGlobalError] = useState(null);

  const signInWithGoogle = async () => {
    try {
      await authContext.signInWithGoogle();
    } catch (e) {
      setGlobalError(e.message);
      console.error(e);
    }
  };


  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, isValid, errors }
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data) => {
    try {
      await authContext.signUp(data.email, data.password);
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      setGlobalError(e.message);
      console.error(e);
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
      >
        <form onSubmit={handleSubmit(onSubmit)} >
          <FormControl isInvalid={errors?.email} mb={2}>
            <Input
              placeholder='Email'
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address"
                }
              })}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.password} mb={2}>
            <Input
              placeholder='Password'
              type="password"
              {...register('password', {
                required: true,
                minLength: { value: 6, message: 'Password should have at least 6 characters' }
              })}
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.confirmPassword} mb={3}>
            <Input
              placeholder='Confirm Password'
              type="password"
              {...register('confirmPassword', {
                required: true,
                minLength: { value: 6, message: 'Password confirmation should have at least 6 characters' },
                validate: (val) => {
                  if (watch('password') != val) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
            <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
          </FormControl>
          {globalError && (
            <Box mb={3}>
              <Text color={'text.medium.red'}>{globalError}</Text>
            </Box>
          )}
          <Text w="250px" fontSize="11px">
            By clicking on "Create account" or "Continue with Google", you agree to the Bibliona <Link to="/legal/terms-of-use" target="_blank"><Text as="span" color="text.medium.orange">Terms of Use</Text></Link> and <Link to="/legal/privacy-policy" target="_blank"><Text as="span" color="text.medium.orange">Privacy Policy</Text></Link>.
            We use cookies to enhance your experience on our site.
          </Text>
          <Button
            mt={3}
            w="100%"
            variant={"orangeFill"}
            type={'submit'}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >Create Account</Button>
        </form>
        <Flex
          mt={3}
          pt={3}
          flex={1}
          flexDirection={"column"}
          borderTop="1px solid"
          borderColor="divider.grey"
          alignItems={"center"}
        >
          <Button
                onClick={signInWithGoogle}
                variant="greyOutline"
                leftIcon={<GoogleIcon />} >
                Sign Up with Google
          </Button>
          <Link to="/auth/sign-in">
              <Button
                variant="orangeTransparent"
                mt={3}
                textAlign={'center'}
              >
             Go Back to Sign In
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  );
}
