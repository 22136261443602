export const menu = {
  baseStyle: {
    item: {
      _hover: {
        bg: 'background.grey',
      },
      _focus: {
        bg: 'background.grey',
      },
    },
  }
};