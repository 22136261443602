'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Flex} from '@chakra-ui/layout';
import {Button, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdAdd, MdCheck, MdClose, MdDelete} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useProductsApi from '../../lib/useProductsApi';
import { ProductContext } from '../../lib/productContext';

const ProductAsideMainFeaturesForm = forwardRef((props, ref) => {

  const {
    product,
    productId,
    refresh,
  } = useContext(ProductContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features',
  });

  const productApi = useProductsApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      if(product.id && data.features) {
        await productApi.updateFeatures(
          data.features,
          product.id
        )
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(() => {
    if(product.features) {
      setValue('features', product.features);
    }
  }, [product]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex alignSelf={"stretch"}  w={"100%"}>
        <form style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'stretch'}
            flexDirection={'column'}
            gap={2}
          >
            {fields.map((item, i) => (
              <Flex
                key={i}
                gap={2}
                alignItems={"center"}
                >
                <Input
                placeholder='Feature…'
                {...register(`features[${i}]`)}
                defaultValue={item}
              />
              <IconButton
              variant="redOutline"
              icon={<MdDelete />}
              onClick={() => {remove(i)}}
              mr={1}
              />
              </Flex>
            ))}
            <Button
              type="button"
              alignSelf={"flex-start"}
              variant="purpleTransparent"
              leftIcon={<MdAdd/>}
              onClick={() => append('')}
            >Add Feature</Button>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
              )}
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='purpleOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default ProductAsideMainFeaturesForm;


