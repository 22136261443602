'use client'

import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useExperienceApi from '@/experiences/lib/useExperienceApi';
import { PersonaPageContext } from '../lib/personaPageContext';

const ExperienceGroupInPersonaNameForm = forwardRef((props, ref) => {

  const {
    refreshExperienceList,
    refreshProductList,
  } = useContext(PersonaPageContext);

  const {
    groupId,
    group,
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const name = data.name;
      if(name && groupId) {
        await experienceApi.updateExperienceGroupNameInPersona(name, groupId);
        await Promise.all[
          refreshExperienceList(),
          refreshProductList()
        ]
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(group.name) {
      setValue('name', group.name);
    }
  }, [group]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.name}>
              <Input
                placeholder='Name'
                {...register('name', {
                  required: true,
                })}
              />
              <FormErrorMessage>{errors && errors.name}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                  <IconButton
                    variant="greyOutline"
                    icon={<MdClose />}
                    onClick={onCancel}
                  />
                  )
                }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='blueOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default ExperienceGroupInPersonaNameForm;


