'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, FormLabel, Input, Skeleton, Switch, Textarea } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/button';
import { MdCheck, MdClose } from 'react-icons/md';
import { forwardRef } from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';
import { ExperienceContext } from '@/experiences/lib/experienceContext';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import { Select } from 'chakra-react-select';

const InsightPersonaListForm = forwardRef((props, ref) => {

  const {
    experienceId,
  } = useContext(ExperienceContext);

  const {
    insight,
    refresh
  } = useContext(InsightContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel,
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    formState: { isSubmitting, isValid }
  } = useForm();

  const insightApi = useInsightApi();
  const personaApi = usePersonaApi();
  const [isPersonaChecked, setIsPersonaChecked] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [personaOptionsList, setPersonaOptionsList] = useState(null);

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      if (!data.applies_to_all_persona) {
        const personaIds = data.persona.map(p => p.value);
        await insightApi.updateApplicableToAllPersona(
          insight.id,
          false,
          personaIds
        )
      } else {
        await insightApi.updateApplicableToAllPersona(
          insight.id,
          true
        )
      }
      refresh();
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const init = async () => {
    setIsPersonaChecked(insight.applicable_to_all_persona)
    setValue('applies_to_all_persona', insight.applicable_to_all_persona)
    if (!insight.applicable_to_all_persona) {
      const insightPersonaList = await personaApi.fetchManyPersonaByInsightId(insight.id);
      const personaListValue = insightPersonaList.map((p) => {
        return { label: p.name, value: p.id }
      });
      setValue('persona', personaListValue)
    }
    let newPersonaList = await personaApi.fetchManyPersonaByExperienceId(experienceId);
    newPersonaList = newPersonaList.map((p) => {
      return { label: p.name, value: p.id }
    });
    setPersonaOptionsList(newPersonaList);
    setIsInit(true);
  };

  useEffect(() => {
    if (experienceId && insight && !isInit) {
      init();
    }
  }, []);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
     {!isInit && (
      <Skeleton height={"50px"} />
     )}
      {isInit && (
        <Flex
          flex="1"
          w="100%"
        >
          <form
            display="flex"
            style={{
              width: "100%"
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex
              alignItems={'center'}
              w="100%"
              flexDirection={'column'}
              gap={2}
            >
              <FormControl
                display='flex'
                alignItems='center'
                mt={3}
                mb={3}>
                <Switch
                  {...register('applies_to_all_persona', {
                    onChange: (e) => {
                      const value = getValues().applies_to_all_persona;
                      setIsPersonaChecked(value);
                    },
                  })}
                />
                <FormLabel ml={2} mb="0">
                  Applies to all persona of this experience
                </FormLabel>
                <FormErrorMessage>{errors && errors.applies_to_all_persona}</FormErrorMessage>
              </FormControl>
              {(!isPersonaChecked) && (
                <Controller
                  control={control}
                  name="persona"
                  rules={{ required: "At least one item must be selected" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, error }
                  }) => (
                    <FormControl isInvalid={errors && errors.persona} mb={3}>
                      <FormLabel>Involved Persona</FormLabel>
                      <Select
                        isMulti
                        options={personaOptionsList}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                      <FormErrorMessage>{errors && errors.persona}</FormErrorMessage>
                    </FormControl>
                  )}
                />
              )}
              <Flex gap={2}>
                {onCancel && (
                  <IconButton
                    variant="greyOutline"
                    icon={<MdClose />}
                    onClick={onCancel}
                  />
                )
                }
                <IconButton
                  style={{
                    display: hideSave ? 'none' : 'inherit',
                  }}
                  variant="orangeOutline"
                  ref={ref}
                  icon={<MdCheck />}
                  type={'submit'}
                  aria-label='check'
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                />
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
});

export default InsightPersonaListForm;


