import React, {useContext, useState} from 'react';
import {Button, Heading, Text, Flex, Box} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdClose} from 'react-icons/md';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import {Icon} from '@chakra-ui/icon';
import {MdOutlineWarning} from 'react-icons/md';
import ProductLogo from '../../../products/components/productLogo';
import useExperienceApi from '../../lib/useExperienceApi';
import { ExperienceContext } from '../../lib/experienceContext';
import { useNavigate } from 'react-router-dom';
import { ProductContext } from '@/products/lib/productContext';

export default function ProductInExperienceListItem(props) {

  const experienceContext = useContext(ExperienceContext);
  const productContext = useContext(ProductContext);

  const {
    product,
    onRemove
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const experienceApi = useExperienceApi();
  const [numberOfPersonaAndProduct, setNumberOfPersonaAndProduct] = useState();
  const modalDelete = useDisclosure();
  const navigate = useNavigate();

  const onTryToDeleteProductExperience = async () => {
    try {
      const restultNumberOfPersonaAndProduct = await experienceApi.fetchNumberOfPersonaAndProduct(experienceContext.experienceId);
      setNumberOfPersonaAndProduct(restultNumberOfPersonaAndProduct);
      modalDelete.onOpen();
    } catch (e) {
      console.error(e);
    }
  };

  async function deleteProductsExperience() {
    try {
      setIsDeleting(true);
      await experienceApi.unlinkProductFromExperience(experienceContext.experienceId, product.id);
      setIsDeleting(false);
      modalDelete.onClose();
      if(onRemove) {
        onRemove();
      }
      if(numberOfPersonaAndProduct === 1) {
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="white"
        role={'group'}
        flex={1}
        mb={1}
        h={"34px"}
        px={3}
        _hover={{
          backgroundColor: 'background.grey',
          borderRadius: '2px'
        }}
      >
        <Flex alignItems="center">
          <ProductLogo
            size={24}
          />
        <Text ml={2} fontSize={'12px'}>{product.name}</Text>
        </Flex>
          { (productContext.rights.can_update && experienceContext.rights.can_update) && (
          <Box style={{opacity:'0'}} _groupHover={{ opacity: '1 !important' }} >
            <IconButton
            icon={<MdClose />}
            size={'md'}
            aria-label='Delete'
            variant='greyTransparent'
            onClick={(e) => {
              onTryToDeleteProductExperience();
              e.stopPropagation();
              e.preventDefault();
            }}
            />
          </Box>
           )}
      </Flex>
      <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Remove Product {product.name}</ModalHeader>
          <ModalBody p={3} >
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
            >
              <Icon
                as={MdOutlineWarning}
                color={'text.medium.red'}
                boxSize={'18px'}
                mr={3}
              />
              <Text textStyle="body" color={'text.medium.red'} >Are you sure to remove the product {product.name} from this experience?</Text>
            </Flex>
            {numberOfPersonaAndProduct === 1 && (
              <Flex
                border="1px"
                borderColor="divider.grey"
                borderRadius="2px"
                backgroundColor="white"
                alignItems={'center'}
                p={3}
                mt={2}
              >
                <Text textStyle="body" color={'text.medium.red'} >
                The experience will be deleted because it will be not link anymore to any product or persona.</Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={modalDelete.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isDeleting}
              onClick={deleteProductsExperience}>Remove</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
  );
}
