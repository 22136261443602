import React from 'react';
import {Spinner} from '@chakra-ui/spinner';
import { Flex } from '@chakra-ui/react';
import Logo from '../../components/logo';

export default function AppLoading() {
  return (
    <>
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection={'column'}
        gap={3}
        >
        <Logo size={40} />
        <Spinner mt={2} />
      </Flex>
    </>
  );
}
