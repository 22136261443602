'use client'

import React, {useEffect, useState} from 'react';
import useProfileApi from './useProfileApi';
import { ProfileContext } from './profileContext';

export default function ProfileProvider(props) {

  let { profileId, originalProfile, children } = props;
  const [profile, setProfile] = useState();
  const [email, setEmail] = useState();

  const profileApi = useProfileApi();

  const refresh = async () => {
    try {
     const newProfile = await profileApi.fetchProfile(profileId)
     setProfile(newProfile);
     const newEmail = await profileApi.fetchEmailByProfileId(profileId)
     setEmail(newEmail);
    } catch (error) {
      console.error(error)
    }
  }
  
  useEffect(() => {
    if (profileId) {
      refresh();
    }
  }, [profileId]);

  useEffect(() => {
    if (originalProfile) {
      setProfile(originalProfile);
    }
  }, [originalProfile]);

  return (
    <ProfileContext.Provider value={{
      profile,
      profileId,
      refresh,
      email
    }} >
      {profile && (
        <>
          {children}
        </>
      )}
    </ProfileContext.Provider>
  );
}




