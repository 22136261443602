'use client'

import React, {createContext} from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './theme/chakra.theme';
import MenuProvider from './menuProvider';

const ConfigContext = createContext({});

export default function ConfigProvider({children}) {

  return (
    <ConfigContext.Provider value={''}>
      <MenuProvider>
        <ChakraProvider theme={theme}>
          {children}
        </ChakraProvider>
      </MenuProvider>
    </ConfigContext.Provider>
  );
}




