'use client';

import React, { useContext, useEffect } from 'react';
import SignInForm from '../components/signInForm';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo';
import { AuthContext } from '../lib/authContext';

export default function SignInPage() {

  const {isAuthenticated} = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <>
      <Logo />
      <Box w={250} mt={3} >
        <SignInForm />
      </Box>
    </>
  )
}
