'use client'

import React, { useContext, useEffect, useState } from 'react';
import {Button, FormControl, FormErrorMessage, Input, Text} from '@chakra-ui/react';
import {Box} from '@chakra-ui/layout';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../lib/authContext';

export default function ResetPasswordForm(props) {
  
  const {email}  = props
  const authContext = useContext(AuthContext);
  const [globalError, setGlobalError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [finalEmail, setFinalEmail] = useState(null);

   const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { isSubmitting, isValid, errors }
  } = useForm({ mode: 'onBlur'});

  const onSubmit = async (data) => {
    try {
      const redirectTo =  window.location.origin + '/auth/update-password'
      await authContext.resetPasswordForEmail(data.email, redirectTo);
      setFinalEmail(data.email);
      setIsSent(true);
    } catch (e) {
      setGlobalError(e.message);
      console.error(e);
    }
  };

  useEffect(() => {
    if(email) {
      setValue('email', email);
      trigger('email');
    }
  }, [email]);

  return (
    <>
      <Box>
        {!isSent && (
            <form onSubmit={handleSubmit(onSubmit)} >
            <FormControl isInvalid={errors.email} mb={2}>
                <Input
                  placeholder='Email'
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    }
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              {globalError && (
              <Box mb={3}>
                <Text color={'text.medium.red'}>{globalError}</Text>
              </Box>
            )}
            <Box>
              <Button
                type={'submit'}
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >Send</Button>
            </Box>
          </form>
        )}
      {isSent && (
        <Box>
          <Text >A link has been sent to {finalEmail}!</Text>
        </Box>
      )}
      </Box>
    </>
  );
}
