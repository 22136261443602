'use client'

import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ExperienceProvider from '../lib/experienceProvider';
import ExperienceAside from './components/experienceAside';
import InsightList from '@/insight/components/insightList';
import ExperiencePageProvider from './lib/experiencePageProvider';
import { useParams } from 'react-router-dom';
import SlideshowProvider from '../../insight/lib/slideshowProvider';

export default function ExperiencePage() {

  const params = useParams();

  return (
    <>
      {params.id && (
        <ExperienceProvider
          experienceId={params.id}
          needPersonasAndProducts={true}
          needInsights={true}
        >
          <ExperiencePageProvider>
            <SlideshowProvider>
              <Flex
                flexDirection="row"
                w="100%"
                h="100%"
              >
                <Box
                  flexShrink="0"
                  w="275px"
                  h="100%"
                  overflow="auto"
                  backgroundColor={"white"}
                >
                  <ExperienceAside />
                </Box>
                <Flex
                  flex="1"
                  width={"calc(100% - 275px)"}
                  flexDirection={"column"}
                  height="100%"
                >
                  <InsightList />
                </Flex>
              </Flex>
            </SlideshowProvider>
          </ExperiencePageProvider>
        </ExperienceProvider>
      )}
    </>
  )
}




