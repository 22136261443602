'use client'

import React from 'react';
import { Text, Flex } from '@chakra-ui/layout';

export default function TermsOfUsePage() {
  return (
    <Flex
      p={3}
      overflow={"auto"}
    >
      <Flex
        flexDirection="column"
        p={3}
        backgroundColor={"background.white"}
        border="1px solid"
        borderColor="divider.grey"
        borderRadius={"2px"}
        h="fit-content"
      >
        <Text fontSize="22px">Bibliona Terms of Use</Text>
        <Text fontSize="18px" mt={3}>1. Acceptance of Terms</Text>
        <Text>
          By accessing and using this website, you agree to comply with and be bound by these Terms of Use. If you do not agree with these terms, please do not use this site.
        </Text>
        <Text fontSize="18px" mt={3} >2. Changes to Terms</Text>
        <Text>
          We reserve the right to modify these terms at any time. Changes will take effect immediately upon being posted on the site. It is your responsibility to review the terms regularly to stay informed of any updates.
        </Text>
        <Text fontSize="18px" mt={3}>3. Use of the Site</Text>
        <Text>
          You agree to use this site only for lawful purposes and in accordance with these terms. You agree not to use the site in any way that could damage, disable, overburden, or impair the site, or interfere with any other party's use and enjoyment of the site.
        </Text>
        <Text fontSize="18px" mt={3}>4. Site Content</Text>
        <Text>
          The content on this site, including but not limited to text, graphics, images, and other materials, is the property of bibiliona and is protected by intellectual property laws. You may not reproduce, distribute, modify, display, perform, or otherwise use the content without our prior written permission.
        </Text>
        <Text fontSize="18px" mt={3}>5. User Accounts</Text>
        <Text>
          To access certain features of our site, you may be required to create a user account via Google Authentication or by using a password and username. You agree to provide accurate, current, and complete information during registration and to update such information promptly if there are any changes. You are responsible for maintaining the confidentiality of your password and for all activities that occur under your account.
        </Text>
        <Text fontSize="18px" mt={3}>6. Data Collection and Use</Text>
        <Text>
          We collect the following information during your registration: username, email, and profile picture. Currently, we do not use any collected data for specific purposes. We are committed to protecting your privacy and handling your personal information in accordance with our privacy policy.
        </Text>
        <Text fontSize="18px" mt={3}>7. Security</Text>
        <Text>
          We implement security measures to protect your personal information, including a database and an API secured by a password. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        </Text>
        <Text fontSize="18px" mt={3}>8. Limitation of Liability</Text>
        <Text>
          To the extent permitted by law, bibiliona will not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses resulting from (i) the use or inability to use the site; (ii) unauthorized access to or alteration of your transmissions or data; or (iii) any other matter relating to the site.
        </Text>
        <Text fontSize="18px" mt={3}>9. Indemnification</Text>
        <Text>
          You agree to indemnify and hold harmless bibiliona, its affiliates, officers, employees, agents, and representatives from any claims, losses, liabilities, damages, costs, and expenses, including attorneys' fees, arising out of your use of the site or your violation of these terms.
        </Text>
        <Text fontSize="18px"  mt={3}>10. Governing Law</Text>
        <Text>
          These terms are governed by and construed in accordance with the laws of France. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the French courts.
        </Text>
        <Text fontSize="18px"  mt={3}>11. Pricing and Payment</Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>11.1 Pricing Plans</Text>
        <Text>
          Bibiliona offers various pricing plans for the use of its services, including a basic free plan. The details of the available plans, including features and prices, are available on our pricing page.
        </Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>11.2 Changes to Pricing</Text>
        <Text>
          We reserve the right to modify the prices of our services at any time. Any changes to pricing will be communicated to users via email or a notification on the site, at least 30 days before the new pricing takes effect.
        </Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>11.3 Payments</Text>
        <Text>
          All payments for paid services must be made through the payment methods specified on our site. You agree to provide current, complete, and accurate payment information and to update such information promptly if there are any changes.
        </Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>11.4 Renewal and Cancellation</Text>
        <Text>
          Paid subscriptions may be automatically renewed at the end of each subscription period. You may cancel your subscription at any time through your account settings. Upon cancellation, you will have access to the services until the end of the current subscription period. No refunds will be issued for partially used subscription periods.
        </Text>
        <Text fontSize="16px" color="text.medium.grey" mt={1}>11.5 Basic Free Plan</Text>
        <Text>
          Bibiliona offers a basic free plan with limited features. Users can upgrade to a paid plan at any time to access additional features. The details of the basic free plan's features are available on our pricing page.
        </Text>
        <Text fontSize="18px" mt={3}>12. Contact</Text>
        <Text>
          If you have any questions about these Terms of Use, please contact us at support@bibiliona.com.
        </Text>
      </Flex>
    </Flex>
  )
}
