import { useState, useEffect } from 'react';
import pica from 'pica';

export default async  function resizeImageWithRatio(blobImage, targetWidth, options = {croppedAreaPixels: null, returnsUrl: false}) {
  try {
    const picaInstance = pica();
    const sourceCanvas = document.createElement('canvas');
    const sourceCtx = sourceCanvas.getContext('2d');

    const targetCanvas = document.createElement('canvas');
    const img = document.createElement('img');
    img.src = blobImage;
    return new Promise((resolve, reject) => {
      img.onload = () => {

        sourceCanvas.width = img.naturalWidth;
        sourceCanvas.height = img.naturalHeight;
        sourceCtx.drawImage(img, 0, 0);
        
        if(!targetWidth) {
          targetWidth = img.naturalWidth;
          if(options.croppedAreaPixels && options.croppedAreaPixels.width) {
            targetWidth = options.croppedAreaPixels.width;
          }
        }

        const finalCrop = options.croppedAreaPixels || {
          width: img.naturalWidth,
          height: img.naturalHeight,
          x: 0,
          y: 0
        };

      const cropCanvas = document.createElement('canvas');
      cropCanvas.width = finalCrop.width;
      cropCanvas.height = finalCrop.height;
      const cropCtx = cropCanvas.getContext('2d');

      cropCtx.drawImage(
        sourceCanvas,
        finalCrop.x,
        finalCrop.y,
        finalCrop.width,
        finalCrop.height,
        0,
        0,
        finalCrop.width,
        finalCrop.height
      );

        const ratio = targetWidth / finalCrop.width;
        targetCanvas.width = finalCrop.width * ratio;
        targetCanvas.height = finalCrop.height * ratio;
        
        picaInstance.resize(cropCanvas, targetCanvas, {
          quality: 3,
          alpha: true,
        }).then(() => {
          const dataURL = targetCanvas.toDataURL('image/png', 9);
          if(options.returnsUrl) {
            fetch(dataURL)
            .then(res => res.blob())
            .then(blob => {
              const urlCreator = window.URL || window.webkitURL;
              const url =  urlCreator.createObjectURL(blob)
              resolve(blob)
            })
          } else {
            fetch(dataURL)
            .then(res => res.blob())
            .then(blob => resolve(blob))
          }
        }).catch((error) => {
          console.error(error)
        });
      }
    });
  } catch (error) {
    console.error(error);
  }
}
