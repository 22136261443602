'use client'

import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useProfileApi from '../lib/useProfileApi';

const ProfileEmailForm = forwardRef((props, ref) => {

  const {
    profileEmail,
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const profileApi = useProfileApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const email = data.email;
      if(email) {
        await profileApi.updateEmail(email);
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(profileEmail) {
      setValue('email', profileEmail);
    }
  }, [profileEmail]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'center'}
          >
            <FormControl isInvalid={errors && errors.email}>
              <Input
                placeholder='Email'
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
              />

              <FormErrorMessage>{errors && errors.email}</FormErrorMessage>
            </FormControl>
            <IconButton
              style={{
                display: hideSave ? 'none' : 'inherit',
              }}
              ref={ref}
              icon={<MdCheck />}
              ml={2}
              type={'submit'}
              aria-label='check'
              variant='greyFill'
              isLoading={isSubmitting}
              isDisabled={!isValid}
            />
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default ProfileEmailForm;


