export const colors = {
  black: '#000',
  white: '#FFF',
  fill: {
    orange: '#FA7C4E',
    blue: '#237CBC',
    purple: '#4D55B9',
    green: '#639e28',
    yellow: '#d3ad23',
    red: '#E02828',
    grey: '#696969',
  }, 
  divider: {
    orange: '#FFDBCE',
    blue: '#CAEFFF',
    purple: '#D5DFFF',
    green: '#DDEECC',
    grey: '#F1F1F1',
    yellow: '#FFEBA3',
    red: '#F5DADA',
  },
  background: {
    orange: '#FFF7F4',
    blue: '#EFF8FF',
    purple: '#EAEFFF',
    grey: '#FBFBFB',
    green: '#eafad5',
    red: '#fff0f0',
    yellow: '#fff4cb',
    white: '#FFF',
    component: {
      grey: '#F2F2F2',
    }
  },
  text: {
    medium:  {
      grey: '#696969',
      red: '#E02828',
      yellow: '#d3ad23',
      orange: '#FA7C4E',
      blue: '#237CBC',
      purple: '#4D55B9',
      green: '#639e28'
    },
    dark:  {
      grey: '#333333',
    }, 
    light: {
      grey: '#BFBDBD',
    },
    disable: {
      grey: '#F2F2F2',
      orange: '#FFF1EC'
    }
  },
};