'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import ProfileThumbnail from '@/profile/component/profileThumbnail';
import { MdArrowDropDown } from 'react-icons/md';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Button, Tag, TagLabel } from '@chakra-ui/react';
import roleDictionnary from '@/lib/roleDictionnary';
import usePersonaApi from '../../lib/usePersonaApi';
import memberStatusDictionnary from '@/lib/memberStatusDictionnary';
import { PersonaContext } from '../../lib/personaContext';
import { ProfileContext } from '@/profile/lib/profileContext';
import { AuthContext } from '@/auth/lib/authContext';
import useProfileApi from '@/profile/lib/useProfileApi';
import { useNavigate } from 'react-router-dom';

export default function PersonaMemberListItem(props) {

  const {
    personaId,
  } = useContext(PersonaContext);

  const { profile } = useContext(ProfileContext)
  const authContext = useContext(AuthContext)

  const {
    onRevoke,
    onRoleUpdated
  } = props;

  const navigate = useNavigate();
  const personaApi = usePersonaApi();
  const profileApi = useProfileApi();
  const [itemRole, setItemRole] = useState()
  const [approving, setApproving] = useState(false)
  const [role, setRole] = useState()
  const [hasRequestedEdition, setHasRequestedEdition] = useState()
  const [status, setStatus] = useState()
  const [email, setEmail] = useState()

  const fetchData = async () => {
    try {
      const newItemRole = await personaApi.fetchMemberRole(
        personaId,
        profile.id
      );
      setItemRole(newItemRole);
      const newStatus = await personaApi.fetchMemberStatus(personaId, profile.id);
      setStatus(newStatus);
      const newHasRequestedEdition = await personaApi.hasRequestedEdition(personaId, profile.id);
      setHasRequestedEdition(newHasRequestedEdition);
      const newRole = await personaApi.fetchMemberRole(personaId, authContext.profile.id);
      setRole(newRole);
      const email = await profileApi.fetchEmailByProfileId(profile.id);
      setEmail(email);
    } catch (e) {
      console.error(e)
    }
  };

  const updateRole = async (role) => {
    try {
      await personaApi.updateRole(personaId, profile.id, role);
      if (onRoleUpdated) {
        onRoleUpdated();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const revokeAccess = async () => {
    try {
      await personaApi.revokeAccess(personaId, profile.id);
      if (onRevoke) {
        onRevoke();
      }
    } catch (error) {
      console.error(error)
    }
  }

  const leave = async () => {
    try {
      await personaApi.revokeAccess(personaId, authContext.profile.id);
      navigate('/persona')
    } catch (error) {
      console.error(error)
    }
  }

  const approveRequest = async () => {
    try {
      setApproving(true)
      await personaApi.approveRequest(personaId, profile.id);
      setApproving(false)
      if (onRoleUpdated) {
        onRoleUpdated();
      }
    } catch (error) {
      console.error(error)
    }
  }

  const declineRequest = async () => {
    try {
      await personaApi.declineRequest(personaId, profile.id);
      if (onRoleUpdated) {
        onRoleUpdated();
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (profile && personaId && authContext.profile) {
      fetchData();
    }
  }, [profile, personaId, authContext.profile]);

  return (
    <>
      {profile && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={3}
          backgroundColor="white"
        >
          <Flex alignItems={'center'}>
            <ProfileThumbnail size={40} />
            <Flex ml={2} flexDirection={"column"}>
              <Text>{profile.pseudo} {authContext.profile.id === profile.id ? '(Me)' : ''} </Text>
              {email && <Text fontSize={"11px"} color="text.medium.grey">{email}</Text>}
            </Flex>
          </Flex>
          <Flex flexDirection={"column"} alignItems={"flex-end"}>
            {status === memberStatusDictionnary.approved && (
              <>
                {((itemRole && itemRole !== roleDictionnary.owner)
                  && (role && role === roleDictionnary.owner)) && (
                    <Menu ml={2} >
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            isActive={isOpen}
                            as={Button}
                            rightIcon={<MdArrowDropDown />}
                            variant='transparent'
                            p={0}
                            width={"fit-content"}
                            textTransform={'capitalize'}
                          >{itemRole}</MenuButton>
                          <MenuList>
                            {hasRequestedEdition &&
                              (<>
                                <MenuItem onClick={approveRequest} >
                                  <Text color='text.medium.green' >Approve Edition Request</Text>
                                </MenuItem>
                                <MenuItem onClick={declineRequest} >
                                  <Text color='text.medium.red' >Decline Edition Request</Text>
                                </MenuItem>
                              </>
                              )}
                            {(itemRole && itemRole !== roleDictionnary.contributor) && <MenuItem onClick={() => { updateRole(roleDictionnary.contributor) }} >Contributor</MenuItem>}
                            {(itemRole && itemRole !== roleDictionnary.viewer) && <MenuItem onClick={() => { updateRole(roleDictionnary.viewer) }}>Viewer</MenuItem>}
                            <MenuItem onClick={revokeAccess} >
                              <Text color='text.medium.red' >Revoke Access</Text>
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  )}
                {((
                  itemRole && itemRole === roleDictionnary.owner)
                  || (role && role !== roleDictionnary.owner)
                  || !role)
                  && (
                    <>
                      {(profile.id !== authContext.profile.id
                        || (itemRole && itemRole === roleDictionnary.owner))
                        &&
                        <Text
                          textTransform={'capitalize'}
                          color="text.tertiaryGrey"
                        >{itemRole}</Text>
                      }
                      {(profile.id === authContext.profile.id
                        && itemRole && itemRole !== roleDictionnary.owner)
                        &&
                        <Menu ml={2} >
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                isActive={isOpen}
                                as={Button}
                                rightIcon={<MdArrowDropDown />}
                                variant='transparent'
                                p={0}
                                width={"fit-content"}
                                textTransform={'capitalize'}
                              >{itemRole}</MenuButton>
                              <MenuList>
                                <MenuItem onClick={leave}>
                                  <Text color='text.medium.red' >Leave</Text>
                                </MenuItem>
                              </MenuList>
                            </>
                          )}
                        </Menu>
                      }
                    </>
                  )}
              </>
            )}
            {hasRequestedEdition && (
              <Tag variant="yellow" ml={2} mt={1}>
                <TagLabel>Request Edition</TagLabel>
              </Tag>
            )}
            {(role !== roleDictionnary.owner && status === memberStatusDictionnary.requested) && (
              <>
                <Tag variant="yellow">
                  <TagLabel>Requested</TagLabel>
                </Tag>
              </>
            )}
            {(role === roleDictionnary.owner && status === memberStatusDictionnary.requested) && (
              <Box>
                <Button variant="primarySuccess"
                  onClick={approveRequest}
                  isLoading={approving}>Approve</Button>
                <Button ml={1} variant="redFill" onClick={revokeAccess}>Decline</Button>
              </Box>
            )}
          </Flex>
        </Flex>
      )}
    </>
  )
}




