'use client'

import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Flex} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/react'
import {MdEdit} from 'react-icons/md';
import {Icon} from '@chakra-ui/icon';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import ProductLogo from '../../components/productLogo';
import ProductLogosForm from './productLogosForm';
import { ProductContext } from '../../lib/productContext';

export default function ProductAsideLogo(props) {

  const {
    product,
    rights,
    refresh
  } = useContext(ProductContext);
  
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalEditLogo = useDisclosure();
  const ref = useRef(null);

  return (
    <>
    <Flex>
        {product && (
        <Box
        position={'relative'}
        w={ (product.has_logo && product.has_logo_with_text) ? '180px' : 'auto'}
        h={100}
        flexShrink={0}
        cursor={rights.can_update ? 'pointer' : 'default'}
        onClick={() => {
          if(rights.can_update) {
            modalEditLogo.onOpen();
          }
        }}
        >
        { (product.has_logo || !product.has_logo_with_text) && (
            <ProductLogo
              size={100}
              productId={product.id}
              withText={false}
            />
        )}
        {(product.has_logo_with_text && product.has_logo) && (
            <Box
              position={'absolute'}
              bottom={'0'}
              right={'0'}
            >
            <ProductLogo
                size={100}
                productId={product.id}
                withText={true}
            />
            </Box>
        )}
            {(product.has_logo_with_text && !product.has_logo)  && (
            <ProductLogo
                size={100}
                productId={product.id}
                withText={true}
            />
        )}
        { (rights.can_update) && (
        <Flex
            border="1px"
            borderColor="divider.grey"
            borderRadius="50%"
            backgroundColor="white"
            alignItems="center"
            justifyContent="center"
            w={30}
            h={30}
            style={{'cursor' : 'pointer'}}
            position={'absolute'}
            bottom={'0'}
            right={'0'}
            onClick={modalEditLogo.onOpen}
        >
            <Icon
            as={MdEdit}
            color={'text.secondaryGrey'}
            boxSize={'15px'}
            />
        </Flex>
        )}
        </Box>
        )}
    </Flex>
      <Modal isOpen={modalEditLogo.isOpen} onClose={modalEditLogo.onClose}>
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Edit Logo</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {product && (
                <ProductLogosForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                      setIsAdding(false);
                      modalEditLogo.onClose()
                      refresh();
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditLogo.onClose}
            >Close</Button>
            <Button
              variant='purpleFill'
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}




