'use client'

import { Flex, Text } from '@chakra-ui/react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import PersonaQuoteForm from './personaQuoteForm';
import { PersonaContext } from '../../lib/personaContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';


export default function PersonaAsideQuote(props) {

  const {
    persona,
    rights
  } = useContext(PersonaContext);

  return (
    <>
    {persona && persona.quote && (
      <Flex
      mb={2}
      flexDirection={"column"}
      alignSelf={'stretch'}
      position={'relative'}
      pl={2}
      _before={{
        position : 'absolute',
        left: '-5px',
        top: '-5px', 
        content:  `"“"`,
        fontSize: '30px',
        fontFamily: 'var(--font-playfair) serif',
        color: 'text.light.grey',
      }}
      _after={{
        position : 'absolute',
        left: '0',
        top: '25px', 
        width: '2px',
        height: 'calc(100% - 30px)',
        backgroundColor: 'text.light.grey',
        content:  `""`,
      }}
    >
      <InlineFormWrapper
          canEdit={rights.can_update}
          value={
            <Flex
            >
              <Text>
               {persona.quote}
              </Text>
            </Flex>
           
          }
        >
          {(close) => (
            <PersonaQuoteForm
              onSubmitted={close}
              onCancel={close}
            />
          )}
        </InlineFormWrapper>
    </Flex>
     )
    }
    </>
  )
}




