'use client'

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/layout';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { Select } from 'chakra-react-select';
import useFeedbackApi from '../lib/useFeedbackApi';

const AddFeedbackForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    register,
    formState: { isSubmitting, isValid }
  } = useForm();

  const feedbackApi = useFeedbackApi();

  const [placeholder, setPlaceholder] = useState('');

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      if (data.description && data.type.value) {
         await feedbackApi.insertFeedback(
          data.type.value, 
          data.description
        );
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
            <Controller
              control={control}
              name="type"
              rules={{ required: 'Feedback type is mandatory' }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, error }
              }) => (
                <FormControl isInvalid={errors && errors.type} mb={3}>
                  <FormLabel>Feedback Type</FormLabel>
                  <Select
                    options={[{
                      label: 'Report a Problem',
                      value: 'problem',
                    }, {
                      label: 'Suggest an Idea',
                      value: 'idea',
                    }, {
                      label: 'Other',
                      value: 'other',
                    }]}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  <FormErrorMessage>{errors && errors.type}</FormErrorMessage>
                </FormControl>
              )}
            />
          <FormControl isInvalid={errors && errors.description} mb={3}>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder={placeholder}
              {...register('description', {
                required: true,
                minLength: 3,
              })}
            />
            <FormErrorMessage>{errors && errors.description}</FormErrorMessage>
          </FormControl>
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default AddFeedbackForm;


