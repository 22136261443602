import React, { useContext, useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/layout';
import PersonaThumbnail from '../../../persona/components/personaThumbnail';
import { ProductPageContext } from '../lib/productPageContext';
import { ProductContext } from '../../lib/productContext';
import { PersonaContext } from '@/persona/lib/personaContext';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import { Button, Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { MdClose, MdOutlineWarning } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';

export default function PersonaInProductListItem() {

  const {
    refreshPersonaList,
    refreshExperienceList
  } = useContext(ProductPageContext);

  const productContext = useContext(ProductContext);
  const {
    persona,
  } = useContext(PersonaContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const modalDelete = useDisclosure();
  const personaApi = usePersonaApi();

  async function removePersonaFromProduct() {
    try {
      setIsDeleting(true);
      await personaApi.unlinkProductFromPersona(
        productContext.product.id,
        persona.id
      )
      await Promise.all([
        refreshPersonaList(),
        refreshExperienceList()
      ])
      setIsDeleting(false);
      modalDelete.onClose();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="white"
        role={'group'}
        flex={1}
        mb={1}
        px={3}
        h={"34px"}
        _hover={{
          backgroundColor: 'background.grey',
          borderRadius: '2px'
        }}
      >
        <Flex alignItems={"center"}>
          <PersonaThumbnail
            size={24}
            personaId={persona.id}
            hasThumbnail={persona.has_thumbnail}
          />
          <Text ml={2}>{persona.name}</Text>
        </Flex>
        {(productContext.rights.can_update) && (
          <Box style={{ opacity: '0' }} _groupHover={{ opacity: '1 !important' }} >
            <IconButton
              icon={<MdClose />}
              size={'md'}
              variant='greyTransparent'
              onClick={(e) => {
                modalDelete.onOpen();
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </Box>
        )}
      </Flex>
      <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Remove Persona {persona.name}</ModalHeader>
          <ModalBody p={3} >
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
            >
              <Icon
                as={MdOutlineWarning}
                color={'text.medium.red'}
                boxSize={'18px'}
                mr={3}
              />
              <Text textStyle="body" color={'text.medium.red'} >Are you sure to remove the persona {persona.name} from product {productContext.product.name}?</Text>
            </Flex>
            <Flex
              border="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              backgroundColor="white"
              alignItems={'center'}
              p={3}
              mt={2}
            >
              <Text textStyle="body" color={'text.medium.red'} >
                The user will be removed from all the experiences of this product.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='redOutline'
              onClick={modalDelete.onClose}
            >Close</Button>
            <Button
              variant="redFill"
              ml={2}
              isLoading={isDeleting}
              onClick={removePersonaFromProduct}>Remove</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
