'use client'

import { Flex, Text } from '@chakra-ui/react';
import React, {useContext} from 'react';
import PersonaBioForm from './personaBioForm';
import { PersonaContext } from '../../lib/personaContext';
import InlineFormWrapper from '@/components/inlineFormWrapper';

export default function PersonaBio(props) {

  const {
    persona,
    rights,
  } = useContext(PersonaContext);

  return (
    <>
    {persona && persona.bio && (
      <Flex
      bg="background.white"
      border="1px solid"
      borderColor="divider.grey"
      mb={3}
      p={3}
      flexDirection={"column"}
    >
      <Text
        color="text.dark.grey"
        fontSize={"18px"}
        fontWeight={"500"}
        mb={1}
        >
        Bio
      </Text>
      <InlineFormWrapper
          canEdit={rights.can_update}
          value={
            <Text>
              {persona.bio}
            </Text>
          }
        >
          {(close) => (
            <PersonaBioForm
              onSubmitted={close}
              onCancel={close}
            />
          )}
        </InlineFormWrapper>
    </Flex>
     )
    }

    </>
  )
}




