import React, { useContext } from 'react';
import {Box} from '@chakra-ui/react';
import {Flex, Text} from '@chakra-ui/layout';
import PersonaThumbnail from './personaThumbnail';
import PersonaInsightTags from '../[id]/components/personaInsightTags';
import { PersonaContext } from '../lib/personaContext';

export default function PersonaListItem() {

  const {persona} = useContext(PersonaContext);

  return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={3}
        backgroundColor="white"
        role="group"
        _hover={{
          background: "background.grey",
        }}
      >
        <Flex
          alignItems='center' >
          <PersonaThumbnail size={40}/>
          <Box ml={2}>
            <Text fontSize={16} >{persona.name}</Text>
          </Box>
        </Flex>
        <PersonaInsightTags personaId={persona.id} />
      </Flex>
  );
}
