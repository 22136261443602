import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Flex,
  Progress,
  Text,
  Box
} from "@chakra-ui/react";
import { MdOutlineLayers, MdOutlineRoute, MdSearch } from "react-icons/md";
import useExperienceApi from "../experiences/lib/useExperienceApi";
import useInsightApi from "../insight/lib/useInsightApi";
import usePersonaApi from "../persona/lib/usePersonaApi";
import useProductsApi from "../products/lib/useProductsApi";
import { Link, useNavigate } from "react-router-dom";
import InsightPicto from "../insight/components/insightPicto";
import PersonaThumbnail from "../persona/components/personaThumbnail";
import PersonaProvider from "../persona/lib/personaProvider";
import ProductProvider from "../products/lib/productProvider";
import ProductLogo from "../products/components/productLogo";

const SearchBar = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const experienceApi = useExperienceApi();
  const insightApi = useInsightApi();
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [menuHover, setMenuHover] = useState(false);
  const [inputHover, setInputHover] = useState(false);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setIsLoading(true);
    if (value && value.length >= 2) {
      const promises = [
        insightApi.searchInsights(value),
        experienceApi.searchExperiences(value),
        experienceApi.searchPersonaExperienceGroups(value),
        experienceApi.searchProductExperienceGroups(value),
        personaApi.searchManyPersona(value),
        productApi.searchProducts(value),
      ];
      const result = await Promise.all(promises);
      const newSuggestions =
        result[0].map((r) => ({
          type: 'insight',
          data: r
        })).concat(
          result[1].map((r) => ({
            type: 'experience',
            data: r
          }))).concat(
            result[2].map((r) => ({
              type: 'persona_experience_group',
              data: r
            }))).concat(
              result[3].map((r) => ({
                type: 'product_experience_group',
                data: r
              }))).concat(
                result[4].map((r) => ({
                  type: 'persona',
                  data: r
                }))).concat(
                  result[5].map((r) => ({
                    type: 'product',
                    data: r
                  })))
      setSuggestions(newSuggestions)
    } else {
      setSuggestions([]);
    }
    setIsLoading(false);
  };

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const clickEvent = window.addEventListener('click', () => {
      const input = document.querySelector('input');
      const isFocus = document.activeElement === input;
      if (!menuHover && !inputHover && !isFocus) {
        setIsOpen(false);
      }
    });
    return () => {
      window.removeEventListener('click', clickEvent);
    }
  }, [])

  return (
    <Flex width="300px" mx={3} position={"relative"}>
      <InputGroup
        onMouseEnter={() => {
          setInputHover(true)
        }}
        onMouseLeave={() => {
          setInputHover(false)
        }}
      >
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} color="text.medium.grey" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search in Bibliona…"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </InputGroup>
      {isOpen && (
        <Flex
          position={"absolute"}
          top={"calc(100% + 10px)"}
          backgroundColor={"white"}
          width={'100%'}
          flexDirection={"column"}
          zIndex={"1000"}
          maxHeight={"50vh"}
          overflow={"auto"}
          borderRadius={"2px"}
          border={"1px solid"}
          borderColor={"divider.grey"}
        >
          {isLoading && (
            <Progress isIndeterminate size='xs' opacity={isLoading ? '1' : '0'} />
          )}
          <Flex
            flexDirection={"column"}
            py={2}
            onMouseEnter={() => {
              setMenuHover(true)
            }}
            onMouseLeave={() => {
              setMenuHover(false)
            }}
          >
            {suggestions.length > 0 ? suggestions.map((suggestion, i) => (
              <Box
                key={i}
                borderBottom={"1px solid"}
                borderColor={"divider.grey"}
                onClick={() => { setIsOpen(false) }}
              >
                {suggestion.type === 'insight' && <SearchListItemInsight insight={suggestion.data} />}
                {suggestion.type === 'experience' && <SearchListItemExperience experience={suggestion.data} />}
                {suggestion.type === 'persona_experience_group' && <SearchListItemPersonaExperienceGoup group={suggestion.data} />}
                {suggestion.type === 'product_experience_group' && <SearchListItemProductExperienceGoup group={suggestion.data} />}
                {suggestion.type === 'persona' && <SearchListItemPersona persona={suggestion.data} />}
                {suggestion.type === 'product' && <SearchListItemProduct product={suggestion.data} />}
              </Box>
            ))
              :
              <Flex px={3} py={2}>
                <Text>No result found</Text>
              </Flex>
            }
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const SearchListItemProduct = (props) => {
  const { product } = props;
  return (
    <ProductProvider
      originalProduct={product}
      productId={product.id}
    >
      <Link to={`/products/${product.id}`}>
        <Flex
          px={3}
          py={2}
          cursor={"pointer"}
          _hover={{ bg: "background.grey" }}
          flexDirection={"column"}
        >
          <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
            <ProductLogo size={20} />
            <Text>Product</Text>
          </Flex>
          <Text>{product.name}</Text>
        </Flex>
      </Link>
    </ProductProvider>
  );
};

const SearchListItemPersona = (props) => {
  const { persona } = props;
  return (
    <PersonaProvider
      originalPersona={persona}
      personaId={persona.id}
    >
      <Link to={`/persona/${persona.id}`}>
        <Flex
          px={3}
          py={2}
          cursor={"pointer"}
          _hover={{ bg: "background.grey" }}
          flexDirection={"column"}
        >
          <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
            <PersonaThumbnail size={20} />
            <Text>Persona</Text>
          </Flex>
          <Text>{persona.name}</Text>
        </Flex>
      </Link>
    </PersonaProvider>
  )
};

const SearchListItemProductExperienceGoup = (props) => {
  const { group } = props;
  return (
    <Link to={`/products/${group.product_id}?group-id=${group.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <Icon
            as={MdOutlineLayers}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text>Experience Group</Text>
        </Flex>
        <Text>{group.name}</Text>
      </Flex>
    </Link>
  )
};

const SearchListItemPersonaExperienceGoup = (props) => {
  const { group } = props;

  return (
    <Link to={`/persona/${group.persona_id}?group-id=${group.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <Icon
            as={MdOutlineLayers}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text>Experience Group</Text>
        </Flex>
        <Text>{group.name}</Text>
      </Flex>
    </Link>
  )
};


const SearchListItemExperience = (props) => {
  const { experience } = props;
  return (
    <Link to={`/experiences/${experience.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex
          gap={1}
          alignItems={"center"}
          mb={1}
          color="text.medium.grey">
          <Icon
            as={MdOutlineRoute}
            color="text.medium.grey"
            boxSize="16px"
          />
          <Text >Experience</Text>
        </Flex>
        <Text>{experience.objective}</Text>
      </Flex>
    </Link>
  )
};

const SearchListItemInsight = (props) => {
  const { insight } = props;
  return (
    <Link to={`/experiences/${insight.experience_id}#${insight.id}`}>
      <Flex
        px={3}
        py={2}
        cursor={"pointer"}
        _hover={{ bg: "background.grey" }}
        flexDirection={"column"}
      >
        <Flex gap={1} alignItems={"center"} mb={1} color="text.medium.grey">
          <InsightPicto size={20} type={insight.type} />
          <Text>Insight</Text>
        </Flex>
        <Text>{insight.name}</Text>
      </Flex>
    </Link>
  )
};

export default SearchBar;
