'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Flex} from '@chakra-ui/layout';
import {FormControl, FormErrorMessage, FormLabel, Skeleton} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import { Select } from 'chakra-react-select';
import useProductsApi from '../../lib/useProductsApi';
import { ProductContext } from '../../lib/productContext';

const ProductAsideTypeForm = forwardRef((props, ref) => {

  const {
    product,
    productId,
    refresh,
    types
  } = useContext(ProductContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    watch,
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const productApi = useProductsApi();
  const [mainTypes, setMainTypes] = useState();
  const [secondaryTypes, setSecondaryTypes] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const mainTypesValue = watch('mainTypes');

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      if(product.id) {
        let typeIds = data.mainTypes.map(t => t.value);
        if(data.secondaryTypes) {
          const secondaryTypeIds = data.secondaryTypes.map(t => t.value);
          typeIds = typeIds.concat(secondaryTypeIds)
        }
        await productApi.linkManyProductTypeToProduct(
          productId,
          typeIds,
        );
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSecondaryTypes = async () => {
    setLoadingData(true)
    const promises = [];
    mainTypesValue.forEach((type) => {
      promises.push(productApi.fetchProductTypeList(type.value))
    })
    const result = await Promise.all(promises);
    const newSecondaryTypes = [];
    mainTypesValue.forEach((mt, i) => {
      newSecondaryTypes.push({
        label: mt.label,
        options: result[i].map((t) => {
          return {
            label: t.label,
            value: t.id
          }
        }) 
      })
    })
    setSecondaryTypes(newSecondaryTypes)
    setLoadingData(false)
  };

  const fetchMainTypes = async () => {
    setLoadingData(true)
    const newMainTypes = await productApi.fetchProductTypeList();
    setMainTypes(newMainTypes.map(t => {
      return  {
        label: t.label,
        value: t.id
      }
    }));
    setLoadingData(false);
  };

  useEffect(() => {
    if(!mainTypes) {
      fetchMainTypes();
    }
  }, [mainTypes]);

  useEffect(() => {
    if(types) {
      setValue('mainTypes', types.filter(t => !t.parent)
      .map((t) => {
        return  {
          label: t.label,
          value: t.id
        }
      }));
      setValue('secondaryTypes', types
      .filter(t => !!t.parent)
      .map((t) => {
        return  {
          label: t.label,
          value: t.id
        }
      }));
    }
  }, [types]);

  useEffect(() => {
    if(mainTypesValue) {
      if(mainTypesValue.length > 0) {
        fetchSecondaryTypes()
      } else {
        setSecondaryTypes(null)
        setValue('secondaryTypes', null)
      }
    } else {
      setSecondaryTypes(null)
    }
  }, [mainTypesValue]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex alignSelf={"stretch"}  w={"100%"}>
        <form style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'stretch'}
            flexDirection={'column'}
            gap={2}
          >
            {mainTypes && (
            <FormControl isInvalid={errors && errors.mainTypes}>
              <FormLabel>Types</FormLabel>
                  <Controller
                    name="mainTypes"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                    <Select
                    isMulti
                    options={mainTypes}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                   )}
                />
                <FormErrorMessage>{errors && errors.mainTypes}</FormErrorMessage>
             </FormControl>
            )}
            {secondaryTypes && (
            <FormControl
              isInvalid={errors && errors.secondaryTypes}
            >
              <Controller
                  name="secondaryTypes"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <Select
                    isMulti
                    placeholder="Refine…"
                    options={secondaryTypes}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  )}
                />
                <FormErrorMessage>{errors && errors.secondaryTypes}</FormErrorMessage>
              </FormControl>
            )}
            {loadingData && <Skeleton height='30px' />}
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                    <IconButton
                      variant="greyOutline"
                      icon={<MdClose />}
                      onClick={onCancel}
                    />
                    )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='purpleOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default ProductAsideTypeForm;


