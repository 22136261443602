import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { useContext, useState } from 'react';
import { ProductContext } from '../lib/productContext';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { MdOutlineWarning } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useProductsApi from '../lib/useProductsApi';

export default function DeleteProductModal(props) {

  const { disclosure } = props;

  const {
    product,
  } = useContext(ProductContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const productApi = useProductsApi();
  const navigate = useNavigate();

  const deleteProduct = async () => {
    try {
      setIsDeleting(true);
      await productApi.deleteProduct(product.id);
      setIsDeleting(false);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {disclosure && (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader>Delete Product {product && product.name}</ModalHeader>
            <ModalBody >
              <Box
                p={3}
              >
                {(product && product.name) && (
                  <>
                    <Flex
                      border="1px"
                      borderColor="divider.grey"
                      borderRadius="2px"
                      backgroundColor="white"
                      alignItems={'center'}
                      p={3}
                      mb={2}
                    >
                      <Icon
                        as={MdOutlineWarning}
                        color={'text.medium.red'}
                        boxSize={'18px'}
                        mr={3}
                      />
                      <Text textStyle="body" color={'text.medium.red'} >Are you sure to delete product {product.name}?</Text>
                    </Flex>
                    <Flex
                      border="1px"
                      borderColor="divider.grey"
                      borderRadius="2px"
                      backgroundColor="white"
                      alignItems={'center'}
                      p={3}
                    >
                      <Text textStyle="body" color={'text.medium.red'} >
                        It will be removed from all associated persona and experiences.
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='redOutline'
                onClick={disclosure.onClose}
              >Close</Button>
              <Button
                variant="redFill"
                ml={2}
                isLoading={isDeleting}
                onClick={deleteProduct}>Delete</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

