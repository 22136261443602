import { Icon } from "@chakra-ui/react";
/*
* Instruction for adding an icon :
* 1. Clean your svg to make it very light
* 2. Remove all the svg Tag and insert the children inside the Icon tag
* 3. swith all the hypen with a capital ex: fill-rule -> fillRule
* 4. Change fill colors by "currentColor" ex: fill="#000" -> fill="CurrentColor" (only if you don't need a custom color)
*/
const CognitionIcon = (props) => (
  <Icon viewBox="0 0 25 25" {...props} fill="currentColor" >
    <path d="M12.275 15.525c1.167 0 2.158-.375 2.975-1.125.817-.75 1.225-1.658 1.225-2.725 0-.95-.304-1.754-.913-2.413-.608-.658-1.345-.987-2.212-.987-.783 0-1.446.25-1.987.75a2.418 2.418 0 0 0-.813 1.85c0 .317.063.625.188.925.125.3.304.575.537.825L12.7 11.2a.322.322 0 0 1-.112-.125.513.513 0 0 1 .188-.612.958.958 0 0 1 .574-.163c.333 0 .608.137.825.412.217.276.325.605.325.988 0 .517-.213.954-.637 1.313-.425.358-.946.537-1.563.537-.783 0-1.446-.317-1.988-.95-.541-.633-.812-1.408-.812-2.325 0-.483.092-.946.275-1.388.183-.441.442-.829.775-1.162L9.125 6.3A5.316 5.316 0 0 0 7.9 8.1a5.559 5.559 0 0 0-.425 2.15c0 1.467.467 2.713 1.4 3.738.933 1.024 2.067 1.537 3.4 1.537ZM6 22v-4.3a9.233 9.233 0 0 1-2.212-3.037A8.771 8.771 0 0 1 3 11c0-2.5.875-4.625 2.625-6.375S9.5 2 12 2c2.083 0 3.93.612 5.538 1.837s2.654 2.821 3.137 4.788l1.3 5.125a.947.947 0 0 1-.175.863.96.96 0 0 1-.8.387h-2v3c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 20h-2v2h-2v-4h4v-5h2.7l-.95-3.875a6.533 6.533 0 0 0-2.45-3.7C15.05 4.475 13.617 4 12 4c-1.933 0-3.583.675-4.95 2.025C5.683 7.375 5 9.017 5 10.95c0 1 .204 1.95.612 2.85a7.5 7.5 0 0 0 1.738 2.4l.65.6V22H6Z" fill="currentColor"/>
  </Icon>
)
export default CognitionIcon;

