'use client'

import { Box, Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";

export default function LoadingProduct() {
    return (
        <Flex
            w={"100%"}
            height={"100%"}
        >
            <Flex
                flexDirection={'column'}
                alignItems={'center'}
                gap={3}
                w={"250px"}
                borderRight={"1px solid"}
                borderColor={"divider.grey"}
                p={3}
                flexShrink={"0"}
            >
                <SkeletonCircle size='100' />
                <Skeleton height='40px' w={"100%"} />
                <Skeleton height='100px' w={"100%"} />
                <Skeleton height='100px' w={"100%"} />
                <Skeleton height='100px' w={"100%"} />
            </Flex>
            <Flex
                flexDirection={'column'}
                p={3}
                gap={3}
                w={"100%"}
            >
                <Skeleton height='100px' width={"100%"} />
                <Skeleton height='400px' width={"100%"} />
            </Flex>
        </Flex>
    )
}