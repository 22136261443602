'use client'

import React, { Children, useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/layout';

export default function InlineFormWrapper(props) {

  const {
    value,
    children,
    canEdit = true,
    width,
    maxWidth,
    minWidth,
    onToggle
  } = props

  const [toggle, setToggle] = useState(false);

  const close = () => {
    setToggle(false);
  }

  useEffect(() => {
    if(onToggle) {
      onToggle(toggle)
    }
  }, [toggle])

  return (
    <>
      {(!toggle && canEdit) && (
        <Flex
           w={width ? width : "fit-content"}
           maxWidth={maxWidth ? maxWidth : "inherit"}
           minWidth={minWidth ? minWidth : "inherit"}
          _hover={{
            position: 'relative',
            zIndex: '1000',
            cursor: 'pointer',
            _before: {
              content: '""',
              position: 'absolute',
              left: '-10px',
              top: '-4px',
              w: '100%',
              h: '100%',
              bg: 'text.disable.grey',
              px: '10px',
              py: '4px',
              zIndex: '-1000',
              borderRadius: "4px"
            }
          }}
          onClick={() => {
            if(canEdit) {
              setToggle(true);
            }
          }}
        >
          {value}
        </Flex>
      )}
      {(toggle && canEdit) && 
       <Box
        w={width ? width : "fit-content"}
        maxWidth={maxWidth ? maxWidth : "inherit"}
        minWidth={minWidth ? minWidth : "inherit"}
        >
        {children(close)}
       </Box>
      }
      {!canEdit && 
        <Flex>
          {value}
        </Flex>
      }
    </>
  );
}
