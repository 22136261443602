'use client'

import React, {useState, useContext, useEffect } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/layout';
import PersonaAsideEditMenu from './personaAsideEditMenu';
import { PersonaContext } from '../../lib/personaContext';
import { MdArrowBack } from 'react-icons/md';
import { IconButton } from '@chakra-ui/react';
import ProductInPersonaList from '@/persona/[id]/components/productInPersonaList';
import PersonaNameForm from './personaNameForm';
import InlineFormWrapper from '@/components/inlineFormWrapper';
import PersonaAsideQuote from './personaAsideQuote';
import PersonaAttributes from './personaAttributes';
import { useTheme } from '@emotion/react';
import PersonaAsideThumbnail from './personaAsideThumbnail';
import { useNavigate } from 'react-router-dom';

export default function PersonaAside(props) {

  const theme = useTheme();

  const {
    persona,
    rights
  } = useContext(PersonaContext);

  const navigate = useNavigate();
  const [space, setSpace] = useState()

  useEffect(() => {
    if (theme) {
      const spaceCurrent = parseInt(theme.space[3].replace('px', ''));
      setSpace((spaceCurrent * 2))
    }
  }, [theme])

  return (
    <>
      {persona && (
        <Flex
          flexShrink={"0"}
          w="275px"
          borderRight="1px"
          borderColor="divider.grey"
          borderRadius="2px"
          backgroundColor="white"
          alignItems={'stretch'}
          flexDirection={'column'}
          gap={3}
          position="relative"
          overflow={"auto"}
          py={3}
        >
          <IconButton
            left={1}
            top={1}
            position="absolute"
            variant="greyTransparent"
            icon={<MdArrowBack />}
            size="lg"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Flex
            flexDirection={"column"}
            alignItems={'center'}
            gap={2}
            mb={1}
          >
            <PersonaAsideThumbnail />
            <InlineFormWrapper
              canEdit={rights.can_update}
              value={
                <Text
                  px={3}
                  fontSize={20}
                  color="fill.blue"
                  textAlign={"center"}
                >
                  {persona.name}
                </Text>
              }
            >
              {(close) => (
                <PersonaNameForm
                  onSubmitted={close}
                  onCancel={close}
                />
              )}
            </InlineFormWrapper>
            <PersonaAsideEditMenu />
          </Flex>
          {persona.quote && (
            <>
              <Divider ml={3} variant="blue" width={"calc(100% - " + space + "px)"} />
              <Flex px={3} width={"100%"}>
                <PersonaAsideQuote />
              </Flex>
            </>
          )}
          {(persona.job
            || persona.location
            || persona.age
            || persona.education
            || persona.fammily_status
            || persona.interests
          )
            && (
              <>
                <Divider ml={3} variant="blue" width={"calc(100% - " + space + "px)"} />
                <Flex px={3} w={"100%"}>
                  <PersonaAttributes />
                </Flex>
              </>
            )}
          <Divider ml={3} variant="blue" width={"calc(100% - " + space + "px)"} />
          <Box w="100%">
            <ProductInPersonaList personaId={persona.id} />
          </Box>
          <Divider ml={3} variant="blue" width={"calc(100% - " + space + "px)"} />
        </Flex>
      )}
    </>
  )
}




