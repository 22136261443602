'use client'

import React, { useContext, useEffect, useState } from 'react';
import { forwardRef } from '@chakra-ui/system';
import { Select } from 'chakra-react-select';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { AuthContext } from '@/auth/lib/authContext';
import { ProductContext } from '../../lib/productContext';
import { ProductPageContext } from '../lib/productPageContext';
import useExperienceApi from '@/experiences/lib/useExperienceApi';

const MoveExperienceInProductToGroupForm = forwardRef((props, ref) => {

  const {
    productId,
    product,
    rights,
  } = useContext(ProductContext);

  const {
    experienceList,
    experienceGroupList,
  } = useContext(ProductPageContext);

  const {
    experienceId,
    hideSave,
    onSubmitted,
    onSubmitting,
    onValidityChange
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isValid }
  } = useForm();

  const { profile } = useContext(AuthContext);

  const experienceApi = useExperienceApi();
  const [groupOptionsList, setGroupOptionsList] = useState(null);
  const [experience, setExperience] = useState(null);

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const groupId = data.group.value
      if (groupId) {
        await experienceApi.moveExperienceToGroupInProduct(
          productId,
          experienceId,
          groupId
        );
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (experienceGroupList && experienceId) {
      const experienceFiltered = experienceList.filter(e => e.id === experienceId)
      const newExperience = experienceFiltered[0]
      const newGroupOptionList = experienceGroupList
        .filter(eg => eg.id !== newExperience.product_experience_group_id)
        .map(eg => ({ label: eg.name, value: eg.id }))
      setExperience(newExperience);
      setGroupOptionsList(newGroupOptionList);
    }
  }, [experienceGroupList, experienceId]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Controller
          control={control}
          name="group"
          rules={{ required: "Please select a group" }}
          render={({
            field: { onBlur, value, onChange },
          }) => (
            <FormControl isInvalid={errors && errors.group}>
              <FormLabel>Select a Group</FormLabel>
              <Select
                options={groupOptionsList}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
              <FormErrorMessage>{errors && errors.group}</FormErrorMessage>
            </FormControl>
          )}
        />
        <Button
          style={{
            display: hideSave ? 'none' : 'inherit',
          }}
          ref={ref}
          isLoading={isSubmitting}
          disabled={!isValid}
          type='submit'
          my={2}
        >Save</Button>
      </form>
    </>
  );
});

export default MoveExperienceInProductToGroupForm;


