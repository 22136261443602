'use client'

import React, {useContext, useEffect} from 'react';
import {Button, FormControl, FormErrorMessage, FormLabel, Input, Skeleton} from '@chakra-ui/react';
import useProfileApi from '../lib/useProfileApi';
import {Box} from '@chakra-ui/layout';
import ImageInput from '../../components/imageInput';
import {forwardRef} from '@chakra-ui/system';
import {Controller, useForm} from 'react-hook-form';
import { AuthContext } from '@/auth/lib/authContext';
import { useState } from 'react';

const ProfileThumbnailForm = forwardRef((props, ref) => {

  const { profile } = useContext(AuthContext);
  const [downloading, setDownloading] = useState(false);

  const {
    hideSave,
    onSubmitting,
    onSubmitted,
    onValidityChange,
  } = props;

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const profileApi = useProfileApi();

  const fetchThumbnail = async () => {
    try {
      if(profile.has_thumbnail) {
        setDownloading(true)
        const newThumbnail = await profileApi.downloadThumbnail(profile.id, 256);
        setValue('thumbnail', { originalImage: newThumbnail })
        setDownloading(false)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const image = data.thumbnail;
      if(image && profile) {
        await profileApi.uploadThumbnail(image, profile.id)
      } else if (!image && profile) {
        await profileApi.deleteThumbnail(profile.id)
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (profile) {
      fetchThumbnail();
    }
  }, [profile]);

  return (
    <>
      <Box
        border="1px"
        borderColor="divider.grey"
        borderRadius="2px"
        backgroundColor="white"
        p={3}
      >
        <form onSubmit={handleSubmit(onSubmit)} >
          <Controller
            control={control}
            name="thumbnail"
            rules={{}}
            render={({
                       field: { onChange, onBlur, value, name, ref },
                       fieldState: { invalid, error }
                     }) => (
              <FormControl isInvalid={errors && errors.experience}>
                <FormLabel>Upload an image</FormLabel>
                {downloading && (<Skeleton height={"40px"} />)}
                {!downloading && (
                  <ImageInput
                    maxWidth={100}
                    value={value}
                    crop={{
                      aspect: 1,
                      zoom: 1,
                      x: 0,
                      y: 0
                    }}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    onBlur={onBlur}
                    outputWidths={[64, 128, 256]}
                  />
                )}
                <FormErrorMessage>{errors && errors.experience}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Button
            style={{
              display: hideSave ? 'none' : 'inherit',
            }}
            ref={ref}
            isLoading={isSubmitting}
            disabled={!isValid && downloading}
            type='submit'
            my={2}
          >Save</Button>
        </form>
      </Box>
    </>
  );
});

export default ProfileThumbnailForm;


