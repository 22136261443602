import React, {useContext, useEffect, useState} from 'react';
import {Flex, Box, Text} from '@chakra-ui/layout';
import useProductsApi from '../lib/useProductsApi';
import { MdDevices } from 'react-icons/md';
import { Icon } from '@chakra-ui/react';
import { ProductContext } from '../lib/productContext';
import {SkeletonCircle} from '@chakra-ui/react'

export default function ProductLogo(props) {

  const {
    size,
    withText,
    hasAcronym = false
  } = props;

  const {
    product,
  } = useContext(ProductContext);

  const [logo, setLogo]  =  useState(null);
  const [logoWithText, setLogoWithText]  =  useState(null);
  const productsApi = useProductsApi();
  const [downloading, setDownloading] = useState(false);
  const [acronym, setAcronym]  =  useState(null);

  const fetchData = async () => {
    try {
      let imageSize;
      if(size > 128) {
        imageSize = 256;
      } else if(size > 64 &&  size <= 128) {
        imageSize = 128;
      } else if(size <= 64 ) {
        imageSize = 64;
      }
      setDownloading(true)
      if(withText){
        if(product.has_logo_with_text) {
          const newLogo = await productsApi.downloadLogoWithText(product.id, imageSize);
          setLogoWithText(newLogo);
        } else {
          setLogoWithText(null)
        }
      } else {
        if(product.has_logo) {
          const newLogo = await productsApi.downloadLogo(product.id, imageSize);
          setLogo(newLogo);
        } else {
          setLogo(null)
        }
      }
      setDownloading(false)
    } catch (e) {
      console.error(e);
    }
  };

  const updateAcronym = async () => {
    const nameArray = product.name.split(' ');
    if(nameArray.length > 1) {
      setAcronym(nameArray[0][0] + (nameArray[1]?  nameArray[1][0] : ''));
    } else {
      setAcronym(nameArray[0][0])
    }
  };

  useEffect(() => {
    if(product) {
      fetchData()
    }
  }, [product]);

  useEffect(() => {
    if(product.name) {
      updateAcronym();
    }
  }, [product]);

  return (
    <>
      <Flex
        border={((product.has_logo_with_text && withText) 
          || (product.has_logo && !withText)) ? "none" : "1px"}
        borderRadius="50%"
        backgroundColor="background.purple"
        borderColor="divider.purple"
        alignItems='center'
        justifyContent='center'
        overflow={'hidden'}
        w={size + 'px'}
        h={size + 'px'}
        flexShrink={0}
      >
        <>
          { (product) && (
            <>
              {(downloading && (!logo && !logoWithText)) && (
                <SkeletonCircle size={size}/>
              )}
              {withText && (
                <> 
                  {(product.has_logo_with_text && logoWithText) && (
                    <Box
                      w={size + 'px'}
                      h={size + 'px'}
                      borderRadius="50%"
                      backgroundColor="white"
                      overflow={'hidden'}
                    >
                      <img
                        src={logoWithText}
                        alt="logo with text"
                        style={{width: '100%', height:'100%', 'objectFit': 'contain'}}
                      />
                    </Box>
                  )}
                </>
              )}
              { (product.has_logo && logo) && (
                <>
                  <Box
                    w={size + 'px'}
                    h={size + 'px'}
                    borderRadius="50%"
                    backgroundColor="white"
                    overflow={'hidden'}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{width: '100%', height:'100%', objectFit: 'contain'}}
                    />
                  </Box>
                </>
              )}
              { ((!product.has_logo_with_text && withText) 
                || (!product.has_logo && !withText)) && (
                  <>
                  {!hasAcronym && (
                    <Icon
                      as={MdDevices}
                      color={'fill.purple'}
                      boxSize={((size * 0.6) + 'px')}
                    />
                  )}
                  {hasAcronym && (
                    <Text
                    textTransform={"uppercase"}
                    fontWeight={"600"}
                    fontSize={((size / 2.14) + 'px')}
                    color={"text.medium.purple"}
                    >{acronym}</Text>
                  )}
                  </>
              )}
            </>
          )}
        </>
      </Flex>
    </>
  );
}