import React, {useContext, useEffect, useState } from 'react';
import Header from './components/header';
import SideMenu from './components/sideMenu';
import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './auth/lib/authContext';

export default function RootLayout({ children }) {

  const location = useLocation();
  const [isInAuth, setIsInAuth] = useState(true);
  const {isAuthenticated} = useContext(AuthContext)

  useEffect(() => {
    setIsInAuth(location.pathname.includes('/auth'))
  }, [location.pathname]);

  return (
      <Flex
        w={"100%"}
        h={"100%"}
        alignItems={"stretch"}
        flexDirection={'column'}
        backgroundColor={'background.grey'}
      >
        {(!isInAuth && isAuthenticated) && <Header />}
        <Flex
          flex={1}
          height={"100px"}
          width={"100%"}
          >
        {(!isInAuth && isAuthenticated) && <SideMenu />}
          <Flex
            flex={1}
            width={"300px"}
          >
             {children}
          </Flex>
        </Flex>
      </Flex>
  )
}
