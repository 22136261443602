'use client'

import React, {useContext, useEffect, useState} from 'react';
import {Box, Text} from '@chakra-ui/layout';
import {forwardRef} from '@chakra-ui/system';
import {Select} from 'chakra-react-select';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Skeleton,
} from '@chakra-ui/react';
import {Controller, useForm} from 'react-hook-form';
import useProductsApi from '../../../products/lib/useProductsApi';
import { AuthContext } from '@/auth/lib/authContext';

const AddProductToPersonaForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onSubmitted,
    onSubmitting,
    personaId,
    onCreateNew
  } = props;

  const {profile} = useContext(AuthContext);

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isValid }
  } = useForm();

  const productApi = useProductsApi();
  const [productOptionsList, setProductOptionsList] = useState(null);

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const productId = data.product.value;
      await productApi.linkManyPersonaToProduct(productId, [personaId]);
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      const newProducts = await productApi.fetchProductNotInPersonaForUser(
        personaId,
        profile.id
      );
      const newProductList = newProducts.map((p) => {
        return {label: p.name, value: p.id}
      });
      if (onCreateNew) {
        newProductList.unshift({
          label: '+ Create New Product',
          value: -1,
        });
      }
      setProductOptionsList(newProductList);
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    if(personaId) {
      fetchData();
    }
  }, [personaId]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Controller
          control={control}
          name="product"
          rules={{ required: "Please enter at least one product" }}
          render={({
                     field: { onChange, onBlur, value, name, ref },
                     fieldState: { invalid, error }
                   }) => (
            <FormControl isInvalid={errors && errors.product}>
              <FormLabel>Select a product</FormLabel>
              {!productOptionsList && (
                <Skeleton w={"100%"} height={"30px"}/>
              )}
              {productOptionsList && (
                <Select
                options={productOptionsList}
                onChange={(e) => {
                  if(e.value === -1 && onCreateNew) {
                    onCreateNew();
                  } else {
                    onChange(e);
                  }
                }}
                onBlur={onBlur}
                value={value}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
              )}
              <FormErrorMessage>{errors && errors.product}</FormErrorMessage>
            </FormControl>
          )}
        />
        <Button
          style={{
            display: hideSave ? 'none' : 'inherit',
          }}
          ref={ref}
          isLoading={isSubmitting}
          disabled={!isValid}
          type='submit'
          my={2}
        >Save</Button>
      </form>
    </>
  );
});

export default AddProductToPersonaForm;


