import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import useInsightApi from './useInsightApi.js';
import { InsightContext } from './insightContext.js';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import useProductsApi from '@/products/lib/useProductsApi';
import { AuthContext } from '@/auth/lib/authContext';
import { SlideshowContext } from './slideshowContext.js';
import Slide from '../components/slide.js';
import InsightProvider from './insightProvider.js';
import { useDisclosure } from '@chakra-ui/hooks';

export default function SlideshowProvider(props) {

  const {children} = props

  const [insights, setInsights] = useState()
  const [skipKey, setSkipKey] = useState(false)
  const disclosure = useDisclosure();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1)
  const [currentInsight, setCurrentInsight] = useState()
  const insightRef = useRef(insights)
  const isOpenRef = useRef(disclosure.isOpen)
  const currentSlideIndexRef = useRef(currentSlideIndex)
  const skipKeyRef = useRef(skipKey)
  
  const playFromInsight = (insightId) => {
    setSkipKey(false);
    const newCurrentInsight = insights.filter(i => i.id === insightId)[0];
    setCurrentInsight(newCurrentInsight);
    setCurrentSlideIndex(newCurrentInsight.order);
    disclosure.onOpen();
  }

  const play = async () => {
    setSkipKey(false);
    setCurrentSlideIndex(1);
    const newCurrentInsight = insights.filter(i => i.order === 1)[0];
    setCurrentInsight(newCurrentInsight);
    disclosure.onOpen();
  };

  const playReverse = async () => {
    setSkipKey(false);
    setCurrentSlideIndex(insights.length);
    const newCurrentInsight = insights.filter(i => i.order === (insights.length))[0];
    setCurrentInsight(newCurrentInsight);
    disclosure.onOpen();
  };

  const next = async () => {
    if(!skipKeyRef.current) {
      if(isOpenRef.current) {
        if(currentSlideIndexRef.current < insightRef.current.length ){
          const nextIndex = currentSlideIndexRef.current + 1;
          setCurrentSlideIndex(nextIndex);
          const newCurrentInsight = insightRef.current.filter(i => i.order === nextIndex)[0];
          setCurrentInsight(newCurrentInsight);
        }
      }
    } else {
      setSkipKey(false);
    }
  }

  const previous = async () => {
    if(!skipKeyRef.current) {
      if(isOpenRef.current) {
        if(currentSlideIndexRef.current > 1 ){
          const nextIndex = currentSlideIndexRef.current - 1;
          setCurrentSlideIndex(nextIndex);
          const newCurrentInsight = insightRef.current.filter(i => i.order === nextIndex)[0];
          setCurrentInsight(newCurrentInsight);
        }
      }
    } else {
      setSkipKey(false);
    }
  }

  const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        next()
      }
      if (event.key === 'ArrowLeft') {
        previous();
      }
  };

  useEffect(() => {
    insightRef.current = insights;
  }, [insights]);

  useEffect(() => {
    isOpenRef.current = disclosure.isOpen;
  }, [disclosure.isOpen]);

  useEffect(() => {
    currentSlideIndexRef.current = currentSlideIndex;
  }, [currentSlideIndex]);
  
  useEffect(() => {
    skipKeyRef.current = skipKey;
  }, [skipKey]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  return (
    <SlideshowContext.Provider value={{
      play,
      playReverse,
      setInsights: setInsights,
      playFromInsight: playFromInsight,
      playReverse: playReverse,
      setSkipKey: setSkipKey,
    }} >
      {children}
      {(insights && currentInsight) && 
       <InsightProvider
        insightId={currentInsight.id}
        originalInsight={currentInsight}
        >
        <Slide disclosure={disclosure} />
       </InsightProvider>
      }
    </SlideshowContext.Provider>
  );
}
