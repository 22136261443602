'use client'

import React, {useEffect, useState, useContext} from 'react';
import {Box, Flex, Divider, Spacer, Text} from '@chakra-ui/layout';
import {Button, Input, FormControl, FormErrorMessage, IconButton, useToast, Tag, TagLabel, Skeleton} from '@chakra-ui/react'
import { MdAdd, MdArrowBack, MdLink, MdPersonAdd} from 'react-icons/md';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import useProfileApi from '@/profile/lib/useProfileApi';
import ProfileThumbnail from '@/profile/component/profileThumbnail';
import ProductMemberListItem from './productMemberListItem.js';
import { useForm } from 'react-hook-form';
import roleDictionnary from '@/lib/roleDictionnary';
import { Select } from 'chakra-react-select';
import useProductsApi from '../../lib/useProductsApi.js';
import memberStatusDictionnary from '@/lib/memberStatusDictionnary.js';
import { ProductContext } from '../../lib/productContext.js';
import { AuthContext } from '@/auth/lib/authContext.js';
import ProfileProvider from '@/profile/lib/profileProvider.js';
import ProductUserTempListItem from './productUserTempListItem.js';
import ProductSelectedMembers from './productSelectedMembers.js';

export default function ProductMembers(props) {

  const {
    productId,
    role,
    refresh,
    hasRequestedEdition
} = useContext(ProductContext);
  
  const {profile} = useContext(AuthContext);
  const profileApi = useProfileApi();
  const productApi = useProductsApi();
  const [profiles, setProfiles] = useState(false);
  const [owner, setOwner] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [user_temp, setUsersTemps] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isSendingRequest, setIsSendingRequest] = useState(false)
  const [addingMembers, setAddingMembers] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [status, setStatus] = useState()
  const [membersRole, setMembersRole] = useState(roleDictionnary.contributor)
  const membersModal = useDisclosure();
  const addMembersModal = useDisclosure();
  const requestEditModal = useDisclosure();
  const toast = useToast();
  const formMember = useForm({mode: 'onChange'});
  const formAddMember = useForm({mode: 'onChange'});

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const promises = [
        profileApi.fetchMembersProfilesByProductId(
          productId,
          memberStatusDictionnary.approved
        ),
        profileApi.fetchProductOwnerByProductId(productId),
        profileApi.fetchTemporaryUsersByProductId(productId),
        productApi.fetchMemberStatus(productId, profile.id)
      ];
      const result = await Promise.all(promises);
      setProfiles(result[0]);
      setOwner(result[1]);
      setUsersTemps(result[2]);
      setStatus(result[3]);

      const newOwnerEmail = await profileApi.fetchEmailByProfileId(result[1].id);
      setOwnerEmail(newOwnerEmail);

      setIsFetching(false);
    } catch (e) {
      console.error(e)
    }
  };

  const onSubmitMembersForm = async (data) => {
    try {
      const newSelectedMembers = Array.from(selectedMembers)
      newSelectedMembers.push(data.people);
      setSelectedMembers(newSelectedMembers);
      addMembersModal.onOpen();
      membersModal.onClose();
    } catch (e) {
      console.error(e);
    }
  };
  
  const resetModal = () => {
    addMembersModal.onClose();
    membersModal.onOpen();
    formMember.setValue('people', '')
    setSelectedMembers([])
  }

  const onSubmitAddMembersForm = async (data) => {
    try {
      const newSelectedMembers = Array.from(selectedMembers)
      if(newSelectedMembers.indexOf(data.people) === -1) {
        newSelectedMembers.push(data.people);
      }
      formAddMember.reset();
      setSelectedMembers(newSelectedMembers);
      addMembersModal.onOpen();
      membersModal.onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const addMembers = async () => {
    try {
      setAddingMembers(true);
      await productApi.addMembers(selectedMembers, productId, membersRole);
      await fetchData();
      resetModal();
      setAddingMembers(false);
    } catch (error) {
      console.error(error);
    }
  }

  const sendEditRequest = async () => {
    try {
      setIsSendingRequest(true);
      await productApi.requestEdit(productId, profile.id);
      await fetchData();
      requestEditModal.onClose();
      setIsSendingRequest(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(productId && role) {
      fetchData();
    }
  }, [productId, role]);
  
  return (
    <>
      { isFetching && (
        <Skeleton w={"150px"} height={"30px"} />
      )}
      {!isFetching && (
      <Flex alignItems={'center'}>
      {profiles && (
        <Flex
          position={'relative'}
          cursor={'pointer'}
          onClick={membersModal.onOpen}
          w={ (((profiles.length * 20) + 10)  + 'px')}
        >
          {profiles.map((p, i) => { 
            return (
              <Box
              key={i}
              position={'relative'}
              left={i > 0 ? (-10 * i + 'px'): null } >
                <ProfileProvider
                   profileId={p.id}
                   originalProfile={p}
                   >
                  <ProfileThumbnail size={30} />
                </ProfileProvider>
              </Box>
            )
          })}
        </Flex>
      )}
      {(role && role !== roleDictionnary.viewer
      && status !== memberStatusDictionnary.requested  ) && (
          <Button
          variant="purpleOutline"
          leftIcon={<MdPersonAdd/>}
          ml={2}
          onClick={membersModal.onOpen}
          >Share</Button>
      )}
      {(role && role !== roleDictionnary.viewer
      && status === memberStatusDictionnary.requested  ) && (
        <Tag variant="yellow">
          <TagLabel>Edition Requested</TagLabel>
       </Tag>
      )}
      { (!role || (role && role === roleDictionnary.viewer) && !hasRequestedEdition) && (
          <Button
          variant="greyOutline"
          ml={2}
          onClick={requestEditModal.onOpen}
          >Request to Edit</Button>
      )}
      {(!role ||  (role && role === roleDictionnary.viewer) && hasRequestedEdition) && (
        <Tag variant="yellow" ml={2}>
          <TagLabel>Edition Request Pending</TagLabel>
        </Tag>
      )}
      <Modal
        isOpen={membersModal.isOpen}
        onClose={membersModal.onClose}
      >
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Members</ModalHeader>
          <ModalBody p={3}  >
            {(role && role !== roleDictionnary.viewer) && (
                <Box mb={2} >
                <form onSubmit={formMember.handleSubmit(onSubmitMembersForm)}>
                    <Flex alignItems={'center'} >
                    <FormControl isInvalid={formMember.formState.errors?.people}>
                      <Input
                          placeholder='Add People'
                          {...formMember.register('people', {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address"
                            }
                          })}
                        />
                      <FormErrorMessage>{formMember.formState.errors.people?.message}</FormErrorMessage>
                    </FormControl>
                    { formMember.formState.isValid && (
                      <IconButton
                        icon={<MdAdd />}
                        ml={2}
                        type={'submit'}
                        variant='purpleOutline'
                      />
                    )}
                  </Flex>
                </form>
              </Box>
            )}
            <Box
              borderWidth="1px"
              borderColor="divider.grey"
              borderRadius="2px"
              borderStyle={'solid'}
              >
              {profiles && profiles.map((p, i) => { 
                return (
                  <Box key={i}>
                    { i > 0 && <Divider />}
                    <ProfileProvider 
                      profileId={p.id}
                      originalProfile={p}
                    >
                      <ProductMemberListItem
                        onRevoke={() => {
                          setProfiles(null)
                          fetchData()
                        }}
                        onRoleUpdated={() => {
                          setProfiles(null)
                          fetchData();
                        }}
                      />
                    </ProfileProvider>
                  </Box>
                )
              })}
              { (role) && (
                <>
                  { (user_temp && user_temp.length > 0) && <Divider />  }
                  {user_temp && user_temp.map((u, i) => { 
                    return (
                      <Box key={i}>
                        { i > 0 && <Divider />}
                        <ProductUserTempListItem  userTemp={u} />
                      </Box>
                    )
                  })}
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
          <Button
              variant="greyOutline"
              onClick={() => {
                const url = window.location.href;
                navigator.clipboard.writeText(url).then(() => {
                  toast({
                    position: 'bottom-right',
                    description: "Linked Copied",
                    duration: 1000,
                    isClosable: true,
                  });
                })
              }}
              leftIcon={<MdLink />}
            >Copy Link</Button>
            <Spacer />
            <Button
              variant="purpleOutline"
              ml={2}
              onClick={membersModal.onClose}
            >Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={addMembersModal.isOpen}
        onClose={addMembersModal.onClose}
      >
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>
          <IconButton
              fontSize='24'
              variant="tertiary"
              icon={<MdArrowBack/>}
              mr={2}
              onClick={resetModal}
            />
            Add Members
            </ModalHeader>
          <ModalBody p={3}  >
            <Box mb={2} >
              <form onSubmit={formAddMember.handleSubmit(onSubmitAddMembersForm)}>
                    <ProductSelectedMembers
                      selectedMembers={selectedMembers}
                      onRemoveSelectedMember={(i) => {
                      let newSelectedMembers = Array.from(selectedMembers);
                      newSelectedMembers.splice(i, 1);
                      if(newSelectedMembers.length < 1) {
                        resetModal();
                      }
                      setSelectedMembers(newSelectedMembers)
                      }}
                    />
                    <Box mb={2}>
                    <Select
                      options={[
                        {value: roleDictionnary.viewer, label: roleDictionnary.viewer},
                        {value: roleDictionnary.contributor, label: roleDictionnary.contributor},
                      ]}
                      defaultValue={{value: roleDictionnary.contributor, label: roleDictionnary.contributor}}
                      onChange={(option) => {setMembersRole(option.value)}}
                      menuPortalTarget={document.body} 
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                    </Box>
                  <Flex alignItems={'center'} >
                  <FormControl isInvalid={formAddMember.formState.errors?.people}>
                    <Input
                        placeholder='Add People'
                        {...formAddMember.register('people', {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                          }
                        })}
                      />
                    <FormErrorMessage>{formAddMember.formState.errors.people?.message}</FormErrorMessage>
                  </FormControl>
                  <IconButton
                      style={{
                        display: formAddMember.formState.errors.people ? 'none' : 'inherit',
                      }}
                      icon={<MdAdd />}
                      ml={2}
                      type={'submit'}
                      variant='purpleOutline'
                    />
                </Flex>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter>
          <Button
              variant="purpleOutline"
              ml={2}
              onClick={resetModal}
              disabled={addingMembers}
            >Back</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={addingMembers}
              onClick={addMembers}
            >Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={requestEditModal.isOpen}
        onClose={requestEditModal.onClose}
      >
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader>Request Edit</ModalHeader>
          <ModalBody p={3}  >
            { (owner && ownerEmail) && (
              <Box
              px={3}
              py={3} 
              border="1px"
              borderColor="divider.grey"
              backgroundColor="white"
              borderRadius="2px"
              >
                <Text mb={2} >A request will be sent to {owner.pseudo}</Text>
                <Flex>
                  <Box
                      mr={2}
                  >
                    <ProfileProvider 
                       profileId={owner.id}
                       originalProfile={owner}>
                      <ProfileThumbnail size={40} />
                    </ProfileProvider>
                  </Box>
                  <Box>
                    <Text>{owner.pseudo}</Text>
                    <Text textStyle="subtitle" >{ownerEmail}</Text>
                  </Box>
                </Flex>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="purpleOutline"
              ml={2}
              onClick={requestEditModal.onClose}
            >Cancel</Button>
            <Button
              variant="purpleFill"
              ml={2}
              isLoading={isSendingRequest}
              disabled={isSendingRequest}
              onClick={sendEditRequest}
            >Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Flex>
    )}
    </>
  )
}