import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, Flex, Icon, Menu, MenuButton, Portal, MenuList, MenuItem, IconButton, useDisclosure, Box, Button, Tooltip } from '@chakra-ui/react';
import { MdDevices, MdDragIndicator, MdFace, MdMoreVert, MdOutlineLayers } from 'react-icons/md';
import InsightTags from '@/insight/components/insightTags';
import { ProductPageContext } from '../lib/productPageContext';
import useExperienceApi from '@/experiences/lib/useExperienceApi';
import { ProductContext } from '../../lib/productContext';
import ExperienceGroupInProductNameForm from './experienceGroupInProductNameForm';
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/modal';
import usePersonaApi from '@/persona/lib/usePersonaApi';
import useProductsApi from '../../lib/useProductsApi';
import ProductProvider from '../../lib/productProvider';
import PersonaProvider from '@/persona/lib/personaProvider';
import PersonaThumbnail from '@/persona/components/personaThumbnail';
import ProductLogo from '../../components/productLogo';
import { useNavigate } from 'react-router-dom';

export default function ExperienceGroupInProductListItem(props) {

  const {
    productId,
    rights,
  } = useContext(ProductContext);

  const {
    refreshExperienceList,
    refreshPersonaList,
  } = useContext(ProductPageContext);

  const {
    experienceGroup,
    dragHover = false
  } = props;

  const experienceApi = useExperienceApi();
  const navigate = useNavigate();

  const [personaList, setPersonaList] = useState(false);
  const [productList, setProductList] = useState(false);
  const personaApi = usePersonaApi();
  const productApi = useProductsApi();
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalEditGroupName = useDisclosure();
  const ref1 = useRef();

  const fetchData = async () => {
    try {
      const newPersonaList = await personaApi.fetchManyPersonaByProductExperienceGroupId(experienceGroup.id);
      setPersonaList(newPersonaList);
      const newProductList = await productApi.fetchProductsByProductExperienceGroupId(experienceGroup.id);
      setProductList(newProductList);
    } catch (e) {
      console.error(e)
    }
  };

  const ungroup = async () => {
    try {
      await experienceApi.ungroupExperienceGroupInProduct(experienceGroup.id)
      await Promise.all[
        refreshExperienceList(),
        refreshPersonaList()
      ]
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (experienceGroup) {
      fetchData()
    }
  }, [experienceGroup]);

  return (
    <>
      <Flex
        px={3}
        py={2}
        backgroundColor={dragHover ? "background.purple" : "white"}
        role={'group'}
        _hover={{
          background: "background.grey",
        }}
        height={"84px"}
        position={"relative"}
        justifyContent={"space-between"}
      >
        {rights.can_update && (
          <Flex
            position={"absolute"}
            top="0"
            left={"0"}
            height={"100%"}
            opacity={0}
            _groupHover={{ opacity: '1' }}
            cursor={"grab"}
            py={2}
            pr={3}
          >
            <Icon
              as={MdDragIndicator}
              boxSize={'20px'}
              color={"text.light.grey"}
              mr={2}
            />
          </Flex>
        )}
        <Flex flexDirection={"column"}>
          <Flex
            alignItems={"center"}
            mb={1}
          >
            <Icon
              as={MdOutlineLayers}
              boxSize={'15px'}
              mr={2}
              color="text.medium.purple"
            />
            <Text
              noOfLines={2}
              color="text.medium.purple"
              fontWeight={"500"}>{experienceGroup.name}</Text>
          </Flex>
          <Text
            fontSize={"12px"}
            color="text.medium.grey"
          >{experienceGroup.experience_count} Experience{(experienceGroup.experience_count > 0 ? 's' : '')}</Text>
        </Flex>
        <Flex alignItems={"center"}>
          <Flex flexDirection={"column"}>
          <InsightTags
            nbBenefits={experienceGroup.positive_insight_count}
            nbFrustrations={experienceGroup.negative_insight_count}
            nbNeeds={experienceGroup.improvement_insight_count}
            nbBehavior={experienceGroup.behavior_insight_count}
            nbMotivation={experienceGroup.motivation_insight_count}
          />
          <Flex
            mt={3}
            alignSelf={"flex-end"}
          >
            {(personaList && personaList.length > 0) && (
              <Flex
                alignItems={"center"}
                mr={3}
              >
                <Icon
                  as={MdFace}
                  size="20"
                  color="text.medium.grey"
                />
                {personaList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Flex
                      ml={2}
                    >
                      <PersonaProvider
                        personaId={p.id}
                        originalPersona={p}
                      >
                        <PersonaThumbnail
                          size={20}
                          hasAcronym={true}
                        />
                      </PersonaProvider>
                    </Flex>
                  </Tooltip>
                ))}
              </Flex>
            )}
            {(productList && productList.length > 0) && (
              <Flex alignItems={"center"}>
                <Icon
                  as={MdDevices}
                  size="20"
                  color="text.medium.grey"
                />
                {productList && productList.map((p, i) => (
                  <Tooltip
                    label={p.name}
                    key={i}
                  >
                    <Flex
                      ml={2}
                    >
                      <ProductProvider
                        productId={p.id}
                        orignalProduct={p}
                      >
                        <ProductLogo
                          size={20}
                          hasAcronym={true}
                        />
                      </ProductProvider>
                    </Flex>
                  </Tooltip>
                ))}
              </Flex>
            )}
          </Flex>
          </Flex>
          {rights.can_update && (
            <Flex
              height={"100%"}
              pl={3}
              alignItems={"center"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Menu >
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={IconButton}
                      icon={<MdMoreVert />}
                      fontSize={"18px"}
                      size="md"
                      variant='greyTransparent'
                    />
                    <Portal>
                      <MenuList zIndex="popover">
                        <MenuItem
                          onClick={modalEditGroupName.onOpen}
                        >Rename</MenuItem>
                        <MenuItem onClick={(e) => {
                          navigate(`/products/${productId}?group-id=${experienceGroup.id}`);
                        }} >Go to Group</MenuItem>
                        <MenuItem
                          onClick={ungroup}
                        >Ungroup</MenuItem>
                      </MenuList>
                    </Portal>
                  </>
                )}
              </Menu>
            </Flex>
          )}
        </Flex>
        <Modal
          isOpen={modalEditGroupName.isOpen}
          onClose={modalEditGroupName.onClose}
        >
          <ModalOverlay />
          <ModalContent >
            <ModalHeader>Edit Group Name</ModalHeader>
            <ModalBody >
              <Box p={3}>
                <ExperienceGroupInProductNameForm
                  hideSave={true}
                  groupId={experienceGroup.id}
                  group={experienceGroup}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    Promise.all([
                      refreshExperienceList(),
                      refreshPersonaList()
                    ])
                    setIsAdding(false);
                    modalEditGroupName.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
            <Button
              variant='purpleOutline'
              onClick={modalEditGroupName.onClose}
            >Close</Button>
              <Button
                variant="purpleFill"
                ml={2}
                isLoading={isAdding}
                isDisabled={!isValid}
                onClick={() => {
                  ref1.current.click();
                }}>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}
