'use client'

import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Flex} from '@chakra-ui/layout';
import {FormControl, Textarea, FormErrorMessage} from '@chakra-ui/react';
import {IconButton} from '@chakra-ui/button';
import {MdCheck, MdClose} from 'react-icons/md';
import {forwardRef} from '@chakra-ui/system';
import useInsightApi from '../lib/useInsightApi';
import { InsightContext } from '../lib/insightContext';

const InsightQuoteForm = forwardRef((props, ref) => {

  const {
    insight,
    refresh
  } = useContext(InsightContext);
  
  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel,
    insightIndex
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm();

  const insightApi = useInsightApi();

  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const quote = data.quote;
      if(insight.id) {
        const newQuotes = Array.from(insight.quotes)
        if(insightIndex) {
          if(quote) {
            newQuotes[parseInt(insightIndex)] = quote;
          } else {
            newQuotes.splice(insightIndex, 1)
          }
        } else {
          if(quote) {
            newQuotes.push(quote);
          }
        }
        await insightApi.updateQuotes(newQuotes, insight.id);
        await refresh();
      }
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(insightIndex) {
      setValue('quote', insight.quotes[insightIndex]);
    }
  }, [insight.quotes]);

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Flex
        flex="1"
        w="100%"
        >
        <form
          display="flex"
          style={{
            width:"100%"
          }}
          onSubmit={handleSubmit(onSubmit)}
          >
          <Flex
            alignItems={'center'}
            w="100%"
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.quote}>
            <Textarea
              flex={"1"}
              height={"150px"}
              placeholder='Quote'
              {...register('quote')}
            />
            <FormErrorMessage>{errors && errors.quote}</FormErrorMessage>
          </FormControl>
            <Flex gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
                )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                variant="orangeOutline"
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
});

export default InsightQuoteForm;


