import React from 'react';
import { supabase } from '../../supabase.client';
import resizeImageWithRatio from '../../lib/resizeImageWithRatio';


const useInsightApi = () => {
 
  const searchInsights = async (searchTerm) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .select('*')
      .or(`description.ilike.%${searchTerm}%,name.ilike.%${searchTerm}%`)
      if (error) {
        throw (error)
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInsight = async (id) => {
    try {
      const { data } = await supabase.from("insight")
        .select(`*`)
        .eq('id', id)
        .single();
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  const fetchAllMyActiveInsightByType = async (type = 'negative') => {
    try {
      const { data, error } = await supabase.rpc('get_all_my_active_insight_by_type', {
        arg_insight_type: type
      });
      if (data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateName = async (name, insightId) => {
    try {
      const result = await supabase.from('insight')
        .update({ name })
        .eq('id', insightId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateQuotes = async (quotes, insightId) => {
    try {
      const result = await supabase.from('insight')
        .update({ quotes })
        .eq('id', insightId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updateOrder = async (order, insightId) => {
    try {
      const { error } = await supabase.from('insight')
        .update({ order })
        .eq('id', insightId)
        .select();
      if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadImages = async (insightId, images) => {
    try {
      await deleteImages(insightId);
      const promises = [];
      images.forEach((image, i) => {
        promises.push(
          supabase.storage.from('insight')
            .upload(`/images/${insightId}/${i + 1}x512.png`, image[512], { upsert: true }),
          supabase.storage.from('insight')
            .upload(`/images/${insightId}/${i + 1}x1024.png`, image[1024], { upsert: true }),
          supabase.storage.from('insight')
            .upload(`/images/${insightId}/${i + 1}xoriginal.png`, image['originalImage'], { upsert: true })
        )
      });
      const result = await Promise.all(promises);
      if (result) {
        const insightResult = await supabase.from('insight')
          .update({
            image_count: images.length,
            images_updated_at: new Date().toISOString()
          })
          .eq('id', insightId)
          .select();
        if (insightResult.error) {
          throw insightResult.error;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const deleteImages = async (insightId) => {
    try {
      const insightResult = await supabase.from('insight')
        .select('image_count')
        .eq('id', insightId)
        .single();
      if (insightResult.error) {
        throw insightResult.error;
      } else {
        const imageCount = insightResult.data.image_count;
        const promises = [];
        for (let i = 1; i <= imageCount; i++) {
          promises.push(
            supabase.storage
              .from('insight')
              .remove(`images/${insightId}/${i}x512.png`),
            supabase.storage
              .from('insight')
              .remove(`images/${insightId}/${i}x1024.png`),
            supabase.storage
              .from('insight')
              .remove(`images/${insightId}/${i}xoriginal.png`)
          )
        }
       const resultRemove = await Promise.all(promises);
       if(resultRemove.error) {
        throw resultRemove.error
       } else {
        await supabase.from('insight')
        .update({ image_count: 0 })
        .eq('id', insightId)
        .select();
       }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const migrateThumbnailToImages = async (insightId) => {
    try {
      const resultInsight = await supabase.from('insight')
      .select(`thumbnails`)
      .eq('id', insightId)
      .select()
      .single();
      if(resultInsight.error) {
        throw resultInsight.error
      } else {
        const insight = resultInsight.data;
        if(insight.has_thumbnail) {
          const originalThumbnail = await downloadThumbnail(insightId, 'original')
          const originalThumbnailBlob = await fetch(originalThumbnail)
            .then(response => response.blob()) 
            .then(blob => blob)
          const image1024 =  await resizeImageWithRatio(originalThumbnail, 1024) 
          const image512 = await resizeImageWithRatio(originalThumbnail, 512)          
          await uploadImages(insightId, [{
            originalImage: originalThumbnailBlob,
            1024: image1024,
            512: image512
          }])
          await deleteThumbnail(insightId);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadImages = async (insightId, size = 1024) => {
    try {
      const { data, error } = await supabase.from('insight')
        .select(`image_count, images_updated_at`)
        .eq('id', insightId)
        .single();
      if (error) {
        throw error;
      } else {
        const promises = [];
        for (let i = 1; i <= data.image_count; i++) {
          promises.push(
            await supabase.storage
              .from('insight')
              .download(`/images/${insightId}/${i}x${size}.png?updated_at=${data.images_updated_at}`)
          )
        }
        const results = await Promise.all(promises);
        const images = results.map((r) => {
          if(r.error) {
            throw error;
          }
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(r.data)
        })
        return images;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadThumbnail = async (files, insightId) => {
    try {
      if (files[1024]) {
        await supabase.storage
          .from('insight')
          .upload(`/thumbnails/${insightId}x1024.png`, files[1024], {
            upsert: true
          });
      }
      if (files['original']) {
        await supabase.storage
          .from('insight')
          .upload(`/thumbnails/${insightId}xoriginal.png`, files['original'], {
            upsert: true
          });
      }
      await supabase.from('insight')
        .update({
          has_thumbnail: true,
          thumbnail_updated_at: new Date().toISOString()
        })
        .eq('id', insightId)
        .select();
    } catch (e) {
      console.error(e);
    }
  }

  const deleteThumbnail = async (insightId) => {
    try {
      await supabase.storage
        .from('insight')
        .remove(`/thumbnails/${insightId}x1024.png`);
      await supabase.storage
        .from('insight')
        .remove(`/thumbnails/${insightId}xoriginal.png`);
      await supabase.from('insight')
        .update({ has_thumbnail: false })
        .eq('id', insightId)
        .select();
    } catch (e) {
      console.error(e);
    }
  }

  const downloadThumbnail = async (insightId, size = 1024) => {
    try {
      const resultUpdatedAt = await supabase.from('insight')
        .select()
        .eq('id', insightId)
        .select()
        .single();
      if (resultUpdatedAt.data) {
        const { data, error } = await supabase.storage
          .from('insight')
          .download(`/thumbnails/${insightId}x${size}.png?updated_at=${resultUpdatedAt.data.thumbnail_updated_at}`);
        if (data) {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(data);
        } else {
          throw error;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsightListByExperienceId = async (experienceId) => {
    try {
      const query = supabase
        .from('insight')
        .select()
        .eq('experience_id', experienceId)
        .order('order');
      const { data, error } = await query;
      if (data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsightListByExperienceIdAndPersonaId = async (experienceId, personaId, options = {}) => {
    try {
      let { data, error } = await supabase.rpc('get_insight_list_by_experience_id_and_persona_id', {
        arg_experience_id: experienceId,
        arg_persona_id: personaId,
        arg_type: options.type,
        arg_archived: options.archived
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsightListByExperienceIdAndProductId = async (experienceId, productId, options = {}) => {
    try {
      let { data, error } = await supabase.rpc('get_insight_list_by_experience_id_and_product_id', {
        arg_experience_id: experienceId,
        arg_product_id: productId,
        arg_type: options.type,
        arg_archived: options.archived
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsightListByPersonaId = async (personaId, options = {}) => {
    try {
      let { data, error } = await supabase.rpc('get_insight_list_by_persona_id', {
        arg_persona_id: personaId,
        arg_type: options.type,
        arg_archived: options.archived
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsightListByProductId = async (productId, options = {}) => {
    try {
      let { data, error } = await supabase.rpc('get_insight_list_by_product_id', {
        arg_product_id: productId,
        arg_type: options.type,
        arg_archived: options.archived
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const insertInsight = async (experienceId, type, name, applicableToAllPersona, applicableToAllProducts, assumption, importance) => {
    try {
      const { data, error } = await supabase.from('insight')
        .insert({
          experience_id: experienceId,
          type,
          name,
          applicable_to_all_persona : applicableToAllPersona,
          applicable_to_all_products: applicableToAllProducts,
          assumption,
          importance
        })
        .select()
        .single();
      if (data) {
        return data;
      } else {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUsersRights = async (experienceId, userId) => {
    try {
      const promiseArray = [
        supabase.rpc('can_user_update_insight', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
        supabase.rpc('can_user_select_insight', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
        supabase.rpc('can_user_delete_insight', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
      ]
      const result = await Promise.all(promiseArray);
      return {
        can_update: result[0].data,
        can_select: result[1].data,
        can_delete: result[2].data,
      }
    } catch (error) {
      console.error(error);
    }
  }

  const updateApplicableToAllPersona = async (
    insightId,
    applicableToAllPersona,
    personaIds = null
  ) => {
    try {
      if (applicableToAllPersona) {
        await unlinkAllPersonaFromInsight(insightId);
        const result = await supabase.from('insight')
          .update({ 'applicable_to_all_persona': applicableToAllPersona })
          .eq('id', insightId)
          .select();
      } else {
        if (!personaIds) {
          throw 'A persona list should be pro';
        } else {
          await unlinkAllPersonaFromInsight(insightId);
          await supabase.from('insight')
            .update({ 'applicable_to_all_persona': applicableToAllPersona })
            .eq('id', insightId)
            .select();
          await linkManyPersonaToInsight(insightId, personaIds)
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  const updateApplicableToAllProducts = async (
    insightId,
    applicableToAllProducts,
    productIds = null
  ) => {
    try {
      if (applicableToAllProducts) {
        await unlinkAllProductsFromInsight(insightId);
        const result = await supabase.from('insight')
          .update({ 'applicable_to_all_products': applicableToAllProducts })
          .eq('id', insightId)
          .select();
      } else {
        if (!productIds) {
          throw 'A product list should be provided';
        } else {
          await unlinkAllProductsFromInsight(insightId);
          await supabase.from('insight')
            .update({ 'applicable_to_all_products': applicableToAllProducts })
            .eq('id', insightId)
            .select();
          await linkManyProductsToInsight(insightId, productIds)
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  const archiveInsight = async (insightId) => {
    return supabase
      .from('insight')
      .update({
        archived: true
      })
      .eq('id', insightId);
  };

  const unarchiveInsight = async (insightId) => {
    return supabase
      .from('insight')
      .update({
        archived: false
      })
      .eq('id', insightId);
  };

  const deleteInsight = async (insightId) => {
    try {
      const result1 = await deleteImages(insightId);
      let { data, error } = await supabase.rpc('delete_insight',
        { arg_insight_id: insightId });
      if (error) {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const linkManyPersonaToInsight = async (insightId, personaIds) => {
    try {
      const insightPersona = personaIds.map(p => ({
        insight_id: insightId,
        persona_id: p
      }));
      await supabase
        .from('insight_persona')
        .insert(insightPersona).select();
    } catch (e) {
      console.error(e)
    }
  };

  const unlinkAllPersonaFromInsight = async (insightId) => {
    try {
      await supabase
        .from('insight_persona')
        .delete()
        .eq('insight_id', insightId);
    } catch (e) {
      console.error(e)
    }
  }


  const unlinkAllProductsFromInsight = async (insightId) => {
    try {
      await supabase
        .from('insight_product')
        .delete()
        .eq('insight_id', insightId);
    } catch (e) {
      console.error(e)
    }
  }

  const linkManyProductsToInsight = async (insightId, productIds) => {
    try {
      const insightProduct = productIds.map(p => ({
        insight_id: insightId,
        product_id: p
      }));
      const { data, error } = await supabase
        .from('insight_product')
        .insert(insightProduct)
        .select();
      if (error) {
        throw error
      }
    } catch (e) {
      console.error(e)
    }
  };

  const moveToExperience = async(insightId, experienceId) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .update({experience_id: experienceId})
      .eq('id', insightId)
      .select();
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setAsFact = async(insightId) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .update({assumption: false})
      .eq('id', insightId)
      .select();
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setAsAssumption = async(insightId) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .update({assumption: true})
      .eq('id', insightId)
      .select();
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setType = async(insightId, type) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .update({type})
      .eq('id', insightId)
      .select();
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setImportance = async(insightId, importance) => {
    try {
      const { data, error } = await supabase
      .from('insight')
      .update({importance})
      .eq('id', insightId)
      .select();
      if(error) {
        throw error;
      }
    } catch (error) {
      console.error(error)
    }
  }


  return {
    searchInsights,
    fetchInsightListByPersonaId,
    fetchInsightListByExperienceId,
    fetchInsightListByExperienceIdAndPersonaId,
    fetchInsightListByExperienceIdAndProductId,
    fetchInsightListByProductId,
    fetchAllMyActiveInsightByType,
    insertInsight,
    deleteThumbnail,
    updateName,
    updateOrder,
    updateQuotes,
    updateApplicableToAllPersona,
    updateApplicableToAllProducts,
    fetchInsight,
    archiveInsight,
    unarchiveInsight,
    fetchUsersRights,
    deleteInsight,
    uploadImages,
    downloadImages,
    migrateThumbnailToImages,
    deleteImages,
    uploadThumbnail,
    downloadThumbnail,
    unlinkAllPersonaFromInsight,
    unlinkAllProductsFromInsight,
    linkManyPersonaToInsight,
    linkManyProductsToInsight,
    moveToExperience,
    setAsFact,
    setAsAssumption,
    setType,
    setImportance
  }
};

export default useInsightApi;
