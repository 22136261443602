import {supabase} from '../../supabase.client';
import supabaseErrorCodeDictionnary from '@/lib/supabaseErrorCodeDictionnary';

const useExperienceApi = () => {

  const searchExperiences = async (searchTerm) => {
    try {
      const { data, error } = await supabase
      .from('experiences')
      .select('*')
      .ilike('objective', `%${searchTerm}%`);
      if (error) {
        throw (error)
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchPersonaExperienceGroups = async (searchTerm) => {
    try {
      const { data, error } = await supabase
      .from('persona_experience_group')
      .select('*')
      .ilike('name', `%${searchTerm}%`);
      if (error) {
        throw (error)
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchProductExperienceGroups = async (searchTerm) => {
    try {
      const { data, error } = await supabase
      .from('product_experience_group')
      .select('*')
      .ilike('name', `%${searchTerm}%`);
      if (error) {
        throw (error)
      } else {
        return data
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const insertExperience = async (objective) => {
    try {
      const {data, error} = await supabase
        .from('experiences')
        .insert({objective})
        .select()
        .single();
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const insertExperienceGroupInProduct = async (name, productId) => {
    try {
      const {data, error} = await supabase
        .from('product_experience_group')
        .insert({name, product_id: productId})
        .select()
        .single();
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const moveExperienceToGroupInProduct = async (productId, experienceId, groupId) => {
    try {
      let {data, error} = await supabase.rpc('move_experience_to_group_in_product', {
        arg_product_id: productId,
        arg_experience_id: experienceId,
        arg_group_id: groupId
      });
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateExperienceGroupNameInProduct = async (name, groupId) => {
    try {
      const {data, error} = await supabase
      .from('product_experience_group')
      .update({name,})
      .eq('id', groupId)
      .select()
      .single();
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const ungroupExperienceGroupInProduct = async (groupId) => {
    try {
      let {data, error} = await supabase.rpc('ungroup_product_experience_group', {
        arg_group_id: groupId
      });
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const insertExperienceGroupInPersona = async (name, personaId) => {
    try {
      const {data, error} = await supabase
        .from('persona_experience_group')
        .insert({name, persona_id: personaId})
        .select()
        .single();
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const moveExperienceToGroupInPersona = async (personaId, experienceId, groupId) => {
    try {
      let {data, error} = await supabase.rpc('move_experience_to_group_in_persona', {
        arg_persona_id: personaId,
        arg_experience_id: experienceId,
        arg_group_id: groupId
      });
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateExperienceGroupNameInPersona = async (name, groupId) => {
    try {
      const {data, error} = await supabase
      .from('persona_experience_group')
      .update({name,})
      .eq('id', groupId)
      .select()
      .single();
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const ungroupExperienceGroupInPersona = async (groupId) => {
    try {
      let {data, error} = await supabase.rpc('ungroup_persona_experience_group', {
        arg_group_id: groupId
      });
      if(data) {
        return data; 
      } else if (error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUsersRights = async (experienceId, userId) => {
    try {
      const promiseArray = [
        supabase.rpc('can_user_update_experience', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
        supabase.rpc('can_user_select_experience', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
        supabase.rpc('can_user_delete_experience', {
          arg_experience_id: experienceId,
          arg_user_id: userId
        }),
      ]
      const result =  await Promise.all(promiseArray);
      return {
        can_update : result[0].data,
        can_select : result[1].data,
        can_delete : result[2].data,
      }
    } catch (error) {
      console.error(error);
    }
  }

  const updateObjective = async (objective, experienceId) => {
    try {
      const result =  await supabase.from('experiences')
        .update({objective})
        .eq('id', experienceId)
        .select();
    } catch (e) {
      console.error(e);
    }
  };

  const updatePrototypeLink = async (link, experienceId) => {
    try {
      const response = await supabase.from('experiences')
        .update({prototype_link: link})
        .eq('id', experienceId);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperiencesInPersonaAndInProductPersonaForUser =  async (personaId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_in_persona_and_in_products_persona_for_user',
        { arg_persona_id: personaId});
        if(error) {
          throw error
        } else if (data) {
          return data
        }
    } catch (e) {
      console.error(e)
    }
  };

  const fetchExperiencesInProductsAndInPersonaProductForUser =  async (productId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_in_product_and_in_persona_products_for_user',
        { arg_product_id: productId});
        if(error) {
          throw error
        } else if (data) {
          return data
        }
    } catch (e) {
      console.error(e)
    }
  };
  

  const fetchExperiencesNotInPersonaButInProductPersonaForUser =  async (personaId, userId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_not_in_persona_but_in_products_persona_for_user',
        { arg_persona_id: personaId});
        if(error) {
          throw error
        } else if (data) {
          return data
        }
    } catch (e) {
      console.error(e)
    }
  };

  const fetchExperiencesNotInProductButInProductPersonaForUser =  async (productId, userId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_not_in_product_but_in_persona_products_for_user',
        { arg_product_id: productId});
        if(error) {
          throw error
        } else if (data) {
          return data
        }
    } catch (e) {
      console.error(e)
    }
  };

  const fetchExperiencesByIds =  async (ids) => {
    try {
      const query = supabase.from('experiences')
        .select()
        .in('id', ids)
      const {data} = await query;
      return data;
    } catch (e) {
      console.error(e)
    }
  };

  const fetchExperiences = async (options) => {
    try {
      let experiences;
      let result;
      let query;
      if (options.personaId) {
        query = supabase.from('persona')
          .select(`
          experiences(*)
          `)
          .eq('id', options.personaId);
         if(options.productId) {
           query.eq('experiences.product_id', options.productId)
         }
         query.single();
         result = await query;
          experiences = result.data.experiences;
      } else {
        query = supabase.from('experiences')
          .select(`
           *,
           insight (id),
           persona(*)
          `);
        if(options.productId) {
          query.eq('product_id', options.productId)
        }
        result = await query;
        experiences = result.data;
      }
      return experiences;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperiencesByProductIdAndPersonaId = async (productId, personaId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_by_product_id_and_persona_id',
        { arg_product_id: productId, arg_persona_id: personaId });
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperiencesByProductId = async (productId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_by_product_id',
        { arg_product_id: productId });
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };


  const fetchExperienceGroupsByProductId = async (productId) => {
    try {
      let { data, error } = await supabase.rpc('get_experience_groups_by_product_id',
        { arg_product_id: productId });
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperienceGroupsByPersonaId = async (personaId) => {
    try {
      let { data, error } = await supabase.rpc('get_experience_groups_by_persona_id',
        { arg_persona_id: personaId });
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperiencesByPersonaId = async (personaId) => {
    try {
      let { data, error } = await supabase.rpc('get_experiences_by_persona_id',
        { arg_persona_id: personaId });
      if(error) {
        throw error
      } else if (data) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchExperienceNotInPersona = async (personaId) => {
    try {
      let { data } = await supabase.rpc('get_experiences_not_in_persona',
        { arg_persona_id: personaId });
      return data;
    } catch (e) {
      console.error(e)
    }
  };

  const fetchExperience = async (id) => {
    try {
      const {data, error} = await supabase.from("experiences")
          .select(`*`)
        .eq('id', id)
        .single();
        if(error) {
          throw error
        } else if (data) {
          return data
        };
    } catch (e) {
      console.error(e);
    }
  };

  const fetchNumberOfPersonaAndProduct = async (experience_id) => {
    let { data, error} = await supabase.rpc('get_number_of_persona_and_product_in_experience', {
      arg_experience_id: experience_id
    });
    if(error) {
      throw error
    } else if (data) {
      return data
    }
  }

  const updateOrderInProduct = async (type, id, order) => {
    try {
      let {data, error} = await supabase.rpc('update_experience_and_group_order', {
        arg_type: type,
        arg_order: order,
        arg_id: id
      });
      if(error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateOrderInPersona = async (type, id, order) => {
    try {
      let query;
      if(type === ('experiences_persona')) {
        query = supabase.from('experiences_persona')
        .update({order})
        .eq('id', id);
      } else if(type === ('persona_experience_group')) {
        query = supabase.from('persona_experience_group')
        .update({order})
        .eq('id', id);
      }
      const {error, data} =  await query;
      if(error) {
        throw error
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteExperience = async (experienceId) => {
    try {
      await supabase
        .from('products_experiences')
        .delete()
        .eq('experience_id', experienceId);

      await supabase
        .from('experiences_persona')
        .delete()
        .eq('experience_id', experienceId);

      await supabase
        .from('insight')
        .delete()
        .eq('experience_id', experienceId);

      await supabase
        .from('experiences')
        .delete()
        .eq('id', experienceId);
    } catch (e) {
      console.error(e);
    }
  };

  const removeExperienceFromProduct = async (productExperienceId) => {
    try {
      const {data, error}  = await supabase
        .from('products_experiences')
        .delete()
        .eq('id', productExperienceId)
      if(error) {
        throw error;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const removeExperienceFromPersona = async (experiencePersonaId) => {
    try {
      const {data, error} = await supabase
        .from('experiences_persona')
        .delete()
        .eq('id', experiencePersonaId)
        if(error) {
          throw error;
        }
    } catch (e) {
      console.error(e);
    }
  };

  const unlinkPersonaFromExperience = async (experienceId, personaId) => {
    try {
      await supabase
        .from('experiences_persona')
        .delete()
        .eq('experience_id', experienceId)
        .eq('persona_id', personaId);
    } catch (e) {
      console.error(e);
    }
  };

  const linkManyPersonaToExperience = async (experienceId, personaIds, groupId) => {
    try {
      let experiencesPersona = personaIds.map(p => ({
        experience_id: experienceId,
        persona_id: p
      }));
      if(groupId) {
        experiencesPersona = experiencesPersona.map(ep => ({...ep, persona_experience_group_id: groupId}) )
      }
      await supabase
        .from('experiences_persona')
        .insert(experiencesPersona).select();
    } catch (e) {
      console.error(e)
    }
  };

  const linkProductsToExperience = async (experienceId, productIds, groupId) => {
    try {
      let experienceProductList = productIds.map((id) => {
        return {'product_id': id, 'experience_id': experienceId};
      });
      if(groupId) {
        experienceProductList = experienceProductList.map(ep => ({...ep, product_experience_group_id: groupId}) )
      }
      const {data, error} = await supabase.from('products_experiences')
      .insert(experienceProductList);
      if(error) {
        throw error
      } else if (data) {
        return data
      }
    } catch (e) {
      console.error(e);
    }
  };

  const unlinkProductFromExperience = async (experienceId, productId) => {
    try {
      await supabase
        .from('products_experiences')
        .delete()
        .eq('experience_id', experienceId)
        .eq('product_id', productId);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    searchExperiences,
    searchPersonaExperienceGroups,
    searchProductExperienceGroups,
    fetchExperience,
    fetchExperiences,
    fetchExperiencesInPersonaAndInProductPersonaForUser,
    fetchExperiencesInProductsAndInPersonaProductForUser,
    fetchExperiencesNotInPersonaButInProductPersonaForUser,
    fetchExperiencesNotInProductButInProductPersonaForUser,
    fetchExperiencesByProductIdAndPersonaId,
    fetchExperiencesByIds,
    fetchExperiencesByProductId,
    fetchExperienceGroupsByProductId,
    fetchExperienceGroupsByPersonaId,
    fetchExperiencesByPersonaId,
    fetchExperienceNotInPersona,
    updateOrderInProduct,
    updateOrderInPersona,
    deleteExperience,
    insertExperience,
    insertExperienceGroupInProduct,
    moveExperienceToGroupInProduct,
    ungroupExperienceGroupInProduct,
    updateExperienceGroupNameInProduct,
    insertExperienceGroupInPersona,
    moveExperienceToGroupInPersona,
    ungroupExperienceGroupInPersona,
    updateExperienceGroupNameInPersona,
    fetchUsersRights,
    updateObjective,
    linkManyPersonaToExperience,
    unlinkPersonaFromExperience,
    linkProductsToExperience,
    unlinkProductFromExperience,
    updatePrototypeLink,
    fetchNumberOfPersonaAndProduct,
    removeExperienceFromProduct,
    removeExperienceFromPersona
  }
};

export default useExperienceApi;
