import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { useContext, useState } from 'react';
import { PersonaContext } from '../lib/personaContext';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { MdOutlineWarning } from 'react-icons/md';
import usePersonaApi from '../lib/usePersonaApi';
import { useNavigate } from 'react-router-dom';

export default function DeletePersonaModal(props) {

  const { disclosure } = props;

  const {
    persona,
  } = useContext(PersonaContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const personaApi = usePersonaApi();
  const navigate = useNavigate();

  const deletePersona = async () => {
    try {
      setIsDeleting(true);
      await personaApi.deletePersona(persona.id);
      setIsDeleting(false);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      {disclosure && (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader>Delete Persona {persona && persona.name}</ModalHeader>
            <ModalBody >
              <Box
                p={3}
              >
                {(persona && persona.name) && (
                  <Flex
                    border="1px"
                    borderColor="divider.grey"
                    borderRadius="2px"
                    backgroundColor="white"
                    alignItems={'center'}
                    p={3}
                  >
                    <Icon
                      as={MdOutlineWarning}
                      color={'text.medium.red'}
                      boxSize={'18px'}
                      mr={3}
                    />
                    <Text textStyle="body" color={'text.medium.red'} >Are you sure to delete persona {persona.name}?</Text>
                  </Flex>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant='redOutline'
                onClick={disclosure.onClose}
              >Close</Button>
              <Button
                variant="redFill"
                ml={2}
                isLoading={isDeleting}
                onClick={deletePersona}>Delete</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

