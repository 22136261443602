'use client'

import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/button';
import { MdCheck, MdClose } from 'react-icons/md';
import { forwardRef } from '@chakra-ui/system';
import { Textarea } from '@chakra-ui/textarea';
import { ProductContext } from '../../lib/productContext';
import useProductsApi from '../../lib/useProductsApi';

const ProductValuePropositionForm = forwardRef((props, ref) => {

  const {
    product,
    productId,
    refresh,
  } = useContext(ProductContext);

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
    onCancel
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm();

  const productApi = useProductsApi();

  const onSubmit = async (data) => {
    try {
      if (onSubmitting) {
        onSubmitting();
      }
      const value_proposition = data.value_proposition;
      if (productId) {
        await productApi.updateValueProposition(value_proposition, productId);
        await refresh();
      }
      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (product.value_proposition) {
      setValue('value_proposition', product.value_proposition);
    }
  }, [product]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Flex
            alignItems={'flex-start'}
            flexDirection={'column'}
            gap={2}
          >
            <FormControl isInvalid={errors && errors.value_proposition}>
              <Textarea
                placeholder='User Objective… User Pain… User Gain'
                {...register('value_proposition', {})}
              />
              <FormErrorMessage>{errors && errors.value_proposition}</FormErrorMessage>
            </FormControl>
            <Flex display={"flex"} gap={2}>
              {onCancel && (
                <IconButton
                  variant="greyOutline"
                  icon={<MdClose />}
                  onClick={onCancel}
                />
              )
              }
              <IconButton
                style={{
                  display: hideSave ? 'none' : 'inherit',
                }}
                ref={ref}
                icon={<MdCheck />}
                type={'submit'}
                aria-label='check'
                variant='purpleOutline'
                isLoading={isSubmitting}
                isDisabled={!isValid}
              />
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
});

export default ProductValuePropositionForm;


