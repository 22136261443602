import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import useInsightApi from '../lib/useInsightApi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import { ExperienceContext } from '../../experiences/lib/experienceContext';
import useExperienceApi from '../../experiences/lib/useExperienceApi';
import { Controller, useForm } from 'react-hook-form';
import { MdCheck } from 'react-icons/md';
import { Select } from 'chakra-react-select';
import { useTheme } from '@emotion/react';

const InsightMoveToExperienceForm = forwardRef((props, ref) => {

  const {
    hideSave,
    onValidityChange,
    onSubmitted,
    onSubmitting,
  } = props;

  const {
    insight,
  } = useContext(InsightContext)

  const {
    experience,
    personaList,
    productList,
    refreshInsights
  } = useContext(ExperienceContext)

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isValid }
  } = useForm();

  const experienceApi = useExperienceApi();
  const [experiencesOptions, setExperiencesOptions] = useState();
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();
  const insightApi = useInsightApi();

  const fetchExperiences = async () => {
    try {
      const groupedOptions = [];
      const productPromises = productList.map(p => experienceApi.fetchExperiencesByProductId(p.id))
      const productExperiencesResult = await Promise.all(productPromises);
      const experiencesInProductsOptions = [];
      productExperiencesResult.forEach(experiences => {
        experiences.forEach((e) => {
          if(experiencesInProductsOptions.filter(o => o.value === e.id ).length === 0 && e.id !== experience.id) {
            experiencesInProductsOptions.push({
              label: e.objective,
              value: e.id,
            });
          }
        })
      });
      const personaPromises = personaList.map(p => experienceApi.fetchExperiencesByPersonaId(p.id));
      const personaPromiseResult = await Promise.all(personaPromises);
      const experiencesInPersonasOptions = [];
      personaPromiseResult.forEach(experiences => {
        experiences.forEach((e) => {
          if(experiencesInPersonasOptions.filter(o => o.value === e.id).length === 0 && e.id !== experience.id) {
            experiencesInPersonasOptions.push({
              label: e.objective,
              value: e.id,
            });
          }
        })
      });
      groupedOptions.push({
        label: 'Experiences in Products',
        options: experiencesInProductsOptions
      }, {
        label: 'Experiences in Personas',
        options: experiencesInPersonasOptions
      })
    setExperiencesOptions(groupedOptions);
    } catch (error) {
      console.error(error)
    }
  };


  const onSubmit = async (data) => {
    try {
      if(onSubmitting) {
        onSubmitting();
      }
      const experienceId = data.experience.value;
      await insightApi.moveToExperience(insight.id, experienceId)
      await refreshInsights();
      if(onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if(!experiencesOptions) {
      fetchExperiences();
    }
  }, [experiencesOptions])

  useEffect(() => {
    if(onValidityChange) {
      onValidityChange(isValid);
    }
  }, [isValid]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Box
            alignItems={'center'}
          >
            <Controller
              control={control}
              name="experience"
              rules={{required: 'An experience is required.'}}
              render={({
                         field: { onChange, onBlur, value, objective, ref },
                         fieldState: { invalid, error }
                       }) => (
                <FormControl isInvalid={errors && errors.experience} mb={3}>
                  <FormLabel>Select an Experience</FormLabel>
                  <Select
                   chakraStyles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? (theme.colors.text.disable.grey + ' !important') : provided.backgroundColor,
                      color: (theme.colors.text.dark.grey + ' !important'),
                    }),
                    }}
                    options={experiencesOptions}
                    onInputChange={(value) => setInputValue(value)} 
                    getOptionLabel={(option) => {
                      return (option.value === -1 ? option.label +  (inputValue ? ` "${inputValue}"` : ''): option.label)
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                  <FormErrorMessage>{errors && errors.experience}</FormErrorMessage>
                </FormControl>
              )}
            />
            <IconButton
              style={{
                display: hideSave ? 'none' : 'inherit',
              }}
              ref={ref}
              icon={<MdCheck />}
              ml={2}
              type={'submit'}
              aria-label='check'
              variant='primary'
              isLoading={isSubmitting}
              isDisabled={!isValid}
            />
          </Box>
        </form>
      </Box>
    </>
  );
});


export default InsightMoveToExperienceForm;