import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { MenuGroup, Skeleton, Tag, Tooltip } from '@chakra-ui/react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp, MdOutlineToggleOn } from 'react-icons/md';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react'
import { InsightContext } from '../lib/insightContext';
import { RiEqualFill } from 'react-icons/ri';
import useInsightApi from '../lib/useInsightApi';

export default function InsightAttributesTags(props) {

  const {onChange} = props;

  const {
    insight,
    rights,
    refresh
  } = useContext(InsightContext)

  const insightApi = useInsightApi();
  const [importanceLoading, setImportanceLoading] = useState(false);
  const [assumptionLoading, setAssumptionLoading] = useState(false);

  const setImportance = async (improtance) => {
    try {
      setImportanceLoading(true);
      await insightApi.setImportance(insight.id, improtance)
      await refresh();
      setImportanceLoading(false);
      if(onChange) {
        onChange();
      }
    } catch (error) {
      console.error(error)
    }
  };

  const setAsFact = async () => {
    try {
      setAssumptionLoading(true);
      await insightApi.setAsFact(insight.id)
      await refresh();
      setAssumptionLoading(false);
      if(onChange) {
        onChange();
      }
    } catch (error) {
      console.error(error)
    }
  };

  const setAsAssumption = async () => {
    try {
      setAssumptionLoading(true);
      await insightApi.setAsAssumption(insight.id)
      await refresh();
      setAssumptionLoading(false);
      if(onChange) {
        onChange();
      }
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <> {insight && (
      <Flex alignItems={"center"} gap={1}>
        {(insight.importance && importanceLoading) &&
          <Skeleton height='24px' width="34px" borderRadius={"50px"} />
        }
        {(insight.importance && !importanceLoading) &&
          <Menu >
            {({ isOpen }) => (
              <>
                {(insight.importance === 'highest' || insight.importance === 'high') && (
                  <Tooltip label="Importance">
                  <MenuButton
                    cursor="pointer"
                    as={Tag}
                    variant="red"
                    pointerEvents={rights.can_update ? 'inherit' : 'none'}
                  ><Flex alignItems={"center"}><Icon as={(insight.importance === 'high' ? MdOutlineKeyboardArrowUp : MdOutlineKeyboardDoubleArrowUp)} /></Flex></MenuButton>
                  </Tooltip>
                )}
                {(insight.importance === 'medium' || insight.importance === 'medium') && (
                  <MenuButton
                    cursor="pointer"
                    as={Tag}
                    variant="yellow"
                    pointerEvents={rights.can_update ? 'inherit' : 'none'}
                  ><Flex alignItems={"center"}><Icon as={RiEqualFill} /></Flex></MenuButton>
                )}
                {(insight.importance === 'low' || insight.importance === 'lowest') && (
                  <MenuButton
                    cursor="pointer"
                    as={Tag}
                    variant="blue"
                    pointerEvents={rights.can_update ? 'inherit' : 'none'}
                  ><Flex alignItems={"center"}><Icon as={(insight.importance === 'low' ? MdOutlineKeyboardArrowDown : MdOutlineKeyboardDoubleArrowDown)} /></Flex></MenuButton>
                )}
                <MenuList>
                  <MenuGroup title='Importance'>
                    <MenuItem onClick={(e) => {
                      setImportance('highest')
                    }}>
                      <Icon as={MdOutlineKeyboardDoubleArrowUp} mr={2} color="text.medium.red" />
                      <Text color="text.medium.red">Highest</Text>
                    </MenuItem>
                    <MenuItem onClick={(e) => {
                      setImportance('high')
                    }}>
                      <Icon as={MdOutlineKeyboardArrowUp} mr={2} color="text.medium.red" />
                      <Text color="text.medium.red">High</Text>
                    </MenuItem>
                    <MenuItem onClick={(e) => {
                      setImportance('medium')
                    }}>
                      <Icon as={RiEqualFill} mr={2} color="text.medium.yellow" />
                      <Text color="text.medium.yellow">Medium</Text>
                    </MenuItem>
                    <MenuItem onClick={(e) => {
                      setImportance('low')
                    }}>
                      <Icon as={MdOutlineKeyboardArrowDown} mr={2} color="text.medium.blue" />
                      <Text color="text.medium.blue">Low</Text>
                    </MenuItem>
                    <MenuItem onClick={(e) => {
                      setImportance('lowest')
                    }}>
                      <Icon as={MdOutlineKeyboardDoubleArrowDown} mr={2} color="text.medium.blue" />
                      <Text color="text.medium.blue">Lowest</Text>
                    </MenuItem>
                  </MenuGroup>

                </MenuList>
              </>
            )}
          </Menu>
        }
        {(insight.assumption && assumptionLoading) &&
          <Skeleton height='24px' width="34px" borderRadius={"50px"} />
        }
        {(insight.assumption && !assumptionLoading) &&
          <Menu >
            {({ isOpen }) => (
              <>
                <MenuButton
                  cursor="pointer"
                  as={Tag}
                  pointerEvents={rights.can_update ? 'inherit' : 'none'}
                >Assum.</MenuButton>
                <MenuList>
                  <MenuItem onClick={setAsFact}>
                    <Icon
                      as={MdOutlineToggleOn}
                      boxSize={'14px'}
                      mr={2}
                    />
                    Set as Fact</MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        }
        {!insight.assumption &&
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  cursor="pointer"
                  as={Tag}
                  pointerEvents={rights.can_update ? 'inherit' : 'none'}
                >Fact</MenuButton>
                <MenuList>
                  <MenuItem onClick={setAsAssumption}>
                    <Icon
                      as={MdOutlineToggleOn}
                      boxSize={'14px'}
                      mr={2}
                    />
                    Set as Assumption</MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        }
      </Flex>
    )}
    </>
  );
}
