'use client'

import React, { useRef, useState, useContext } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import PersonaNameForm from './personaNameForm';
import { PersonaContext } from '../../lib/personaContext';
import DeletePersonaModal from '../../components/deletePersonaModal';
import PersonaBioForm from './personaBioForm';
import PersonaQuoteForm from './personaQuoteForm';
import PersonaAttributesForm from './personaAttributesForm';

export default function PersonaAsideEditMenu(props) {

  const {
    persona,
    rights,
  } = useContext(PersonaContext);
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalEditName = useDisclosure();
  const modalEditBio = useDisclosure();
  const modalEditQuote = useDisclosure();
  const modalEditAttributes = useDisclosure();
  const modalDelete = useDisclosure();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  return (
    <>
      {(persona && rights.can_update) && (
        <>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={IconButton}
                  icon={<MdMoreHoriz />}
                  variant='blueOutline'
                />
                <MenuList>
                  <MenuItem onClick={() => {
                    modalEditName.onOpen();
                  }}>
                    {persona.name ? 'Edit Name' : 'Add Name'}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    modalEditBio.onOpen();
                  }}>
                    {persona.bio ? 'Edit Bio' : 'Add Bio'}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    modalEditQuote.onOpen();
                  }}>
                    {persona.quote ? 'Edit Quote' : 'Add Quote'}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    modalEditAttributes.onOpen();
                  }}>
                    Edit Attributes
                  </MenuItem>
                  {rights.can_delete && (
                    <MenuItem onClick={() => {
                      modalDelete.onOpen();
                    }}>
                      <Text color='text.medium.red' >Delete</Text>
                    </MenuItem>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        </>
      )}
      <Modal isOpen={modalEditName.isOpen} onClose={modalEditName.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Name</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {persona && (
                <PersonaNameForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditName.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref1}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalEditName.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref1.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditBio.isOpen} onClose={modalEditBio.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Bio</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {persona && (
                <PersonaBioForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditBio.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref2}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalEditBio.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref2.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditQuote.isOpen} onClose={modalEditQuote.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Quote</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {persona && (
                <PersonaQuoteForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditQuote.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref3}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalEditQuote.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref3.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalEditAttributes.isOpen} onClose={modalEditAttributes.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Edit Attributes</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {persona && (
                <PersonaAttributesForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    modalEditAttributes.onClose()
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref3}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='blueOutline'
              onClick={modalEditAttributes.onClose}
            >Close</Button>
            <Button
              variant="blueFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref3.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeletePersonaModal disclosure={modalDelete} />
    </>
  )
}




