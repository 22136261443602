'use client';

import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/layout';
import { useToast, Text } from '@chakra-ui/react';
import UpdatePasswordForm from '../components/updatePasswordForm';
import { useNavigate } from 'react-router-dom';

export default function UpdatePasswordPage() {

  const navigate = useNavigate();
  const toast = useToast();

  return (
    <>
      <Box  p={3} maxW={350} >
      < Text mb={3} >Update Password</Text>
        <UpdatePasswordForm
        hideSave={false}
        onSubmitted={() => {
          navigate('/')
          toast({
            position: 'bottom-right',
            description: "Password successfuly updated",
            duration: 1000,
            isClosable: true,
            status: 'success'
          });
        }}
        />
      </Box>
    </>
  )
}
