import React, {useContext, useEffect, useState} from 'react';
import {Flex, Box, Text} from '@chakra-ui/layout';
import {MdPerson} from 'react-icons/md';
import {Icon} from '@chakra-ui/icon';
import useProfileApi from '../lib/useProfileApi';
import { ProfileContext } from '../lib/profileContext';
import { SkeletonCircle } from '@chakra-ui/react';

export default function ProfileThumbnail(props) {


  const {
    size
  } = props;

  const [thumbnail, setThumbnail]  =  useState(null);
  const [acronym, setAcronym]  =  useState(null);
  const [downloading, setDownloading] = useState(false);
  const profileApi = useProfileApi();

  const { profile } = useContext(ProfileContext);

  const fetchThumbnail = async () => {
    let imageSize;
    if(size > 128) {
      imageSize = 256;
    } else if(size > 64 &&  size <= 128) {
      imageSize = 128;
    } else if(size <= 64 ) {
      imageSize = 64;
    }
    setDownloading(true)
    const newThumbnail = await profileApi.downloadThumbnail(profile.id, imageSize);
    setDownloading(false);
    setThumbnail(newThumbnail);
  };

  const updateAcronym = async () => {
    const pseudoArray = profile.pseudo.split(' ');
    if(pseudoArray.length > 1) {
      setAcronym(pseudoArray[0][0] + (pseudoArray[1]?  pseudoArray[1][0] : ''));
    } else {
      setAcronym(pseudoArray[0][0])
    }
  };

  useEffect(() => {
    if(profile && profile.has_thumbnail) {
      fetchThumbnail()
    }
  }, [profile]);

  useEffect(() => {
    if(profile.pseudo) {
      updateAcronym();
    }
  }, [profile]);

  return (
    <>
      <Flex
        border="1px"
        borderRadius="50%"
        backgroundColor="background.grey"
        borderColor="divider.grey"
        alignItems='center'
        justifyContent='center'
        w={size + 'px'}
        h={size + 'px'}
      >
         {((downloading && !thumbnail) && profile.has_thumbnail) && (
            <SkeletonCircle size={size + 'px'}/>
        )}
        { (profile.has_thumbnail && thumbnail) && (
          <Box
            w={size + 'px'}
            h={size + 'px'}
            borderRadius="50%"
            style={{overflow: 'hidden'}}
          >
            <img src={thumbnail} alt="thumbnail" style={{width: '100%', height:'100%'}} />
          </Box>
        )}
        { !profile.has_thumbnail && !acronym && (
          <Icon
            as={MdPerson}
            color={'text.secondaryGrey'}
            boxSize={((size * 0.6) + 'px')}
          />
        )}
        { !profile.has_thumbnail && acronym && (
          <Text
            textTransform={"uppercase"}
            fontWeight={"600"}
            fontSize={((size / 2.14) + 'px')}
          >{acronym}</Text>
        )}
      </Flex>
    </>
  );
}