import React, { forwardRef } from 'react';
import {insightTypeDictionnary} from '../lib/insightTypeDictionnary';
import { Flex, Icon } from '@chakra-ui/react';
import { MdOutlineThumbDown, MdOutlineThumbUp, MdOutlineTouchApp } from 'react-icons/md';
import { PiHandsPrayingFill } from 'react-icons/pi';
import CognitionIcon from '@/components/icons/cognitionIcon';

const InsightPicto = forwardRef((props, ref) => {
  const {
    type,
    size = 38,
  } = props;

  return (
    <Flex
      ref={ref}
      w={(size + 'px')}
      h={(size + 'px')}
      flexShrink={"0"}
      {...props}
    >
      {type === insightTypeDictionnary.positive && (
        <Flex
          alignItems='center'
          justifyContent={'center'}
          alignSelf="stretch"
          backgroundColor="background.green"
          borderColor="divider.green"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          w={"100%"}
          h={"100%"}
        >
          <Icon
            as={MdOutlineThumbUp}
            color={'fill.green'}
            boxSize={ ((size / 2.1) + 'px')}
          />
        </Flex>
      )}
      { type === insightTypeDictionnary.negative && (
        <Flex
          alignItems='center'
          alignSelf="stretch"
          justifyContent={'center'}
          w={"100%"}
          h={"100%"}
          backgroundColor="background.red"
          borderColor="divider.red"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
        >
          <Icon
            as={MdOutlineThumbDown}
            color={'fill.red'}
            boxSize={ ((size / 2.1) + 'px')}
          />
        </Flex>
      )}
      { type === insightTypeDictionnary.improvement && (
        <Flex 
          alignItems='center'
          alignSelf="stretch"
          justifyContent={'center'}
          w={"100%"}
          h={"100%"}
          backgroundColor="background.yellow"
          borderColor="divider.yellow"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          >
          <Icon
            as={PiHandsPrayingFill}
            color={'fill.yellow'}
            boxSize={ ((size / 2.1) + 'px')}
          />
        </Flex>
      )}
      { type === insightTypeDictionnary.behavior && (
        <Flex
          alignItems='center'
          alignSelf="stretch"
          justifyContent={'center'}
          px={2}
          w={"100%"}
          h={"100%"}
          backgroundColor="background.grey"
          borderColor="divider.grey"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          >
          <Icon
            as={MdOutlineTouchApp}
            color={'fill.grey'}
            boxSize={ ((size / 2.1) + 'px')}
          />
        </Flex>
      )}
      { type === insightTypeDictionnary.motivation && (
        <Flex
          alignItems='center'
          alignSelf="stretch"
          justifyContent={'center'}
          px={2}
          w={"100%"}
          h={"100%"}
          backgroundColor="background.purple"
          borderColor="divider.purple"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          >
          <Icon
            as={CognitionIcon}
            color={'fill.purple'}
            boxSize={ ((size / 2.1) + 'px')}
          />
        </Flex>
      )}
    </Flex>
  );
});


export default InsightPicto;