import React, {useContext, useRef, useState} from 'react';
import { Box, Text, Flex, Spacer } from '@chakra-ui/layout';
import { Button, Icon, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { ProfileContext } from '../lib/profileContext';
import ProfileEmailForm from './profileEmailForm';
import { MdOutgoingMail } from 'react-icons/md';

export default function ProfileEmail(props) {

  const {
    profile,
    email,
    refresh,
  } = useContext(ProfileContext)

  const modalEditEmail = useDisclosure();
  const modalEmailSent = useDisclosure();
  const ref = useRef();
  const [isAdding, setIsAdding] = useState(false);
  const [isValid, setIsValid] = useState(false);

  return (
    <Box px={3} py={3}>
      <Text textStyle="subtitle">Email</Text>
      <Flex alignItems={'center'}>
        <Text>{email}</Text>
        <Spacer />
        <Button
          variant={'greyOutline'}
          onClick={modalEditEmail.onOpen}
        >Edit</Button>
      </Flex>
      <Modal isOpen={modalEditEmail.isOpen} onClose={modalEditEmail.onClose}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Update Email</ModalHeader>
          <ModalBody >
            <Box p={3}>
              {profile && (
                <ProfileEmailForm
                  hideSave={true}
                  onSubmitting={() => {
                    setIsAdding(true);
                  }}
                  onSubmitted={() => {
                    setIsAdding(false);
                    refresh();
                    modalEditEmail.onClose()
                    modalEmailSent.onOpen();
                  }}
                  onError={() => {
                    setIsAdding(false);
                  }}
                  onValidityChange={(v) => {
                    setIsValid(v);
                  }}
                  ref={ref}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='greyOutline'
              onClick={modalEditEmail.onClose}
            >Close</Button>
            <Button
              variant="greyFill"
              ml={2}
              isLoading={isAdding}
              isDisabled={!isValid}
              onClick={() => {
                ref.current.click();
              }}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalEmailSent.isOpen}
        onClose={modalEmailSent.onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            Verification Email
          </ModalHeader>
          <ModalBody p={3}  >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={3}
            >
              <Icon
                as={MdOutgoingMail}
                color={'text.medium.grey'}
                boxSize={'50px'}
              />
              <Flex>
                <Text textAlign={"center"} >A verification Email as been sent to both your<span style={{ fontWeight: "700" }}> new and old </span> email addresses. Please <span style={{ fontWeight: "700" }} >click on both link.</span></Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="greyFill"
              ml={2}
              onClick={modalEmailSent.onClose}
            >Ok</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}