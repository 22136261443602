'use client';

import React, { useState } from 'react';
import { Button, Text, Flex, Icon } from '@chakra-ui/react';
import { MdArrowBack, MdOutlineEmail } from 'react-icons/md';
import Logo from '../../components/logo';
import { useNavigate } from 'react-router-dom';

export default function EmailConfirmedPage() {

  const navigate = useNavigate();

  return (
    <>
      <Logo />
      <Flex
        mt={3}
        alignItems="center"
        flexDirection="column"
      >
        <Flex>
          <Icon
            as={MdOutlineEmail}
            color={'fill.green'}
            boxSize={'20px'}
            mb={3}
          />
          <Text color={'fill.green'} ml={1}>Email successfuly confirmed !</Text>
        </Flex>
        <Button
          variant="greyOutline"
          leftIcon={MdArrowBack}
          onClick={() => {
            navigate('/auth/sign-in')
          }}
        >
          Go back to sign in</Button>
      </Flex>
    </>
  )
}
