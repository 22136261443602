import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Heading, Icon, IconButton, Skeleton, Spinner, Tooltip } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/layout';
import { MdOutlinePlayCircle } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';

export default function Prototype(props) {

  const disclosure = useDisclosure();
  const [isLoading, setIsLoading] = useState(true)

  const onIframeLoaded = () => {
    setIsLoading(false)
  }

  return (
     <>
     <Flex borderRadius={"2px"} overflow={"hidden"}>
      <Tooltip label={"Open Prototype"}>
        <IconButton
          icon={<Icon as={MdOutlinePlayCircle} fontSize={"18px"}/>}
          onClick={disclosure.onOpen}
          variant="orangeOutline"
        />
      </Tooltip>
     </Flex>
     <Modal
        isOpen={disclosure.isOpen}
        onClose={() => {
          disclosure.onClose();
          setIsLoading(true);
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"calc(100% - 20px)"} maxH={"calc(100vh - 20px)"} h={"100%"} py={0} >
          <ModalHeader py={1} px={3}>
            <Flex>
              <Icon alignSelf={"center"} as={MdOutlinePlayCircle} marginRight={1} />
              <Text>Prototype</Text>
            </Flex>
           </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading && (
              <Flex alignItems={"center"} justifyContent={"center"} w="100%" h={"100%"} >
                <Spinner />
              </Flex>
              )
            }
            <iframe
              onLoad={onIframeLoaded}
              width="100%" style={{height: "calc(100% + 48px)"}} src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/proto/35cDBTzn2W6cvZxzIOUzWf/Bibliona?page-id=74%3A219&node-id=79-1961&node-type=FRAME&viewport=1774%2C1846%2C0.2&t=feddSBdE0Ta5i8s4-1&scaling=contain&content-scaling=fixed&starting-point-node-id=79%3A1961&show-proto-sidebar=1" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
     </>
  );
}
