import './App.css';
import AppRoutes from './Routes';
import ConfigProvider from './lib/configProvider';

function App() {
  return (
    <>
      <ConfigProvider>
        <AppRoutes />
      </ConfigProvider>
    </>
  );
}

export default App;
