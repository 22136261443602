'use client'

import React from 'react';
import ProductList from './components/productList';
import { Flex } from '@chakra-ui/layout';

export default function ProductPage() {

  return (
    <Flex
      width="100%"
      height="100%"
    >
      <ProductList />
    </Flex>
  )
}
